import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import AppearInView from "./Utils/AppearInView";

const useStyles = makeStyles((theme) => ({
  wordColor: { color: "white" },
}));

const FAQ = (mobileView) => {
  const theme = useTheme();
  const { wordColor } = useStyles(theme);

  return (
    <div className={wordColor}>
      <AppearInView variant="fade" offset="150px">
        <Box
          m={5}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant={mobileView ? "h2" : "h1"}>FAQ'S</Typography>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant={mobileView ? "h5" : "h3"} style={{
              fontFamily: "blockyPixel",
              color: theme.palette.highlight,
              }}>
              What are PixelTigers?
            </Typography>
          </Box>
          <Box
            m={2}
            sx={{
              mx: "auto",
              width: "70%",
              p: 1,
              m: 1,
              borderRadius: 1,
              textAlign: "center",
              paddingBottom: "15px",
              marginTop: "0px",
            }}
          >
            <Typography variant="body1" style={{fontFamily: "gothamMedium"}}>
            PixelTigers are part of an expanding NFT collection capped at 4444 Genesis tigers. 
            An additional 8888 PixelCubs will join their parents as they set off on their journey - 
            into The Jungle and beyond.
            </Typography>
          </Box>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Divider flexItem style={{ background: "white" }} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box>
          <Box
            m={3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant={mobileView ? "h5" : "h3"} style={{
                fontFamily: "blockyPixel",
                color: theme.palette.highlight,
              }}>
              How much will it cost to mint?
            </Typography>
          </Box>
          <Box
            m={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "13px",
              marginTop: "-12px",
              fontFamily: "gothamMedium",
            }}
          >
            0.069 ETH each + GAS
          </Box>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Divider flexItem style={{ background: "white" }} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box>
          <Box
            m={3}
            sx={{
              display: "flex",
              justifyContent: "center",

            }}
          >
            <Typography variant={mobileView ? "h5" : "h3"} style={{
                fontFamily: "blockyPixel",
                color: theme.palette.highlight,
              }}>
              When will minting be available?
            </Typography>
          </Box>
          <Box
            m={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "0px",
              marginTop: "-25px",
            }}
          >
            <Typography variant="body1"  sx={{fontFamily: "gothamMedium"}}
              align="center">
              <p style={{ fontFamily: 'inherit', width: "90%", overflow:"wrap" }}>
                {`We are phasing out minting opportunities as we build.`}
              </p>
            </Typography>
          </Box>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Divider flexItem style={{ background: "white" }} />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box>
          <Box
            m={3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant={mobileView ? "h5" : "h3"} style={{
                fontFamily: "blockyPixel",
                color: theme.palette.highlight,
              }}>
              Mints per transaction?
            </Typography>
          </Box>
          <Box
            m={2}
            sx={{
              mx: "auto",
              width: "70%",
              p: 1,
              m: 1,
              borderRadius: 1,
              textAlign: "center",
              paddingBottom: "20px",
              marginTop: "-20px",
              fontFamily: "gothamMedium",
            }}
          >
            2 per transaction, for both presale and public sale.
          </Box>
        </Box>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Divider flexItem style={{ background: "white" }} />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                m: 4,
              }}
            />
          </Grid>
        </Box>
      </AppearInView>
    </div>
  );
};

export default FAQ;
