
import { useState, createContext, useEffect } from "react";

import { getAnalytics, logEvent } from 'firebase/analytics';
import { getDatabase, ref, set, push, onChildAdded } from 'firebase/database';
import { constants } from './constants';

import { MainRaffleData, SubRaffleData } from "./RaffleComponent/raffleData";
// import { expeditionStart } from "./GamesComponent/gameData";
import { app } from "../config/firebaseClient";

const analytics = getAnalytics(app);
// Get reference to database service
const db = getDatabase(app);

/**
 * Adds user address and number of entries to list of main raffle entries
 * Raffle references uses RaffleEnd date to uniquely identfy raffles
 * @param {*} address user wallet address
 * @param {*} count number of entries
 */
const mainRaffleEnd = MainRaffleData.RaffleEnd.replaceAll(" ", "");
const mainRaffleRef = ref(db, `${constants.contractAddressERC20}/mainRaffle/${mainRaffleEnd}`);
export const addMainRaffle = (address, count) => {
    // logEvent(analytics, 'enter_main_raffle');
    const newEntryRef = push(mainRaffleRef);
    const curDate = new Date();
    set(newEntryRef, {
        addr: address,
        count: count,
        date: curDate.toString(),
    })
};

/**
 * Adds user address and number of entries to list of sub raffle entries
 * Raffle references uses RaffleEnd date to uniquely identfy raffles
 * @param {*} address user wallet address
 * @param {*} count number of entries
 * @param {*} endDate used to identify raffles
 */
const subRaffleEnd = SubRaffleData.RaffleEnd.replaceAll(" ", "");
const subRaffleRef = ref(db, `${constants.contractAddressERC20}/subRaffle/${subRaffleEnd}`);
export const addSubRaffle = (address, count) => {
    logEvent(analytics, 'enter_sub_raffle');
    const newEntryRef = push(subRaffleRef);
    const curDate = new Date();
    set(newEntryRef, {
        addr: address,
        count: count,
        date: curDate.toString(),
    })
}

export const startUpRecord = () => {
    const startupRef = ref(db, 'startup');
    const newStartupRef = push(startupRef)
    const curDate = new Date();
    set(newStartupRef, {
        date: curDate.toString()
    })
}

const RaffleContext = createContext();
const RaffleProvider = ({ children }) => {
    const [ mainRaffleParticipants, setMainRaffleParticipants ] = useState([]);
    const [ numEntriesMain, setNumEntriesMain ] = useState(0);
    const [ subRaffleParticipants, setSubRaffleParticipants ] = useState([]);
    const [ numEntriesSub, setNumEntriesSub ] = useState(0);

    useEffect(() => {
        onChildAdded(mainRaffleRef, (data) => {
            setMainRaffleParticipants(arr => ([
                ...arr,
                {
                    addr: data.val().addr,
                    count: data.val().count
                }
            ]))
            setNumEntriesMain(val => val + data.val().count);
        });    

        onChildAdded(subRaffleRef, (data) => {
            setSubRaffleParticipants(arr => ([
                ...arr,
                {
                    addr: data.val().addr,
                    count: data.val().count
                }
            ]))
            setNumEntriesSub(val => (val + data.val().count))
        });    
    }, [])

    return (
        <RaffleContext.Provider
            value={{
                mainRaffleParticipants,
                numEntriesMain,
                subRaffleParticipants,
                numEntriesSub
            }}
        >
            {children}
        </RaffleContext.Provider>
    )
}

export { RaffleProvider, RaffleContext }

/**
 * Adds user address and number of entries to list of main raffle entries
 * Raffle references uses RaffleEnd date to uniquely identfy raffles
 * @param {*} tokenId user wallet address
 * @param {*} ownerAddr number of entries
 */
 export const setOwner = (tokenId, ownerAddress) => {
     const ownerRef = ref(db, `/owners/${tokenId}`);
     const curDate = new Date();
     set(ownerRef, {
         owner: ownerAddress,
         date: curDate.toString(),
     });
 };
 
 export const setAddrNumTokens = (addr, claimable, balance) => {
    const tokenBalRef = ref(db, `/tokenBal/${addr}`);
    const curDate = new Date();
    set(tokenBalRef, {
        claimable: claimable,
        balance: balance,
        date: curDate.toString()
    });
 }