import { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import AttributeFilters from './AttributeFilters';
import { flexbox } from "@mui/system";

const useStyles = () => makeStyles(theme => ({
        Wrapper: {
            height: "100%",
        },
        AttributeFilterWrapper: {
            paddingBottom: "2px",
            borderBottom: `1.5px solid ${theme.palette.primary.dark}`,
        },
        ShowAttributeButton: {
            textTransform: "none",
            backgroundColor: "inherit",
            color: "inherit",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "10px",
            height: "35px",
            alignItems: "center",
        },
        AttributeTitle: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center", 
            "& h1": {
                fontSize: "15px",
                fontWeight: "600",
                color: theme.palette.primary.dark,
                fontFamily: theme.palette.pixelFont,
            }
        },
        AttributeIcon: {
            width: "20px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "4px",
            "& img": {
                maxWidth: "100%",
                height: "18px",
            },
        },
        ShowAttributeIcon: {
            fontSize: "20px",
        },
        HideFilter: {
            display: "none",
        }
    })
)

const Filters = ({curFilters, attrCounts, isFilterSet, toggleFilter}) => {

    const theme = useTheme();
    const classes =  useStyles()(theme);

    /* Toggle show filters */
    const getInitShowFilter = (attrCounts) => {
        let filterState = {};
        Object.keys(attrCounts).forEach((attr) => {
            filterState[attr] = false;
        })
        return filterState;
    }
    const [showFilters, setShowFilters] = useState(getInitShowFilter(attrCounts));
    const toggleShowFilter = (attr) => {
        setShowFilters((prevState) => ({
            ...prevState,
            [attr]: !prevState[attr]
        }));
    }
    useEffect(() => { // reset show filter if wulfz type change
        setShowFilters(getInitShowFilter(attrCounts));
    }, [attrCounts])
    
    /* Return display for attribute: values group */
    const AttributeFilterGroup = ({attr, values}) => {
        delete values.lastModified; // remove util field
        return (
            <Grid container item xs={12} className={classes.AttributeFilterWrapper}>

                <Button className={classes.ShowAttributeButton} onClick={() => toggleShowFilter(attr)}>
                    <div className={classes.AttributeTitle}>
                        <div className={classes.AttributeIcon}>
                            <img src={`/assets/images/gallery_icons/${attr.toLowerCase()}.svg`} alt={''}/>
                        </div>
                        <h1>{attr.toUpperCase()}</h1>
                    </div>
                    <span className={classes.ShowAttributeIcon}>{showFilters[attr] ? "-" : "+"}</span>
                </Button>
                <Grid container item className={!showFilters[attr] && classes.HideFilter}> 
                    <AttributeFilters 
                        attr={attr} 
                        values={values} 
                        toggleFilter={toggleFilter}
                        isFilterSet={isFilterSet}/>
                </Grid> 
            </Grid>
        )
    }

    /* Display all filters */
    const allFilters = Object.keys(attrCounts).map((attrType, idx) => {
        return (
            <AttributeFilterGroup key={attrType+idx} attr={attrType} values={attrCounts[attrType]} />
        )
    })

    return (
        <Grid container item className={classes.Wrapper}>
            {allFilters}
        </Grid>
    )
}

export default Filters;
