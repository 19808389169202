import { useState, useEffect } from 'react';
import { GalleryFn } from '../utils/firebaseUtils/galleryUtils';
import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';

import GalleryDisplay from './GalleryComponent/GalleryDisplay';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import ScrollToTop from './Utils/ScrollToTop';
import { AccountContext } from './MetaMaskUtils';

const useStyles = (mobileView) => makeStyles(theme => ({
    })
)

const GalleryPage = ({ mobileView }) => {
    
    const theme = useTheme();
    const classes =  useStyles(mobileView.mobileView)(theme);

    /* Contains metadata for all wulfz */
    const [pixelTigers, setPixelTigers] = useState(null);
    const getPixelTigers = (tigerType) => {
        if (pixelTigers === null) {
            return null;
        }
        /* Genesis / Cub */
        return pixelTigers.filter((x) => x["type"] === tigerType);
    }

    /* Specifies attributes available for filter for each type */
    const [attributes, setAttributes] = useState(null)
    const getAttributes = (tigerType) => {
        if (attributes === null) {
            return null;
        }
        return attributes[tigerType];
    }

    /* Type of tiger to display */
    const [selectedType, setSelectedType] = useState("Genesis");
    const updateType = (tigerType) => {
        setSelectedType(tigerType);
    }

    /* Utils to load data sequentially */
    const LoadData = async () => {
        let newTigers = {};
        const data = await GalleryFn.getData();
        newTigers = data.metadata;
        
        // Set first
        setPixelTigers(Object.values(newTigers));        
        setAttributes(data.attrCount);

        // check if need fetch others
        if (data[["newStart"]] >= 0) {
            const otherMeta = await GalleryFn.getRestofMetadata(data["newStart"]);
            Object.keys(otherMeta).forEach((tokenId) => { // join to original array
                newTigers[tokenId] = otherMeta[tokenId];
            })

            // Update if necessary
            setPixelTigers(Object.values(newTigers));           
        }
    }
    /* Fetch on page load */
    useEffect(() => {
        LoadData();
    }, [])


    return (
        <ScrollToTop>
            <div>
                <Header mobileView={mobileView.headerMobileView}/>
                    <Grid container xs={12} justifyContent="center" alignItems="center"
                        pt={14}>
                        {
                            ( pixelTigers === null || attributes === null ) ? 
                            <Grid container xs={12} pt={10} pb={10} justifyContent="center" alignItems="center"
                                sx={{height: "100vh"}}>
                                <Typography variant="h2"
                                    sx={{ fontSize: "20px", color: theme.palette.highlight }}>
                                    Loading...
                                </Typography>
                            </Grid>
                            :
                            <GalleryDisplay
                                pixelTigers={getPixelTigers(selectedType)}
                                attributes={getAttributes(selectedType)}
                                selectedType={selectedType}
                                setSelectedType={setSelectedType}
                                />
                        }
                    </Grid>
                <Footer />
            </div>
        </ScrollToTop>
    );
}

export default GalleryPage;
