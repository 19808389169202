import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    /* Transition */
    fadeEnter: {
        opacity: "0 !important",
    },
    fadeEnterActive: {
        opacity: "1 !important",
        transition: "all 0.5s linear",
    },
    fadeExit: {
        opacity: "0",
        transition: "all 1.5s ease",
    },
    fadeExitActive: {
        opacity: "0"
    },

    /* Transition Up */
    fadeUpEnter: {
        opacity: "0",
        marginBottom: "2px",
    },
    fadeUpEnterActive: {
        opacity: "1",
        marginBottom: "0px",
        transition: "all .5s linear"
    }
})

const FadeIn = ({show, direction, children}) => {
    
    const classes =  useStyles();

    /**
     * Pass show as an object to pass by reference
     *  {
     *      show: true/false
     *  }
     */
    let fadeStyle;
    if (show === undefined) {
        fadeStyle = {
            appear: classes.fadeEnter,
            appearActive: classes.fadeEnterActive,
            exit: classes.fadeExit,
            exitActive: classes.fadeExitActive,
        };
        if (direction !== undefined) {
            if (direction === "UP") {
                fadeStyle = {
                    appear: classes.fadeUpEnter,
                    appearActive: classes.fadeUpEnterActive,
                    exit: classes.fadeExit,
                    exitActive: classes.fadeExitActive,
                };
            }
        }
        return (
            <CSSTransition in={true} appear={true} timeout={1000} classNames={fadeStyle} mountOnEnter>
                {children}
            </CSSTransition>
        )
    }
    fadeStyle = {
        enter: classes.fadeEnter,
        enterActive: classes.fadeEnterActive,
        exit: classes.fadeExit,
        exitActive: classes.fadeExitActive,
    };
    if (direction !== undefined) {
        if (direction === "UP") {
            fadeStyle = {
                enter: classes.fadeUpEnter,
                enterActive: classes.fadeUpEnterActive,
                exit: classes.fadeExit,
                exitActive: classes.fadeExitActive,
            };
        };
    };
    return (
        <CSSTransition in={show['show']} timeout={1000} classNames={fadeStyle}>
            {children}
        </CSSTransition>
    )
}

export default FadeIn;