import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from "../config/firebaseClient";
import { AuthFn } from '../utils/firebaseUtils/authUtils';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
    
    /**
     * Create user object for reference in code
     */
    const [user, setUser] = useState(null);
    useEffect(() => {
        const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
            if (user) { // can be default user
                setUser(user);
            } else {
                signInAnonymously(auth)
                    .then(() => {
                        console.log("Set up success.");
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log("Error code:", errorCode, "Message:", errorMessage);
                })
            }
        });

        return () => unregisterAuthObserver();
    }, [])

    /**
     * Register the user's google auth uid the first time they connect wallet
     */
    const [UIDRegistered, setUIDRegistered] = useState(false);
    const registerUID = async (userAddress) => {
        if (!UIDRegistered && user && userAddress) {
            const success = await AuthFn.addAddressUID(userAddress, user.uid);
            if (success) {
                setUIDRegistered(true);
            }
        }
    }

    

    return (
        <AuthContext.Provider
            value={{
                user,
                registerUID,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
