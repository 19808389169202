import Grid from "@mui/material/Grid";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { membersInfo } from "./teamData";
import Member from "./Member";
import { Typography } from "@mui/material";

import AppearInView from "../Utils/AppearInView";

const useStyles = makeStyles((theme) => ({
  outer: {
    //   paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  modtitle: {
    color: "white",
    paddingTop: "1rem",
    borderBottom: "1px solid white",
    fontFamily: "blockyPixel",
  },
}));

const Team = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  // TODO: make design nicer for mobile somehow
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      className={classes.outer}
    >
      <AppearInView variant="fade" offset="150px">
        <Grid container item xs={12} justifyContent="center" mt={13} mb={3}>
          <Typography variant="h2" sx={{ color: "white" }}>
            Our Team
          </Typography>
        </Grid>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Grid container item xs={12} justifyContent="center" mb={3}>
          <Typography variant="body" sx={{ 
              maxWidth: "80%",
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}>
            PixelTigers is a fully community run project. We are a group of passionate members who are operated by our elected Council Members. 
          </Typography>
        </Grid>
      </AppearInView>
      <AppearInView variant="fade" offset="150px">
        <Grid container item xs={12} justifyContent="center" mb={3}>
          <Typography variant="body" sx={{ 
              color: theme.palette.highlight
          }}>
            Current Tiger Council Members
          </Typography>
        </Grid>
      </AppearInView>
      <Grid
        container
        item
        xs={11}
        md={11}
        lg={9}
        xl={7}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        {/* Change grid layout for positioning */}
        <AppearInView variant="fade" offset="150px">
          <Grid container item xs={12} justifyContent="center">
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Member member={membersInfo["zaklewis"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Member member={membersInfo["sampath"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Member member={membersInfo["jonathanbeaudoin"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
              >
              <Member member={membersInfo["montanaturner"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Member member={membersInfo["ilinlee"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              xs={4}
              md={2}
              justifyContent="center"
              alignItems="center"
            >
              <Member member={membersInfo["mahdishirzadkhan"]} sz={"sm"} />
            </Grid>
          </Grid>
        </AppearInView>
        <AppearInView variant="fade" offset="150px">
          <Grid container item xs={12} pt={5} justifyContent="center">
            <Grid
                container
                item
                xs={4}
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Member member={membersInfo["raelynnchan"]} sz={"sm"} />
              </Grid>
              <Grid
                container
                item
                xs={4}
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Member member={membersInfo["kaiyang"]} sz={"sm"} />
              </Grid>
              <Grid
                container
                item
                xs={4}
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Member member={membersInfo["liolim"]} sz={"sm"} />
              </Grid>
            </Grid>
          </AppearInView>
      </Grid>
      {/* <Grid
        container
        item
        xs={12}
        md={12}
        lg={10}
        xl={8}
        justifyContent="center"
        alignItems="center"
      >
        <AppearInView variant="fade" offset="150px">
          <Grid
            container
            item
            xs={8}
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h4" className={classes.modtitle}>
              Moderators
            </Typography>
          </Grid>
        </AppearInView>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={10}
        xl={8}
        justifyContent="center"
        alignItems="center"
      >
        <AppearInView variant="fade" offset="150px">
          <Grid container item xs={12} justifyContent="center">
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={2.5}
            >
              <Member member={modsInfo["PROREO"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={4}
              md={2.5}
            >
              <Member member={modsInfo["TheRealTomNook"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={4}
              md={2.5}
            >
              <Member member={modsInfo["Skorge"]} sz={"sm"} />
            </Grid>
          </Grid>
        </AppearInView>
        <AppearInView variant="fade" offset="150px">
          <Grid container item xs={12} justifyContent="center">
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={3}
              md={2}
            >
              <Member member={modsInfo["Dawg"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={3}
              md={2}
            >
              <Member member={modsInfo["DopeOxide"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={3}
              md={2}
            >
              <Member member={modsInfo["Evio"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={3}
              md={2}
            >
              <Member member={modsInfo["NFTJ1"]} sz={"sm"} />
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              xs={3}
              md={2}
            >
              <Member member={modsInfo["Pr0xymac"]} sz={"sm"} />
            </Grid>
          </Grid>
        </AppearInView>
      </Grid> */}
    </Grid>
  );
};

export default Team;
