import { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import PopUpCard from "./PopUpCard";
import FadeIn from './FadeIn';


const useStyles = () => makeStyles(theme => ({
        Hide: {
            opacity: '0', // hide before fully loaded
        },
        FadeIn: {
            opacity: "1",
            "-webkit-transition": "opacity 0.5s linear",
        },
        Wrapper: {
            width: "100%",
        },
        CardWrapper: {
            display: "grid",
            width: "100%",
            gridTemplateColumns: "repeat(5, 1fr)",  
            [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: "repeat(4, 1fr)",  
            },
            // [theme.breakpoints.down('md')]: {
            //     gridTemplateColumns: "repeat(5, 1fr)",  
            // },
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: "repeat(3, 1fr)",  
            }
        },
        TigersCard: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            aspectRatio: "0.85",
            flex: "1",
        },
        TigersCardContent: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "inherit",
            width: "100%",
            padding: "0px 2px",
        },
        CardInfoWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            borderRadius: "0px 0px 4px 4px",
            backgroundColor: theme.palette.primary.dark,   
        },
        CardInfo: {
        },
        TigerID: {
            zIndex: "2",
            paddingBottom: "5px",
            color: theme.palette.primary.light,
            fontSize: "12px",
            fontWeight: "500",
            textTransform: "none",
            fontFamily: theme.palette.pixelFont,      
            [theme.breakpoints.down('lg')]: {
                fontSize: "10px",
            },
        },
        TigersCardBtn: {
            display: "flex",
            flexDirection: "column",
            width: "95%",
            padding: "0px",
            "-webkit-transition": "all 0.05s linear",
            "&:hover": {
                width: "100%",
            }
        },
        TigerImageWrapper: {
            zIndex: "2",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        TigerRank: {
            position: "absolute",
            zIndex: "3",
            backgroundColor: alpha(theme.palette.primary.dark, 0.5),
            lineHeight: "30px",
            color: theme.palette.highlight,
            fontFamily: theme.palette.pixelFont,
            padding: "0px 10px",
            top: "4%",
            right: "5%",
            fontSize: "14px",
            borderRadius: "10px",
            [theme.breakpoints.down('lg')]: {
                top: "5%",
                right: "5%",
                fontSize: "12px",
                borderRadius: "4px",
                lineHeight: "18px",
                padding: "0px 6px",
            },
            [theme.breakpoints.down('md')]: {
                top: "5%",
                right: "5%",
                fontSize: "10px",
                borderRadius: "4px",
                lineHeight: "18px",
                padding: "0px 6px",
            },
            [theme.breakpoints.down('md')]: {
                top: "4%",
                right: "4%",
                fontSize: "9px",
                borderRadius: "4px",
                lineHeight: "16px",
                padding: "0px 3px",
            }
        },
        TigerImage: {
            width: "100%",
            borderRadius: "4px 4px 0px 0px",
        },
        OSIcon: {
            marginTop: "30%",
            height: "18px",
            [theme.breakpoints.down('lg')]: {
                height: "16px"
            },
            [theme.breakpoints.down('sm')]: {
                height: "14px"
            }
        }
    })
)

const CardsDisplay = ({tigers, mobileView}) => {

    const theme = useTheme();
    const classes =  useStyles()(theme);
    
    /* Pop up card utils */
    const [selectedTiger, setSelectedTiger] = useState(tigers[0]);
    const [showPopUp, setShowPopUp] = useState(false);
    const togglePopUp = () => {
        setShowPopUp(!showPopUp);
    }
    
    /* Limit number to display */
    const INIT_NUM = 40;
    const NUM_PER_SCROLL = 20;
    const [numDisplay, setNumDisplay] = useState([INIT_NUM]); // use array to pass by ref
    const updateNumDisplay = () => {
        setNumDisplay((prevVal) => [prevVal[0] + NUM_PER_SCROLL]);
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const handleScroll = (event) => {
            const tigersWrapper = document.querySelector('#tigersWrapper');
            const tigersBtm = tigersWrapper.clientHeight + tigersWrapper.offsetTop;
            if ((window.innerHeight + window.scrollY) > tigersBtm - 50) {
                if (!showPopUp) { // don't expand if pop up open
                    updateNumDisplay();
                }
            }
        }
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    useEffect(() => { // reset number to display on change in tigers
        setNumDisplay([INIT_NUM]);
    }, [tigers])

    /* Display tigers Cards */ //numDisplay[0]
    const rendertigers = tigers.slice(0, numDisplay[0]).map((tiger) => {
        /**
         * attributes: attributes of token
         * image: image url
         * name: name of token
         * type: type of token
         * tokenId: ID in contract
         * typeId: ID for specific type
         */
        return (
            <div key={tiger.name} className={`${classes.TigersCard}`}> 
                <FadeIn>
                    <div className={classes.TigersCardContent}>
                        <Button className={classes.TigersCardBtn}
                            onClick={() => {
                                setSelectedTiger(tiger);
                                togglePopUp();
                            }}
                            >
                            <div className={classes.TigerImageWrapper}>
                                { tiger.type === "Genesis" &&
                                    <span className={classes.TigerRank}>#{tiger.rank}</span>
                                }
                                <img src={tiger.image} alt={tiger.name} className={classes.TigerImage}/>
                            </div>
                            <Grid container item xs={12} justifyContent="center" alignItems="center" className={classes.CardInfoWrapper}>
                                <Grid container item xs={11} justifyContent="space-between" alignItems="center" className={classes.CardInfo}>
                                    <span className={classes.TigerID}>{isMobile ? `#${tiger.tokenId}`: tiger.name}</span>
                                    <a href={`https://opensea.io/assets/ethereum/0xe1b05227824e45f5713ea8bf6e4ad59b3d4a98ef/${tiger.tokenId}`} target="_blank" rel="noreferrer">
                                        <img src={`/assets/images/socials/opensea_blue.svg`} alt='os' className={classes.OSIcon}/>
                                    </a>
                                </Grid>
                            </Grid>
                        </Button>
                    </div>
                </FadeIn>
            </div>
        );
    })
    
    return (
        <div id="cardDisplay" className={`${classes.Wrapper}`}>
            <PopUpCard
                tiger={selectedTiger}
                show={showPopUp}
                toggleShow={togglePopUp}
                />
            <div id="tigersWrapper" className={`${classes.CardWrapper}`}>
                {rendertigers}
            </div>
        </div>
    )
}

export default CardsDisplay;
