import { db } from '../../config/firebaseClient';
import { collection, query, getDocs, getDocsFromCache, orderBy, where, limit, startAt, endAt} from "firebase/firestore";

const metadataPath = "metadata/tigerMetadata/tigers";

// Get latest update date in Server
const getLatestDateServer = async () => {
    let lastModified = null;
    const lastModRef = query(collection(db, metadataPath), orderBy("lastModified", "desc"), limit(1));
    const querySnapshot = await getDocs(lastModRef);
    querySnapshot.forEach((doc) => {
        lastModified = doc.data().lastModified;
    })

    return lastModified;
}
// Get latest update date in Cache
const getLatestDateCache = async () => {
    let lastModified = null;
    const lastModRef = query(collection(db, metadataPath), orderBy("lastModified", "desc"), limit(1));
    const querySnapshot = await getDocsFromCache(lastModRef);
    if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
            lastModified = doc.data().lastModified;
        })
    }
    return lastModified;
}
// Fetch from server if Server last update is later
const requireServerFetch = async () => {
    const lastModifiedCache = await getLatestDateCache();
    const lastModifiedServer = await getLatestDateServer();
    if (lastModifiedCache === null) {
        return true;
    }
    return lastModifiedServer > lastModifiedCache;
}

/**
 * Fetch metadata
 * @return array of tokenIds
 */
const getMetaData = async (reqServer) => {
    let metadata = {};    
    let numToFetch = 50; // Num to first fetch from server. (for faster load)
    let newStart = numToFetch + 1; // buffer in case miss out some. To stagger load to let page load first
    let metadataRef = query(collection(db, metadataPath));
    // Fetch data
    let querySnapshot;
    if (reqServer) {
        metadataRef = query(collection(db, metadataPath), orderBy("typeId"), endAt(numToFetch));
        querySnapshot = await getDocs(metadataRef);
    } else {
        querySnapshot = await getDocsFromCache(metadataRef);
        if (querySnapshot.size < 6000) { // @TODO update with actual total supply
            metadataRef = query(collection(db, metadataPath), orderBy("typeId"), endAt(numToFetch));
            querySnapshot = await getDocs(metadataRef);
        } else {
            // if successfully fetch from cache
            newStart = -1;
        }
    }
    querySnapshot.forEach((doc) => {
        const tiger = doc.data();
        metadata[tiger.tokenId] = tiger;
    }) 
    return {
        metadata: metadata,
        newStart: newStart
    };
}

/** 
 * Fetch rest of the data.
 * @param start start index to fetch
*/
const getRestofMetadata = async (start) => {
    let metadata = {};    
    const metadataRef = query(collection(db, metadataPath), orderBy("typeId"), startAt(start));
    const querySnapshot = await getDocs(metadataRef);
    querySnapshot.forEach((doc) => {
        const tiger = doc.data();
        metadata[tiger.tokenId] = tiger;
    }) 
    return metadata;
}

/**
 * Fetch wulfz attributes and counts
 */
const getAttrCounts = async (reqServer) => {
    let attributes = {
        "Genesis": {},
        "Cub": {}
    }

    const basePath = "metadata/attributes/types/"

    // Fetch for Genesis
    const genesisPath = basePath + "Genesis/counts";
    const genesisRef = query(collection(db, genesisPath));
    // Fetch data
    let querySnapshot;
    if (reqServer) {
        querySnapshot = await getDocs(genesisRef);
    } else {
        querySnapshot = await getDocsFromCache(genesisRef); // fetch from cache
        if (querySnapshot.size === 0) { // if cache data is empty, fetch from server
            querySnapshot = await getDocs(genesisRef);
        }
    }
    querySnapshot.forEach((doc) => {
        attributes["Genesis"][doc.id] = doc.data();
    }) 

    // Fetch for Cub
    const cubPath = basePath + "Cub/counts";
    const cubRef = query(collection(db, cubPath));
    if (reqServer) {
        querySnapshot = await getDocs(cubRef);
    } else {
        querySnapshot = await getDocsFromCache(cubRef);
        if (querySnapshot.size === 0) {
            querySnapshot = await getDocs(cubRef);
        }
    }
    querySnapshot.forEach((doc) => {
        attributes["Cub"][doc.id] = doc.data();
    }) 

    return attributes;
}

/**
 * Util to fetch both metadata and attribute counts
 */
const getData = async () => {
    let data = {};
    const reqServer = await requireServerFetch();
    const metadataInfo = await getMetaData(reqServer);
    data["metadata"] = metadataInfo["metadata"];
    data["newStart"] = metadataInfo["newStart"];
    data["attrCount"] = await getAttrCounts(reqServer);
    return data;
}

export const GalleryFn = {
    getData,
    getRestofMetadata
}
