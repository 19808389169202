import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import JungleRun from './JungleRunComponent/JungleRun';

import ScrollToTop from './Utils/ScrollToTop';

function JungleRunPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <JungleRun mobileView={mobileView.mobileView}/>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default JungleRunPage;
 