import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme();
theme = responsiveFontSizes(theme);
theme = createTheme(theme, {
  palette: {
    ...theme.palette,
    primary: {
      light: "#99a37c",
      main: "#195A4F",
      dark: "#153935",
    },
    offWhite: "#e5e5e5",
    grey_disable: "#747474",
    highlight: "#ff9e2b",
    header: "#000000",
    section_header: "#E7B651",
    section_subheader: "#b39974",
    footer: {
      social_bg: "#585f6b",
      desc: "#a9a9a9",
    },
    error: {
      main: "#ff9e2b",
    },
    pixelFont: "lores-12",
    blockyFont: "blockyPixel",
    robotoSlabFont: "roboto-slab",
    gothamMedFont: 'gothamMedium',
    btnBackground: {
      border: `2px solid #ff9e2b`,
      backgroundColor:'black',
      color: '#ff9e2b',
      '&:hover': {
        border: `2px solid #ff9e2b`,
        backgroundColor:'black',
        color: '#ff9e2b',
        fontWeight: 'bolder'    
      }
    },
    btnDisabledBackground: {
      border: `2px solid #747474`,
      backgroundColor:'transparent',
      color: "#747474",
    },
  },
  typography: {
    ...theme.typography,
    h1: {
      fontFamily: "blockyPixel",
    },
    h2: {
      fontFamily: "blockyPixel",
    },
    h3: {
      fontFamily: "roboto-slab",
    },
    h4: {
      fontFamily: "roboto-slab",
    },
    h5: {
      fontFamily: "roboto-slab",
    },
    h6: {
      fontFamily: "roboto-slab",
    },
  },
});

export default theme;
