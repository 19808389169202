import { db } from "../../config/firebaseClient";
import { doc, setDoc, collectionGroup, getDoc, deleteDoc, query, 
        where, getDocs } from "firebase/firestore";
 
const walletPathRoot = "wallet"

/**
 * Adds wallet address to user's document
 * @param {*} walletAddr user wallet address
 * @param {*} uid user id
 */
const addWalletToUID = async (walletAddr, uid) => {
    try {
        const exists = await addrExists(walletAddr);
        if (exists) {
            alert("Wallet already linked");
            return false;
        }
        const walletRef = doc(db, `${walletPathRoot}/${uid}`);
        await setDoc(walletRef, {
            walletAddr: walletAddr
        })
        return true;
    } catch (e) {
        alert(`Failed to link wallet: ${e}`);
        return false;
    }
}

/**
 * Get wallet address attached to user's id
 * @param {*} uid user id
 */
const getWalletAddr = async (uid) => {
    try {
        const walletRef = doc(db, `${walletPathRoot}/${uid}`);
        const snapshot = await getDoc(walletRef);
        if (snapshot.exists()) {
            const userData = snapshot.data();
            return userData.walletAddr;
        }
        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
}

/**
 * Delete wallet address link
 * @param {*} uid user id
 */
const deleteWalletLink = async (uid) => {
    try {
        const walletRef = doc(db, `${walletPathRoot}/${uid}`);
        await deleteDoc(walletRef);
        return true;
    } catch (e) {
        alert(`Failed to delete linked wallet ${e}`);
        return false;
    }
}

/**
 * Check if walletaddress already exists
 */
 const addrExists = async (walletAddr) => {
    const q = query(collectionGroup(db, `${walletPathRoot}`), where("walletAddr", "==", walletAddr));
    const querySnapshot = await getDocs(q);

    // consolidate transactions
    const addresses = [];
    querySnapshot.forEach((doc) => {
        addresses.push({
            ...doc.data(),
        });
    })    
    return (addresses.length > 0);
}


export const walletLinkFn = {
    addWalletToUID,
    addrExists,
    getWalletAddr,
    deleteWalletLink
}