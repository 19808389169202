import Button from "@mui/material/Button";
import { useContext } from "react";
import { AccountContext } from "../MetaMaskUtils";
import theme from "../../theme";

function ConnectButton() {
  const accountContext = useContext(AccountContext);

  return (
    <Button
      style={{
        ...theme.palette.btnBackground,
        width: "80%",
        maxWidth: "200px",
        borderRadius: "7px",
        // maxHeight: "25px",
        "&:disabled": {
          ...theme.palette.btnDisabledBackground,
        },
        fontSize: "0.7rem",
        left: "50%",
        transform: "translateX(-50%)",
      }}
      onClick={accountContext.connectWalletHandler}
    >
      Connect
    </Button>
  );
}

export default ConnectButton;
