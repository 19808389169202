import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import FadeIn from "./FadeIn";

const useStyles = makeStyles(theme => ({
        Wrapper: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "10px",
            height: "30px",
            borderRadius: "8px",
            height: "80px",
            [theme.breakpoints.down('md')]: {
                padding: "20px 0px 0px 0px",
            },
        },
        FilterInfo: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundImage: `url(/assets/images/ui/Roadmap/RM_textbox1.webp)`,
            width: "180px",
            padding: "8px",
        },
        FilterText: {
            color: theme.palette.offWhite,
            fontFamily: theme.palette.pixelFont,
            fontSize: "18px",
            lineHeight: "30px",
            margin: "0px 4px",
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
                marginLeft: '4px',
            }
        },
        FilterCount: {
            color: theme.palette.offWhite,
            fontFamily: theme.palette.pixelFont,
            backgroundColor: alpha(theme.palette.offWhite, 0.2),
            padding: "0px 4px",
            borderRadius: "5px",
            fontSize: "16px",
            lineHeight: "26px",
            [theme.breakpoints.down('md')]: {
                fontSize: '12px'
            }
        },
        ClearBtn: {
            border: "0px solid black !important",
            backgroundColor: alpha(theme.palette.grey_disable, 0.6),
            color: theme.palette.offWhite,
            height: "24px",
            width: "30px",
            margin: "0px 10px !important",
            fontSize: "12px",
            fontFamily: theme.palette.gothamMedFont,
            [theme.breakpoints.down('md')]: {
                fontSize: '10px',
                height: "22px",
                width: "22px",
            }
        },
        FilterCardsWrapper: {
            display: "flex",
            overflowX: "scroll",
            whiteSpace: "nowrap",
            gap: "6px",
            width: "100%",
            "&::-webkit-scrollbar": {
                display: "none"
            },
            scrollbarWidth: "none",
        },
        FilterCard: {
            color: theme.palette.offWhite,
            fontFamily: theme.palette.pixelFont,
            fontSize: "12px",
            margin: "4px 6px",
            border: `2px solid ${alpha(theme.palette.offWhite, 0.4)}`,
            borderRadius: "12px",
            display: "flex",
            "&:hover": {
                cursor: "pointer",
            },
        },
        FilterWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "0px 10px",
        },
        FilterAttr: {
            width: "100%",
            fontSize: "10px",
            textAlign:"center",
            [theme.breakpoints.down('md')]: {
                fontSize: "8px",
            }
        },
        FilterType: {
            width: "100%",
            textAlign:"center",
            // textOverflow: "ellipsis",
            // whiteSpce: "nowrap",
            // overflow: "hidden",
            color: theme.palette.highlight,
            [theme.breakpoints.down('md')]: {
                fontSize: "10px",
            }
        },
        CancelWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
        },
        Cancel: {
            fontFamily: theme.palette.gothamMedFont,
            fontSize: "9px",
            fontWeight: "800",
            opacity: "0.7",
            // paddingLeft: "3px",
            // paddingTop: "16px",
            paddingRight: "4px",
        },
    })
)

const FilterDisplay = ({filters, removeFilter, clearFilters}) => {

    const theme = useTheme();
    const classes =  useStyles(theme);
    
    /* Helper to extract attr: value pairs */
    const getFilters = (filterObj) => {
        /* Filter has format
            {
                attr: [values]
            }
        */
        let filterPairs = [];
        Object.keys(filterObj).forEach((attr) => {
            const vals = filterObj[attr];
            vals.forEach((val) => {
                filterPairs.push([attr, val]);
            })
        })
        return filterPairs;
    }
    const [filterPairs, setFilterPairs] = useState(getFilters(filters));
    useEffect(() => {
        setFilterPairs(getFilters(filters));
    }, [filters])

    const filterCards = filterPairs.map((filterPair) => {
        const [attr, val] = filterPair;
        return (
            <div key={attr+val}>
                <FadeIn>
                    <Grid container item justifyContent="center" alignItems="center" className={classes.FilterCard}
                        onClick={() => {
                            removeFilter(attr, val);
                        }}>
                        <div className={classes.FilterWrapper}>
                            <span className={classes.FilterAttr}>
                                {`${attr.toUpperCase()}`}
                            </span>
                            <span className={classes.FilterType}>
                                {`${val.toUpperCase()}`}
                            </span>
                        </div>
                        <div className={classes.CancelWrapper}>
                            <span className={classes.Cancel}>
                                X
                            </span>
                        </div>
                    </Grid>
                </FadeIn>
            </div>
        )
    })

    return (
        <div className={classes.Wrapper}>
            <div className={classes.FilterInfo}>
                <span className={classes.FilterText}>FILTERS:</span>
                <span className={classes.FilterCount}>{filterPairs.length}</span>
                <Button className={classes.ClearBtn} onClick={clearFilters}
                    variant="outlined">CLEAR</Button>
            </div>
            <div className={classes.FilterCardsWrapper}>
                {filterCards}
            </div>
        </div>
    )
}

export default FilterDisplay;
