import { useEffect, useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { MainRaffleData, SubRaffleData, backgrounds } from './raffleData';
import RaffleComponent from './RaffleComponent';
import RaffleParticipantsDialog from './RaffleParticipantsDialog';
import { AccountContext } from '../MetaMaskUtils';
import { RaffleContext } from "../FirebaseUtils";

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import './styles.css';

const useStyles = (mobileView, narrowView) => makeStyles(theme => ({
        outer: {
            backgroundImage: `url(${backgrounds.cover})`,
            backgroundPosition: 'center',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: "4.4rem",
            height:`${mobileView ? '80vh' : '100vh'}`,
            minHeight:`${mobileView ? "200px" : "700px"}`,
            maxHeight:`${mobileView ? "600px" : `${narrowView ? '800px' : '1000px'}`}`, 
        },
        header: {
            paddingTop: `${mobileView ? '4rem' : '3rem'}`,
            paddingBottom: "1rem",
            color: "white",
            fontSize: `${mobileView ? '2.3rem' : '3.3rem'}`
        },
        wrapper: {
            // width: "auto",
            position: "relative",
            height:'100%',
            // backgroundColor:'rgb(0,255,255,0.2)',
        },
        mainSection: {
            position: "absolute",
            top: "0",
            zIndex: "3",
            height: "100%",
            width: "100%",
            // width: `${mobileView ? '90%' : '80%'}`,
            // height: 'undefined',
            // aspectRatio: `2.47`,
            // backgroundColor: 'rgb(0,0,255,0.2)',
        },
        topLeftBg: {
            position: "absolute",
            top: `${narrowView ? "calc(45% + min(-35px,-7.6vw))" : "calc(15% + min(-50px,-7.6vw))"}`,
            left: `${mobileView ? '-6%' : '-1%'}`,
            zIndex: "1",
            width: "25%"
        },
        centreBox: {
            position: "absolute",
            top: `${narrowView ? '45%' : '15%'}`,
            zIndex: "2",
            width: `${mobileView ? '90%' : '80%'}`,
            // minWidth: `${mobileView ? '200px' : '380px'}`
        },
        toggleWrapper: {
            position: 'absolute',
            top: "0",
            // backgroundColor:'white',
        },
        toggleRaffleBtn: {
            ...theme.palette.btnDisabledBackground,
            fontFamily: theme.palette.blockyFont,
            fontSize: `${narrowView ? 'max(0.85rem,min(1rem,2.3vw))' : 'max(1.2rem,min(1.45rem,1.6vw))'}`,
            zIndex: "5",
            minWidth: `80%`,
            maxWidth: "80%",
            minHeight: `${narrowView ? "25px" : "40px"}`,
            maxHeight: `${narrowView ? "25px" : "40px"}`,
            borderRadius: "5px",
            "&:disabled": {
                color: theme.palette.primary.dark,
                border: `3px solid ${theme.palette.primary.dark}`,
                backgroundColor: theme.palette.primary.light,
                borderRadius: "7px",
                minWidth: `100%`,
                maxWidth: "100%",
                minHeight: `${narrowView ? "40px" : "55px"}`,
                maxHeight: `${narrowView ? "40px" : "55px"}`,
            }
        },
    })
)

const Raffle = ({mobileView}) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const raffleContext = useContext(RaffleContext);
    
    // for view
    const [ narrowView, setNarrowView ] = useState(false);
    useEffect(() => {   
        if (window.visualViewport.width < 700) {
            setNarrowView(true);
        }
        else {
            setNarrowView(false);
        }
    })  

    const classes =  useStyles(mobileView,narrowView)(theme);

    // toggle raffle. Default main
    const [mainRaffle, setMainRaffle] = useState(true);
    const selectRaffle = (raffle) => {
        if ( raffle === "MAIN" ) {
            setMainRaffle(true);
            return;
        }
        setMainRaffle(false);
    }

    // Show Raffle Participants
    const [ showParticipants, setShowParticipants ] = useState(false);
    const handleShowParticipants = () => {
        setShowParticipants(!showParticipants);
    }

    return (
        <Grid container justifyContent="center" alignContent="center" className={classes.outer}>
            <RaffleParticipantsDialog
                mobileView={mobileView} 
                showParticipants={showParticipants}
                participants={mainRaffle ? raffleContext.mainRaffleParticipants : raffleContext.subRaffleParticipants}
                handleShowParticipants={handleShowParticipants}
                />
            <Grid container item direction="row" justifyContent="center" alignItems="center"
                sx={{height: '100%'}}>
                {/* Title */}
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    sx={{height: '23%'}}>
                    <Typography variant="h2" className={classes.header}>
                        RaFFle
                    </Typography>
                </Grid>
                {/* Wrapper for Raffle Content */}
                <Grid container item xs={12} justifyContent="center" alignItems="center" 
                    sx={{
                        height:"77%",
                    }}>
                    <Grid container item xs={12} md={10} lg={9} justifyContent="center" alignItems="center" className={classes.wrapper}>
                        <img src={backgrounds.topLeft} alt="topleftbg" className={classes.topLeftBg} />
                        <img src={backgrounds.main} alt="centrebox" className={classes.centreBox} />
                        {/* Buttons to toggle raffle */}
                        <Grid container item xs={12} justifyContent="center" alignItems="center"
                            className={classes.toggleWrapper}>
                            {/* Main */}
                            <Grid container item xs={2.5} md={2} lg={1.5} justifyContent="flex-end" alignItems="center">
                                <Button 
                                    onClick = { () => {
                                        selectRaffle("MAIN")
                                    } }
                                    className={classes.toggleRaffleBtn}
                                    disabled={mainRaffle}
                                >
                                    Main
                                </Button>
                            </Grid>
                            {/* Sub */}
                            {/* <Grid container item xs={2.5} md={2} lg={1.5} justifyContent="flex-start" alignItems="center">
                                <Button 
                                    onClick = { () => {
                                        selectRaffle("SUB")
                                    } }
                                    className={classes.toggleRaffleBtn}
                                    disabled={!mainRaffle}
                                >
                                    Sub
                                </Button>
                            </Grid> */}
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="space-evenly"
                            className={classes.mainSection}>
                            <SwitchTransition mode={"out-in"}>
                                <CSSTransition
                                    key={mainRaffle}
                                    classNames={`${mainRaffle ? "mainRaffle" : "subRaffle"}`}
                                    addEndListener={(node, done) => {
                                        node.addEventListener("transitionend", done, false);
                                    }}
                                >
                                    <div style={{height:"100%", width:"100%"}}>
                                        { mainRaffle ? 
                                            <RaffleComponent 
                                                narrowView = {narrowView}
                                                mobileView={mobileView}
                                                raffleData={MainRaffleData} 
                                                enterRaffleHandler={accountContext.enterMainRaffle}
                                                handleShowParticipants={handleShowParticipants}
                                                />
                                            :
                                            <RaffleComponent 
                                                narrowView = {narrowView}
                                                mobileView={mobileView}
                                                raffleData={SubRaffleData} 
                                                enterRaffleHandler={accountContext.enterSubRaffle}
                                                handleShowParticipants={handleShowParticipants}
                                                />
                                        }
                                    </div>
                                </CSSTransition>
                            </SwitchTransition>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Raffle;