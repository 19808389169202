import Header from "./HeaderComponent/Header";
import Footer from "./FooterComponent/Footer";
import MyTigers from "./MyTigersComponent/MyTigers";

import ScrollToTop from "./Utils/ScrollToTop";

function MyTigersPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
        <Header mobileView={mobileView.headerMobileView} />
        <MyTigers mobileView={mobileView.headerMobileView} />
        <Footer />
      </div>
    </ScrollToTop>
  );
}

export default MyTigersPage;
