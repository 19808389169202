import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const avatarSz = {
    lg: {
        width: 8, //vw
        minWidth: 70, //px
        maxWidth: 80, //px
        outerMaxWidth: 160, 
        contentMinHeight: 160,
        contentHeight: 12, //vh
        contentMinWidth: 110, //vw
        contentPaddingTop: 2.8,
        outerPadding: "0.2rem 0.5rem",
        bgColor: "#bebebe",
        fontColor: "black",
    },
    sm: {
        width: 5,  //vw
        minWidth: 70, //px
        maxWidth: 80, //px
        outerMaxWidth: 150,
        contentMinHeight:  100,
        contentHeight: 13, //vw
        contentPaddingTop: 2.4,
        contentMinWidth: 90, //vw
        outerPadding: "0.2rem 0.4rem",
        bgColor: "transparent",
        fontColor: "white",
    }
};


const useStyles = avatar => makeStyles((theme) => ({
    outer: {
        maxWidth: `${avatar.outerMaxWidth}px`,
        backgroundColor:'transparent',
        border: "none",
        boxShadow: "none",
        padding: avatar.outerPadding,
    },
    top: {
        minHeight:`${avatar.minWidth/2}px`,
        height:`${avatar.width/2}vw`,
        maxHeight:`${avatar.maxWidth/2}px`
    },
    avatar: {
        width:`${avatar.width}vw`,
        height:`${avatar.width}vw`,
        minWidth:`${avatar.minWidth}px`,
        minHeight:`${avatar.minWidth}px`,
        maxWidth:`${avatar.maxWidth}px`,
        maxHeight:`${avatar.maxWidth}px`,
    },
    content: {
        minHeight: `${avatar.contentMinHeight}px`,
        minWidth: `${avatar.contentMinWidth}px`,
        height: `${avatar.contentHeight}vh`,
        width: "20vw !important",
        backgroundColor: `${avatar.bgColor}`,
        borderRadius: "6px",
        // paddingTop: `${avatar.width/2}vw`,
        paddingTop: `${avatar.contentPaddingTop}rem`
    },
    memberTitle: {
        paddingTop: '10px',
        fontFamily:  "gothamMedium",
        color: theme.palette.highlight,
        // backgroundColor:"white"
        fontSize: "14px"
    },
    memberName: {   
        color: theme.palette.offWhite, //`${avatar.fontColor}`,
        fontFamily: "blockyPixel",
        paddingTop: "5px",
    }
}));

const Member = ({ member, sz }) => {
    const theme = useTheme(); 
    const classes = useStyles(avatarSz[sz])(theme);
    return (
        <Grid container item className={classes.outer} justifyContent="center" alignItems="center">
            <Grid container item justifyContent="center" alignItems="center">
                {/* { (sz === 'lg') &&  */}
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    className={classes.top}>
                    <Avatar src={member.image.src} alt={member.name}
                        className={classes.avatar}>
                    </Avatar>
                </Grid>
                {/* } */}
                <Grid container item justifyContent="center" alignItems="flex-start"  className={classes.content}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography variant={sz === 'lg' ? 'h5' : 'h6'} align="center"
                                className={classes.memberName}>
                                {member.name}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography variant="body1" className={classes.memberTitle} align="center">
                                {member.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
} 

export default Member;