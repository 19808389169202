import { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { CSSTransition } from 'react-transition-group';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import FadeIn from './FadeIn';

const useStyles = () => makeStyles(theme => ({
        /* Transition */
        fadeEnter: {
            opacity: "0",
            marginTop: "10px",
        },
        fadeEnterActive: {
            opacity: "1",
            marginTop: "0px",
            transition: "all .2s ease-in",
        },
        fadeExit: {
            opacity: "1",
            marginTop: "0px",
        },
        fadeExitActive: {
            opacity: "0",
            backgroundColor: "red",
            marginTop: "10px",
            transition: "all .1s ease-in",
        },
        HideCard: {
            display: "none",
        },
        ShowCard: {
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: alpha(theme.palette.primary.dark, 0.5),
            height: "100%",
            width: "100%",
            zIndex: "999",
        },
        ContentWrapper: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        CardWrapper: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        ToggleBtn: {
            position: "absolute",
            top: "0",
            left: "0",
            height: "100vh",
            width: "100%",
            backgroundColor: "transparent",
            zIndex: "1",
        },
        CardBox: {
            width: "500px",
            aspectRatio: "0.8",
            backgroundColor: `#8CA18C`,
            zIndex: "10",
            borderRadius: "10px",
            border: `4px solid ${theme.palette.primary.dark}`,
            boxShadow: `0px 20px 30px 0px ${theme.palette.primary.light}`,
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gridAutoRows: "10%",
            [theme.breakpoints.down('lg')]: {
                width: "480px",
            },
            [theme.breakpoints.down('md')]: {
                width: "400px",
                aspectRatio: "0.65",
            },
            [theme.breakpoints.down('sm')]: {
                width: "340px",
            }
        },
        /* Title classes */
        Title: {
        },
        TitleBox: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
        },
        TitleBoxLeft: {
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        TitleBoxRight: {
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "40px !important",
        },
        TitleType: {
            backgroundColor: alpha(theme.palette.primary.dark, 0.6),
            color: theme.palette.offWhite,
            padding: "6px 6px",
            margin: "0px 6px",
            borderRadius: "8px",
            fontFamily: theme.palette.pixelFont,
            [theme.breakpoints.down('lg')]: {
                fontSize: "16px"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "14px"
            }
        },
        TitleName: {
            color: 'black',
            fontFamily: theme.palette.gothamMedFont,
            fontSize: "20px",
            [theme.breakpoints.down('lg')]: {
                fontSize: "18px"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "16px"
            }
        },
        TitleRank: {
            color: theme.palette.primary.dark,
            fontFamily: theme.palette.pixelFont,
            fontSize: "20px",
            [theme.breakpoints.down('lg')]: {
                fontSize: "18px"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "16px"
            },
        },
        TitleIcon: {
            height: "40%",
            margin: "0px 8px",
        },
        /* Image classes */
        DisplayImage: {
            gridRowStart: "2",
            gridRowEnd: "7",
            position: 'relative',
            
            backgroundImage: `url(/assets/images/main/WelcomeToTheJungle.webp)`,
            backgroundPosition: 'center',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            [theme.breakpoints.down('md')]: {
                gridRowEnd: "6",
            }
        },
        TigerImage: {
            position: 'absolute',
            height: "100%",
            aspectRatio: "1",
        },
        Properties: {
            gridRowStart: "7",
            gridRowEnd: "10",
            display: "flex",
            flexDirection: "column",

            [theme.breakpoints.down('md')]: {
                gridRowStart: "6",
            }
        },
        PropertiesHeader: {
            width: "95%",
            padding: "6px 10px",
            fontFamily: theme.palette.gothamMedFont,
            fontSize: "12px",
            textDecoration: "underline",
        },
        AttributesWrapper: {
            height: "95%",
            width: "98%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridAutoRows: "40px",
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: "repeat(2, 1fr)",
                gridAutoRows: "36px",
            },
            overflow: "hidden",
        },
        AttributeCard: {
            backgroundColor: alpha(theme.palette.offWhite, 0.4),
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 6px",
            margin: "4px 4px",
        },
        AttrType: {
            fontFamily: theme.palette.pixelFont,
            color: theme.palette.primary.dark,
            fontSize: "10px",
            paddingRight: "2px",
            [theme.breakpoints.down('lg')]: {
                fontSize: "9.5px",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "9px"
            }
        },
        AttrVal: {
            fontFamily: theme.palette.gothamMedfont,
            fontSize: "12px",
            fontWeight: "600",

            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            [theme.breakpoints.down('lg')]: {
                fontSize: "11px",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "10px"
            }
        },
        Links: {
            gridRowStart: "10",
            gridRowEnd: "11",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        LinksOS: {
            height: "30px",
            padding: "4px 20px",
            [theme.breakpoints.down('md')]: {
                height: "26px"
            }
        }
    })
)

const PopUpCard = ({tiger, show, toggleShow}) => {

    const theme = useTheme();
    const classes =  useStyles()(theme);

    const RenderAttributes = ({attributes}) => {
        const attrKeys = [...Object.keys(attributes)];
        // for md and above, need to put all the long text in same col
        attrKeys.sort((a, b) => 
            attributes[b].length -  attributes[a].length);
        const numRows = Math.ceil(attrKeys.length / 3);

        // rearrange
        const longestFew = attrKeys.slice(0, numRows);
        const rest = attrKeys.slice(numRows);
        let rearranged = [];
        let longestIdx = 0;
        let restIdx = 0;
        for (let i = 0; i < attrKeys.length; i++) {
            if (i % 3 === 0) { // first col
                rearranged.push(longestFew[longestIdx]);
                longestIdx++;
            } else {
                rearranged.push(rest[restIdx]);
                restIdx++;
            }
        }

        return rearranged.map((attr, idx) => {
            const val = attributes[attr];
            return (
                <div key={attr+idx} className={classes.AttributeCard}>
                    <span className={classes.AttrType}>{`${attr}`}</span>
                    <span className={classes.AttrVal}>{val}</span>
                </div> 
            )
        })
    }

    return (
        <CSSTransition in={show} unmountOnExit timeout={500} classNames={{
            enter: classes.fadeEnter,
            enterActive: classes.fadeEnterActive,
            exit: classes.fadeExit,
            exitActive: classes.fadeExitActive,
        }}>
            <div id="Card" className={classes.ShowCard}>
                <div className={classes.CardWrapper}>
                    <button className={classes.ToggleBtn} onClick={toggleShow}>
                    </button>
                    <div className={classes.CardBox}>
                        <div className={`${classes.Title} ${classes.ContentWrapper}`}>
                            <div className={classes.TitleBox}>
                                {/* Type, Name, Rank, Tiger Icon */}
                                <div className={classes.TitleBoxLeft}>
                                    <span className={classes.TitleType}>{tiger.type}</span>
                                    <span className={classes.TitleName}>{tiger.name}</span> 
                                </div>
                                <div className={classes.TitleBoxRight}>
                                    <span className={classes.TitleRank}>{(tiger.type !== "Cub") && `#${tiger.rank}`}</span>
                                    <img className={classes.TitleIcon} src={`/assets/images/ui/Mint/tigerIcon.webp`} alt="tigerIcon" />
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.DisplayImage} ${classes.ContentWrapper}`}>
                            {/* Image */}
                            <img className={classes.TigerImage} src={tiger.image} alt={tiger.name} />
                            {/* <img className={classes.ImageTopLeftBg} src={`/assets/images/ui/Mint/TopLeft_Bg.webp`} alt={'left background'} /> */}
                        </div>
                        <div className={`${classes.Properties} ${classes.ContentWrapper}`}>
                            {/* Attributes */}
                            <span className={classes.PropertiesHeader}>Properties</span>
                            <div className={classes.AttributesWrapper}>
                                <RenderAttributes attributes={tiger.attributes}/>
                            </div>
                        </div>
                        <div className={`${classes.Links} ${classes.ContentWrapper}`}>
                            {/* Opensea URL */}
                            <a href={`https://opensea.io/assets/ethereum/0xe1b05227824e45f5713ea8bf6e4ad59b3d4a98ef/${tiger.tokenId}`} target="_blank" rel="noreferrer">
                                <img className={classes.LinksOS} src="/assets/images/socials/opensea.svg" alt="opensea" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default PopUpCard;