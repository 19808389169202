import { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CircleIcon from '@mui/icons-material/Circle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { AccountContext } from "../MetaMaskUtils";
import { RaffleContext } from "../FirebaseUtils";

import Prizes from './RafflePrizes';

const useStyles = (mobileView, narrowView) => makeStyles(theme => ({
        statusLabel: {
            marginBottom: '1.5rem',
        },
        mainSection: {
            position: "absolute",
            top: `${narrowView ? '45%' : '15%'}`,
            zIndex: "3",
            width: `${mobileView ? '90%' : '80%'}`,
            height: 'undefined',
            aspectRatio: `2.47`,
            // backgroundColor: 'rgb(0,0,255,0.2)'
        },
        overallWrapper: {
            // marginTop: "2%",
            height: "100%",
            widht: "100%",
            position: 'relative',
            // height: `${narrowView ? '100%' : '90%'}`,
            // width: `96%`,
            // backgroundColor:'rgb(255,0,0,0.2)'
        },
        narrowPrizeSection: {
            position: "absolute",
            top: `${narrowView ? '15%' : '15%'}`,
            zIndex: "3",
            width: `${mobileView ? '60%' : 'min(280px,45%)'}`,
            height: 'undefined',
            aspectRatio: `2.47`,
            // backgroundColor: 'rgb(0,255,255,0.2)'
        },
        prizesWrapper: {
            height:"100%",
            // backgroundColor:'rgb(255,0,0,0.2)'
        },
        descWrapper: {
            height: "100%"
        },
        // For Description
        wrapper:{
            height: "100%",
            // backgroundColor:"rgb(125,125,20,0.2)"
        },
        countdown: {
            fontSize: `${narrowView ? 'max(0.7rem,min(0.8rem,1.8vw))' : 'max(0.8rem,min(1.13rem,1.2vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.offWhite,
        },
        raffleButtonOutline: {
            position:'absolute',
            width: "95%",
            marginRight: "5%",
            // height: "60%",
            height: `${narrowView ? '20px' : '31px'}`,
            border: `max(1px,min(2px,0.2vw)) solid white`,
            borderRadius: `${narrowView ? '4px' : '7px'}`,
        },
        raffleBtn: {
            ...theme.palette.btnBackground,
            fontSize: "0.6rem",
            minWidth: `max(60px,100%)`,
            maxWidth: "max(60px,100%)",
            minHeight: `${narrowView ? "22px" : "35px"}`,
            maxHeight: `${narrowView ? "22px" : "35px"}`,
            borderRadius: `${narrowView ? '4px' : '7px'}`,
            border: `max(1px,min(2px,0.2vw)) solid ${theme.palette.highlight}`,
            "& .MuiButton-root": {
                minWidth: "20% !important",
            },
            "&:disabled": {
                border: `max(1px,min(2px,0.2vw)) solid #A9A9A9`,
                color: theme.palette.offWhite,
                backgroundColor: theme.palette.grey_disable
            },
            "&:hover": {
                backgroundColor:'black',
            },
        },
        raffleBtnTxt: {
            width: '90%',
            zIndex: "5",
            textDecoration: 'none',
            // backgroundColor:'yellow',
            "& .MuiInput-input":{ // style of balance num overall
                padding: "0px 7px",
                fontSize: "max(0.7rem,0.9vw)",
                fontFamily: theme.palette.gothamMedFont,
                color: theme.palette.offWhite,
            },
        },
        raffleBtnUnderline: {
            "&:before": {
                borderBottom: "none",
            },
            "&:after": {
                borderBottom: "1px solid white",
            }
        },
        balanceAdornment: {
            "& .MuiTypography-root": { // style of $PIXELS in balance
                fontFamily: theme.palette.gothamMedFont,
                fontSize: "max(0.7rem,0.8vw)",
                "-webkit-text-fill-color": 'white',
                paddingRight: '5px'
            },   
        },
        descText: {
            fontSize: `${narrowView ? 'max(0.55rem,min(0.7rem,1.7vw))' : 'max(0.6rem,min(0.9rem,1vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.offWhite,
        },
        descListitem: {
            width: "100%",
            // backgroundColor:" rgb(125,125,0,0.4)"
            padding: "0",
        },
        descListitemicon: {
            minWidth: "min(20px,10%)",
            // maxWidth: "5px",
            // backgroundColor:" rgb(125,125,0,0.4)"
        },
        descListitemtext: {
            padding: "0",
            margin: '0',
        },
        raffleHelperText: {
            fontSize: `${narrowView ? 'max(0.55rem,min(0.7rem,1.7vw))' : 'max(0.6rem,min(0.9rem,1vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.highlight,
        },
        enterText: {
            fontSize: `${narrowView ? "max(0.6rem,0.8vw)" : "max(0.7rem,0.9vw)"}`,
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.offWhite,
        },
        participants: {
            fontSize: `${narrowView ? "max(0.6rem,0.6vw)" : "max(0.7rem,0.7vw)"}`,
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.highlight,
            '&:hover': {
                fontWeight: "bolder"
            }
        }
    })
)

const RaffleComponent = ({ narrowView, mobileView, raffleData, enterRaffleHandler, handleShowParticipants }) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const raffleContext = useContext(RaffleContext);

    const classes = useStyles(mobileView, narrowView)(theme);

    /* Raffle participant details */
    const [ raffleParticipants, setRaffleParticipants ] = useState({
        num: 0,
        participants: []
    })
    const participants = (raffleData.type === "main") ? raffleContext.mainRaffleParticipants : raffleContext.subRaffleParticipants;
    const num = (raffleData.type === "main") ? raffleContext.numEntriesMain : raffleContext.numEntriesSub;
    useEffect(() => {
        setRaffleParticipants({
            num: num,
            participants: participants
        })        
    }, [participants, num]);

    /* EnterRaffle */
    const [ enterStatus, setEnterStatus ] = useState("IDLE");
    // wrapper to pass to enter raffle
    const updateEnterStatus = ((status) => {
        setEnterStatus(status);
    })
    
    /* Variable & checks for raffle tix */
    const [ raffleState, setRaffleState ] = useState({
        numTix: 1,
        text: "1",
        error: "",
    })
    // Check utils
    const getNum = (numString) => {
        return parseInt(numString, 10);
    };
    const isNumeric = (numString) => {
        return /^\d+$/.test(numString);
    };
    const handleRaffleTextChanged = (event) => {
        const val = event.target.value;
        // Error handling
        if (val.length === 0) {
            // empty    
            setRaffleState({
                error: "Should not be empty!",
                numTix: 0,
                text: val,
            });
        } else if (!isNumeric(val)) {
            // not int
            setRaffleState({
                error: "Should be a valid integer!",
                numTix: 0,
                text: val,
            });
        } else if (getNum(val) <= 0) {
            // number less than 0
            setRaffleState({
                error: "Should be greater than 0!",
                numTix: 0,
                text: val,
            });
        } else {
            setRaffleState({
                error: "",
                numTix: getNum(val),
                text: val,
            });
        }
    };

    // check raffle active status
    const [ raffleActive, setRaffleActive ] = useState(false);
    useEffect(() => {
        // if is main raffle
        if (raffleData.type === "main") {
            setRaffleActive(accountContext.mainRaffleActive);
        }
        if (raffleData.type === "sub") {
            setRaffleActive(accountContext.subRaffleActive);
        }
    }, [accountContext.mainRaffleActive, accountContext.subRaffleActive, raffleData.type])
    
    /* Countdown to raffle */
    const [ timeLeft, setTimeLeft ] = useState(null);
    const end = new Date(raffleData.RaffleEnd);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            if (end < now) { // passed raffle end
                setTimeLeft(-1);
                return;
            }
            const diff = new Date(end.getTime() - now.getTime());
            
            setTimeLeft(
                {
                    seconds: Math.floor( diff /     1000 %   60 ),
                    minutes: Math.floor( diff /    60000 %   60 ),
                    hours:   Math.floor( diff /  3600000 %   24 ),
                    days:    Math.floor( diff / 86400000        )
                }
            )
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft])
    const Countdown = () => {
        let countdownText = "Calculating ...";
        if (timeLeft) {
            if (timeLeft === -1) {
                countdownText = "Raffle has ended"
            } else {
                if (timeLeft.days > 0){
                    countdownText = `RAFFLE ENDS IN: ${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m`
                } else {
                    countdownText = `RAFFLE ENDS IN: ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
                }
            }
        }
        return (
            
            <Typography variant="body1"
                className={classes.countdown}>
                {countdownText}
            </Typography>
        )
    }

    // Status
    const GetStatusLabel = () => {
        var label = ''

        // Raffle not active
        if (!raffleActive) {
            label = "Raffle is not active";
        }
        else if (timeLeft === -1) {
            label = "Raffle has ended";
        }
        else {
            if (accountContext.numOwnedTigers > 0) { // check if own tigers
                if (enterStatus === "FAILED") {
                    label = "Transaction Failed";    
                } else if (enterStatus === "ENTERING") {
                    label = "Entering ...";
                } else if (enterStatus === "ENTERED") {
                    label = "Successfully entered!";
                }
            }
            else if (accountContext.numOwnedTigers === 0){
                label = "You do not own any Tigers";
            }
        }
        
        if (label.length > 0){
            return <Typography variant="body1" className={classes.raffleHelperText}>{label}</Typography>
        } else {
            return <div></div>
        }
    }

    // Buttons
    const raffleButton = (
        <Box sx={{ position: "relative", width: "100%"}}>
            <Button 
                disabled={(raffleState.error !== "") || (accountContext.numOwnedTigers <= 0) || !raffleActive || (timeLeft === -1)}
                onClick = { () => {
                    enterRaffleHandler(raffleState.numTix, updateEnterStatus)
                } }
                className={classes.raffleBtn}
            >
                Enter
            </Button>   
            { enterStatus === "ENTERING" && (
                <CircularProgress
                    size={20}
                    sx={{
                    color: theme.palette.offWhite,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    }}
                />
            )}
        </Box>
    )
    const connectButton = (
        <Box sx={{ position: "relative", width: "100%" }}>
            <Button 
                onClick = { accountContext.connectWalletHandler }
                className={classes.raffleBtn}
            >
                Connect
            </Button>  
        </Box> 
    )


    /* Description Container */
    // Format description
    const raffleDesc = raffleData.Description.map((line, i) => {
        return (
            <ListItem
                key={line+i}
                classes={{ root: classes.descListitem }}
                >
                <ListItemIcon
                    classes={{root: classes.descListitemicon}}>
                    <CircleIcon 
                        sx={{
                            height: "max(0.2rem, min(0.35rem, 0.8vw))",
                            color: theme.palette.offWhite,
                        }}/>
                </ListItemIcon>
                <ListItemText 
                    primary={line}
                    classes={{
                        root: classes.descListitemtext,
                        primary: classes.descText
                    }}
                />
            </ListItem>
        )
    })
    

    return (
        <Grid container item justifyContent="center" alignItems="center" className={classes.overallWrapper}>
            {narrowView ? 
                // Narrow View
                <Grid container xs={12} item justifyContent="center" alignItems="center" 
                    sx={{
                        height: "100%",
                    }}>
                    <Grid container item xs={12} justifyContent="center" alignItems="center" className={classes.narrowPrizeSection}>    
                        <Grid container item justifyContent="center" alignItems="center" className={classes.prizesWrapper}>
                            < Prizes 
                                narrowView={narrowView} 
                                mobileView={mobileView} 
                                raffleData={raffleData}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center" alignItems="center" className={classes.mainSection}>
                        <Grid container item justifyContent="center" alignItems="center" 
                            sx={{
                                height: "91%",
                                width: "96%",
                            }}>
                            <Grid container item xs={12} justifyContent="center" alignItems="space-evenly" className={classes.wrapper}>
                                <Grid container item xs={12} justifyContent="center" alignContent="center">
                                    <Countdown />
                                </Grid>
                                <Grid container item xs={12} justifyContent="center" alignContent="center">
                                    <List dense={true} disablePadding>
                                        {raffleDesc}
                                    </List>
                                </Grid>
                                <Grid container item xs={12} justifyContent="center" alignContent="center">
                                    <Grid container item xs={11} md={10} lg={9} justifyContent="center" alignItems="center">
                                        <Grid container item xs={narrowView ? 8 : 12} md={12} justifyContent="center" alignItems="center">
                                            <Grid container item xs={7} justifyContent="flex-start" alignItems="center">
                                                <Typography variant="body1"
                                                    className={classes.enterText}
                                                    mb={0.5}
                                                    >
                                                    {`Enter ${raffleData.Header}:`}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={5} justifyContent="flex-end" alignItems="center">
                                                <Button
                                                    sx={{
                                                        textDecoration: "none",
                                                        textTransform: "none",
                                                        padding: "0 0",
                                                    }}
                                                    disabled={raffleParticipants.num === 0}
                                                    onClick={handleShowParticipants}
                                                    >
                                                    <Typography variant="body1"
                                                        className={classes.participants}
                                                        mb={0.5}
                                                        >
                                                        {`${raffleParticipants.num} entries`}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={narrowView ? 8 : 12} md={12} justifyContent="flex-start" alignItems="center"
                                            sx={{position:'relative'}}
                                            mb={0.5}>
                                            <div className={classes.raffleButtonOutline}></div>
                                            <Grid container item xs={narrowView ? 9 : 8.5} md={8.5} justifyContent="flex-end" alignItems="center">
                                                <TextField
                                                    variant="standard"
                                                    value={raffleState.text}
                                                    onChange={handleRaffleTextChanged}
                                                    error={raffleState.error !== ""}
                                                    // helperText={raffleState.error}
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment position="end" 
                                                                className={classes.balanceAdornment}>
                                                                Tickets
                                                            </InputAdornment>,
                                                            classes: {
                                                                underline: classes.raffleBtnUnderline
                                                            }
                                                        // disableUnderline: true
                                                    }}
                                                    className={classes.raffleBtnTxt}
                                                />
                                            </Grid>
                                            <Grid container item xs={3} md={3.5} justifyContent="center" alignItems="center">
                                                { accountContext.currentAccount ? raffleButton : connectButton}
                                            </Grid>
                                        </Grid>
                                        { (raffleState.error !== "") ?
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                    <Typography variant="body1"
                                                        className={classes.raffleHelperText}>
                                                        {raffleState.error}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                    <GetStatusLabel />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : 
                // Large View
                <Grid container item justifyContent="center" alignItems="center" className={classes.mainSection}>
                    <Grid container item justifyContent="center" alignItems="center" 
                        sx={{
                            height: "91%",
                            width: "96%",
                        }}>
                        <Grid container item xs={7.5} justifyContent="center" alignItems="center" className={classes.prizesWrapper}>
                            < Prizes 
                                narrowView={narrowView} 
                                mobileView={mobileView} 
                                raffleData={raffleData}
                            />
                        </Grid>
                        <Grid container item xs={4.5} justifyContent="center" alignItems="center" className={classes.descWrapper}>
                            <Grid container item xs={12} justifyContent="center" alignItems="space-evenly" className={classes.wrapper}>
                                <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                                    <Grid container item xs={12} md={11} justifyContent="center" alignContent="center">
                                        <Countdown />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                                    <Grid container item xs={11} md={11} justifyContent="center" alignContent="center">
                                        <List dense={true} disablePadding>
                                            {raffleDesc}
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} justifyContent="center" alignContent="center">
                                    <Grid container item xs={11} md={11} justifyContent="center" alignItems="center">
                                        <Grid container item xs={narrowView ? 8 : 12} md={12} justifyContent="flex-start" alignItems="center">
                                            <Grid container item xs={7} justifyContent="flex-start" alignItems="center">
                                                <Typography variant="body1"
                                                    className={classes.enterText}
                                                    mb={0.5}
                                                    >
                                                    {`Enter ${raffleData.Header}:`}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={5} justifyContent="flex-end" alignItems="center">
                                                <Button
                                                    sx={{
                                                        textDecoration: "none",
                                                        padding: "0 0",
                                                        textTransform: "none",
                                                    }}
                                                    disabled={raffleParticipants.num === 0}
                                                    onClick={handleShowParticipants}
                                                    >
                                                    <Typography variant="body1"
                                                        className={classes.participants}
                                                        mb={0.5}
                                                        >
                                                        {`${raffleParticipants.num} entries`}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={narrowView ? 8 : 12} md={12} justifyContent="flex-start" alignItems="center"
                                            sx={{position:'relative'}}
                                            mb={0.5}>
                                            <div className={classes.raffleButtonOutline}></div>
                                            <Grid container item xs={narrowView ? 9 : 8.5} md={8.5} justifyContent="flex-end" alignItems="center">
                                                <TextField
                                                    variant="standard"
                                                    value={raffleState.text}
                                                    onChange={handleRaffleTextChanged}
                                                    error={raffleState.error !== ""}
                                                    InputProps={{
                                                        endAdornment: 
                                                            <InputAdornment position="end" 
                                                                className={classes.balanceAdornment}>
                                                                Tickets
                                                            </InputAdornment>,
                                                            classes: {
                                                                underline: classes.raffleBtnUnderline
                                                            }
                                                    }}
                                                    className={classes.raffleBtnTxt}
                                                />
                                            </Grid>
                                            <Grid container item xs={3} md={3.5} justifyContent="center" alignItems="center">
                                                { accountContext.currentAccount ? raffleButton : connectButton}
                                            </Grid>
                                        </Grid>
                                        { (raffleState.error !== "") ?
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                    <Typography variant="body1"
                                                        className={classes.raffleHelperText}>
                                                        {raffleState.error}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                    <GetStatusLabel />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}
export default RaffleComponent;
