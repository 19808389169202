import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { socialsData, desc, copyright } from './footerData';

import AppearInView from '../Utils/AppearInView';

const useStyles = makeStyles(theme => ({
        outerGrid: {
            paddingTop: "3rem",
            paddingBottom: "3rem",
            backgroundColor:theme.palette.primary.dark
        },
        innerGrid: {
            width: "80%",
        },
        socialButton: {
            height: "1.7rem",
            // width: "100%",
            margin: "0.2em",
            paddingLeft: "0.4rem",
            paddingRight: "0.4rem",
            // backgroundColor: theme.palette.footer.social_bg
        },
        footerDesc: {
            color: theme.palette.footer.desc,
            paddingBottom:"20px"
        },
        brand: {
            paddingTop:"5px",
            paddingBottom:"10px"
        }
    })
)

const Footer = () => {
    const theme = useTheme();
    const { outerGrid, innerGrid, brand, socialButton, footerDesc } =  useStyles(theme);

    // Social Buttons
    const getSocialButtons = () => { 
        return socialsData.map(({ src, href, alt }) => {
            return (
                <a href={href} key={src} target="_blank" rel="noreferrer">
                    <img className={socialButton} src={src} alt={alt}></img>
                </a>
            )
        });
    }

    return (
        <Grid container direction="row" justifyContent="center"
            className={outerGrid}
            >
            <Grid container item className={innerGrid} justifyContent="center" >
                <AppearInView variant="fade">
                    <Grid container item xs={10} justifyContent="flex-start">
                        <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                            {getSocialButtons()}
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                            <Typography variant="h3" style={{fontFamily: "blockyPixel"}} className={brand}>
                                PixelTigers
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                            <Typography variant="body1" className={footerDesc} style={{fontFamily: "gothamMedium"}}>
                                {desc}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-start" alignContent="center">
                            <Typography variant="caption" style={{fontFamily: "gothamMedium"}}>
                                {copyright}
                            </Typography>
                        </Grid>
                    </Grid>
                </AppearInView>
            </Grid>
        </Grid>
    )
}

export default Footer;