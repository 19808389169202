import { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = (mobileView) => makeStyles(theme => ({
        toggleTypeBtn: {
            ...theme.palette.btnDisabledBackground,
            fontFamily: theme.palette.blockyFont,
            fontSize: `${mobileView ? 'max(1.2rem,min(1.45rem,1.6vw))' : 'max(1.2rem,min(1.45rem,1.6vw))'}`,
            zIndex: "1",
            // minWidth: mobileView ? `160px` : `200px`,
            // maxWidth: mobileView ? `160px` : "200px",
            // minHeight: `${mobileView ? "50px" : "50px"}`,
            // maxHeight: `${mobileView ? "50px" : "50px"}`,
            width: "40%",
            borderRadius: "5px",
            color: theme.palette.primary.dark,
            border: `3px solid ${theme.palette.primary.dark}`,
            backgroundColor: "grey",
            margin: "10px",
            "&:disabled": {
                color: theme.palette.highlight,
                border: `3px solid ${theme.palette.highlight}`,
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "7px",
            },
            [theme.breakpoints.down('md')]: {
                width: "min(40%, 200px)",
            }
        },
    })
)

const TypeSelect = ({mobileView, selectedType, setSelectedType}) => {

    const theme = useTheme();
    const classes =  useStyles(mobileView.mobileView)(theme);

    return (
        <Grid container xs={12} justifyContent="center" alignItems="center">
            {/* Toggle select Genesis vs Cub */}
            <Grid container xs={12} justifyContent="center" alignItems="center"
                >
                <Button className={classes.toggleTypeBtn}
                    disabled={selectedType==="Genesis"}
                    onClick={() => setSelectedType("Genesis")}>
                    Genesis
                </Button>
                <Button className={classes.toggleTypeBtn}
                    disabled={selectedType==="Cub"}
                    onClick={() => setSelectedType("Cub")}>
                    Cub
                </Button>
            </Grid>
        </Grid>   
    )
}

export default TypeSelect;