import { useState, useEffect, useContext } from "react";

import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import InputLabel from '@mui/material/InputLabel';
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import MintButton from "./MintButton";
import { mintImages, backgrounds, tigerIcon } from "./mintData";

import { AccountContext } from "../MetaMaskUtils";
import "./styles.css";

const useStyles = (narrowView, mobileView) =>
  makeStyles((theme) => ({
    outer: {
      paddingTop: "4.4rem",
      height: "100%",
      width: "100%",
    },
    header: {
      zIndex: "2",
      color: "white",
    },
    mintSection: {
      // minHeight: "400px",
      // padding: "1rem",
      // marginTop: `${mobileView ? '0rem':'0.5rem'}`,
      // marginBottom: `${mobileView ? '0rem':'2rem'}`
    },
    numberDisplay: {
      // paddingBottom: "1rem",
      color: "white",
      fontFamily: "blockyPixel",
      fontSize: `${narrowView ? "max(1.35rem,min(4rem, 5vw))" : "max(2.2rem,min(3.5rem, 2.9vw))"}`,
    },
    icon: {
      height: `${narrowView ? "max(20px,min(42px, 5vw))" : "max(20px,min(42px, 2.9vw))"}`,
      paddingLeft: "5px",
      paddingBottom: `${mobileView ? "5px" : "5px"}`,
    },
    mainSection: {
      width: "80%",
      height: "undefined",
      aspectRatio: "2",
      zIndex: "2",
      position: "relative",
    },
    centreBox: {
      position: "absolute",
      top: `0`,
      zIndex: "2",
      width: `100%`,
    },
    mainSectionInnerWrap: {
      height: "undefined",
      aspectRatio: "2",
      position: "absolute",
      top: 0,
      zIndex: "3",
    },
    numberText: {
      color: "white",
      fontSize: "max(0.8rem, min(1.1rem, 1.3vw))",
      fontFamily: "arial",
      // fontWeight: "bold",
      paddingBottom: `${mobileView ? "10%" : "12%"}`,
    },
    userInputArea: {
      // border: "1px solid white",
      maxHeight: "38px",
      maxWidth: "90%",
      borderRadius: "5px",
    },
    tigerImg: {
      width: "100%",
      height: "100%",
      borderRadius: "5px",
      backgroundColor: "white",
      // maxWidth: "200px",
      // height: "",
      // width: "auto",
      // backgroundColor:"white"
    },
    wrapper: {
      backgroundImage: `url(${backgrounds.cover})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      minHeight: `${mobileView ? "200px" : "500px"}`,
      maxHeight: `${mobileView ? "600px" : "1000px"}`,
      // width: "auto",
      position: "relative",
      background: "#1F3037",
      overflow: "hidden",
    },
    backgroundLayer: {
      width: "100%",
      height: "100%",
      position: "relative",
      top: "0px",
      bottom: "0px",
      right: "0px",
      left: "0px",
    },
    topLeftBg: {
      paddingTop: "4.4rem",
      position: "absolute",
      top: "0px",
      left: "0px",
      zIndex: "1",
      maxWidth: "max(150px,20vw)",
    },
    topRightBg: {
      paddingTop: "4.4rem",
      position: "absolute",
      top: "0px",
      right: "0px",
      zIndex: "1",
      maxWidth: "max(170px,25vw)",
    },
    bottomLeftBg: {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      zIndex: "1",
      maxWidth: "max(200px,30vw)",
    },
    bottomRightBg: {
      position: "absolute",
      bottom: "0px",
      right: "0px",
      zIndex: "1",
      maxWidth: "max(150px,20vw)",
    },
    mintContent: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
    inputText: {
      width: "100%",
      color: "white",
      "& .MuiInput-root": {
        borderBottom: "1px solid white",
      },
      "& .MuiInputBase-input": {
        // style of balance num overall
        color: "white",
      },
      "& .MuiTypography-root": {
        // style of $PIXELS in balance
        "-webkit-text-fill-color": `white !important`,
      },
    },
    inputTextSelect: {
      "& .MuiSelect-select": {
        borderBottom: "1px solid white",
        color: "white",
        "-webkit-text-fill-color": `white !important`,
      },
    },
    inputTextSelectIcon: {
      fill: "white",
    },
    label: {
      fontSize: `${
        mobileView
          ? "max(0.65rem,min(0.7rem,1.7vw))"
          : "max(0.7rem,min(0.9rem,1vw))"
      }`,
      fontFamily: theme.palette.gothamMedFont,
      wordWrap: "break-word",
      width: `${mobileView ? "50%" : "100%"}`,
      color: theme.palette.offWhite,
      "&:hover": {
        // border: `1px solid ${theme.palette.highlight}`,
        borderRadius: "5px",
      },
    },
  }));

const Mint = ({ mobileView }) => {
  const theme = useTheme();
  const accountContext = useContext(AccountContext);

  // for view
  const [narrowView, setNarrowView] = useState(false);
  useEffect(() => {
    if (window.visualViewport.width < 875) {
      setNarrowView(true);
    } else {
      setNarrowView(false);
    }
  });

  const classes = useStyles(narrowView, mobileView)(theme);

  /* Rotating image gallery */
  const [imageIdx, setImageIdx] = useState(0);
  useEffect(() => {
    const updateImage = setInterval(() => {
      var idx = (imageIdx + 1) % mintImages.length;
      setImageIdx(idx);
    }, 375);
    return () => clearInterval(updateImage);
  }, [imageIdx]);

  /* Number to mint */
  const [numMintState, setNumMintState] = useState({
    numMint: 1,
    text: "1",
    error: "",
  });
  // Utils to check validity of number to mint
  // const getNum = (numString) => {
  //   return parseInt(numString, 10);
  // };
  // const isNumeric = (numString) => {
  //   return /^\d+$/.test(numString);
  // };
  /* For select */
  const handleNumMintSelect = (event) => {
    setNumMintState((prevState) => ({
      ...prevState,
      numMint: event.target.value,
    }));
  };
  /* For text field */
  // const handleNumMintText = (event) => {
  //   const val = event.target.value;
  //   // Error handling
  //   if (val.length === 0) {
  //     // empty
  //     setNumMintState({
  //       error: "Should not be empty!",
  //       numMint: 0,
  //       text: val,
  //     });
  //   } else if (!isNumeric(val)) {
  //     // not int
  //     setNumMintState({
  //       error: "Should be a valid integer!",
  //       numMint: 0,
  //       text: val,
  //     });
  //   } else if (getNum(val) <= 0) {
  //     // number less than 0
  //     setNumMintState({
  //       error: "Should be greater than 0!",
  //       numMint: 0,
  //       text: val,
  //     });
  //   } else if (getNum(val) > 2) {
  //     // number greater than 2
  //     setNumMintState({
  //       error: "Only 2 can be minted at a time!",
  //       numMint: 0,
  //       text: val,
  //     })
  //   } else {
  //     setNumMintState({
  //       error: "",
  //       numMint: getNum(val),
  //       text: val,
  //     });
  //   }
  // };

  /* Status message for minting process */
  const [mintStatus, setMintStatus] = useState("");
  const [txnUrl, setTxnUrl] = useState("");
  // wrapper to pass into mintButton
  const updateMintStatus = (status, url) => {
    setMintStatus(status);
    setTxnUrl(url);
  };

  const GetMintLabel = () => {
    var label = "";
    if (mintStatus === "FAILED") {
      label = "Mint Failed";
    }
    if (mintStatus === "MINTING") {
      label = "Minting in progress ...";
    }
    if (mintStatus === "MINTED") {
      label = `Minted @ ${txnUrl.substring(0, 35)}...`;
      return (
        <Link
          sx={{
            textDecoration: "none",
            width: `${mobileView ? "50%" : "100%"}`,
            height: "100%",
            padding: "0",
          }}
          href={txnUrl}
          target="_blank"
        >
          <Button
            sx={{
              minWidth: `100%`,
              maxWidth: `100%`,
              textTransform: "none",
              padding: "0",
            }}
          >
            <Typography
              variant="body1"
              align="center"
              className={classes.label}
              sx={{
                "&:hover": {
                  // border: `1px solid ${theme.palette.highlight}`,
                  color: theme.palette.highlight,
                  fontWeight: "bolder",
                },
              }}
            >
              {label}
            </Typography>
            ;
          </Button>
        </Link>
      );
    }
    if (label.length > 0) {
      return (
        <Typography variant="body1" align="center" className={classes.label}>
          {label}
        </Typography>
      );
    }
    return <div></div>;
  };

  const InstructionText = () => {
    let instrText = "";

    if (accountContext.currentAccount) {
      if (accountContext.mintActive) {
        // mint is up
        instrText = `Pixel Tigers`;
      } else if (accountContext.presaleActive) {
        // during presale: check if eligible
        if (accountContext.presaleAmount > 0) {
          instrText = `Whitelist slots: ${accountContext.presaleAmount}`;
        } else {
          instrText = "No Whitelist slots. rawr.";
        }
      } else {
        // Not during mint
        instrText = "Its not time yet!";
      }
    } else {
      // wallet not connected
      instrText = `Connect your wallet!`;
    }

    return (
      <Typography variant="body1" className={classes.numberText}>
        {instrText}
      </Typography>
    );
  };

  const GetMintButton = () => {
    return (
      <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
        <Grid container justifyContent="center" alignItems="center">
          <MintButton
            narrowView={narrowView}
            numMint={numMintState.numMint}
            mintStatusHandler={updateMintStatus}
          />
        </Grid>
        {mintStatus === "MINTING" && (
          <CircularProgress
            size={20}
            sx={{
              color: theme.palette.offWhite,
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <div className={classes.wrapper}>
      <img src={backgrounds.topLeft} alt="topleftbg" className={classes.topLeftBg}
      />
      <img src={backgrounds.topRight} alt="toprightbg" className={classes.topRightBg}
      />
      <img src={backgrounds.bottomLeft} alt="bottomleftbg" className={classes.bottomLeftBg}
      />
      <img src={backgrounds.bottomRight} alt="bottomrightbg" className={classes.bottomRightBg}
      />
      <div className={classes.mintContent}>
        <Grid container justifyContent="center" alignItems="center" className={classes.outer} >
          <Grid container item justifyContent="center" alignItems="flex-start" sx={{ height: "100%" }}>
            {/* Title */}
            <Grid container item xs={12} justifyContent="center" alignItems="center" className={classes.header}
              sx={{ height: "27%" }} >
              <Typography variant="h2">Mint</Typography>
            </Grid>
            <Grid container item xs={10} md={7} lg={6} xl={5} justifyContent="center" alignItems="center"
              className={classes.mainSection} sx={{ height: "73%" }} >
              <img src={backgrounds.main} alt="centrebox" className={classes.centreBox} />
              <Grid container item justifyContent="center" alignItems="center" className={classes.mainSectionInnerWrap} >
                {/* Avatar */}
                <Grid container item xs={6} justifyContent="center" alignItems="center" >
                  {/* Add responsive size if required */}
                  <Grid container item xs={9.5} justifyContent="flex-end" alignItems="center" >
                    <img
                      src={mintImages[imageIdx].src}
                      alt={mintImages[imageIdx].alt}
                      className={classes.tigerImg}
                    />
                  </Grid>
                </Grid>
                {/* Mint side */}
                <Grid container item xs={6} justifyContent="center" className={classes.mintSection} >
                  {/* Show stats */}
                  <Grid container item xs={12} justifyContent="center" alignItems="center" >
                    <Grid container item xs={12} justifyContent="flex-start" alignItems="center" >
                      <Typography
                        variant={mobileView ? "h4" : "h3"}
                        className={classes.numberDisplay}
                      >
                        {`${
                          accountContext.totalSupply
                            ? accountContext.totalSupply
                            : "?"
                        } mints`}
                      </Typography>
                      <img src={tigerIcon} alt="" className={classes.icon}
                      ></img>
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-start" alignItems="center" >
                      <InstructionText />
                    </Grid>
                  </Grid>
                  {/* Mint Functions */}
                  <Grid container item xs={12} justifyContent="flex-start" alignItems="center" >
                    <Grid container item xs={11} justifyContent="center" alignItems="center" 
                      className={classes.userInputArea}>
                      <Grid container item xs={12} md={6.5} lg={8} justifyContent="flex-end" alignItems="center" mb={1}>
                        {/* <TextField
                          variant="standard"
                          value={numMintState.text}
                          onChange={handleNumMintText}
                          error={numMintState.error !== ""}
                          helperText={numMintState.error}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">PixelTigers</InputAdornment>
                            ),
                          }}
                          className={classes.inputText}
                        ></TextField> */}
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            variant="standard"
                            id="numMintSelect"
                            value={numMintState.numMint}
                            onChange={handleNumMintSelect}
                            className={classes.inputTextSelect}
                            inputProps={{
                              classes: {
                                icon: classes.inputTextSelectIcon,
                              },
                            }}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={12} md={5.5} lg={4} justifyContent="center" alignItems="center" mb={1} >
                        {!mobileView && <GetMintButton />}
                      </Grid>
                      {/* label to update minting status */}
                      {!narrowView && (
                        <Grid container item xs={12} justifyContent="center" alignItems="center" >
                          <GetMintLabel />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                sx={{ position: "absolute", top: `max(38%,42vw)`, zIndex: "6" }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  mb={1}
                >
                  {mobileView && <GetMintButton />}
                </Grid>
                {narrowView && (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <GetMintLabel />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Mint;
