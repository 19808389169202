import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import Voting from './VotingComponent/Voting';

import { VoteContextProvider } from '../context/VotingContext';

import ScrollToTop from './Utils/ScrollToTop';


function VotingPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
        <Header mobileView={mobileView.headerMobileView}/>
            <VoteContextProvider>
                <Voting mobileView={mobileView.mobileView}/>
            </VoteContextProvider>
        <Footer />
      </div>
    </ScrollToTop>
  );
}

export default VotingPage;
