import { useState, useEffect } from 'react'; 
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = (mobileView) => makeStyles(theme => ({
    outer: {
        backgroundImage: `url(/assets/images/main/SubPageBg.webp)`,
        backgroundPosition: 'center',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "6rem",
        // height: "160vh",
        minHeight:`${mobileView ? "300px" : "500px"}`,
        maxHeight:`${mobileView ? "600px" : "1600px"}`, 
    }, 
    header: {
        color: "white",
        fontSize: `${mobileView ? '2.3rem' : '3.3rem'}`
    },
    gameWrapper: {
        padding: "20px 0px",
    },
    gameDesc: {
        fontSize: `${mobileView ? "max(0.7rem,min(1rem,2vw))" : "max(0.85rem,min(0.9rem,1.2vw))"}`,
        color: theme.palette.offWhite,
        fontFamily: theme.palette.gothamMedFont,
        zIndex: "3",
        width: "100%",
        margin: "4px 10px",
    },
}))


const JungleRun = ({mobileView}) => {

    const theme = useTheme();
    const classes = useStyles(mobileView)(theme);


    const htmlSrc = `./junglerungame.html?apiKey=${process.env.REACT_APP_API_KEY}&` +
                `authDomain=${process.env.REACT_APP_AUTH_DOMAIN}&` +
                `databaseURL=${process.env.REACT_APP_DATABASE_URL}&` +
                `projectId=${process.env.REACT_APP_PROJECT_ID}&` +
                `storageBucket=${process.env.REACT_APP_STORAGE_BUCKET}&` +
                `messagingSenderId=${process.envREACT_APP_MESSAGING_SENDER_ID}&` +
                `appId=${process.env.REACT_APP_APP_ID}&` +
                `measurementId=${process.env.REACT_APP_MEASUREMENT_ID}`;
    return (
        <div className={classes.outer}> 
            <Grid container justifyContent="center" alignItems="center"
                sx={{height: "100%"}}>
                {/* Title */} 
                <Grid container item xs={12} justifyContent="center" alignItems="flex-end"
                    pb={3}>
                    <Typography variant="h2" className={classes.header}>
                        BETA
                    </Typography>
                </Grid>  
                {/* Info text */}
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <Grid container item xs={mobileView ? 11 : 9} md={10} lg={8} xl={7} justifyContent="center" alignItems="flex-start">
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography variant="body1"
                                align="center"
                                pb={2}
                                sx={{
                                    fontFamily: `${theme.palette.pixelFont} !important`,
                                    fontSize: `${mobileView ? "max(0.8rem,min(1.1rem,2.1vw))" : "max(0.95rem,min(1.4rem,1.3vw))"}`,
                                    color: theme.palette.highlight,
                                }}>
                                Welcome to The Jungle.
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={8} justifyContent="center" alignItems="center">
                            <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.gameDesc}>
                                    The gates have opened! The Jungle calls upon every Tiger to set out on a journey. 
                                    The Objective? Collect pixel shards. How? Run! Avoid obstacles, pick up power-ups and make it all the way to the top. 
                                    Pixels are temporary, glory is forever.
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.gameDesc}>
                                    Please note the game is still in the BETA stage. Our developers and community are currently working together to finalise everything. The game utilises MetaMask and in the future will only be available to Tiger or Cub holders. However, the BETA is currently open to the public to try and enjoy 🙂
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.gameDesc}>
                                    Can you beat the community's high score?
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.gameDesc}
                                    pt={2}
                                    sx={{fontStyle: "italic"}}>
                                    You do not require any funds to play, connect your MetaMask and refresh the page. You may have to refresh a few times for MetaMask to pick up the connection.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={4} justifyContent="center" alignItems="center">
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="left"
                                    className={classes.gameDesc}
                                    sx={{color: `${theme.palette.highlight} !important`}}>
                                    Controls:
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="left"
                                    className={classes.gameDesc}>
                                    Left Click - Jump / Double Jump 
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.gameDesc}>
                                    Right Click - Duck
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>  
                <Grid container item className={classes.gameWrapper} justifyContent="center" alignItems="center">
                    { mobileView ? 
                        <Typography variant="body1" className={classes.gameDesc}
                            align="center">
                            Game is not supported on mobile
                        </Typography>
                        :
                        <iframe src={htmlSrc} 
                            style={{
                                height: `${mobileView ? "320px" : "640px"}`,
                                width: `${mobileView ? "480px" : "960px"}`, 
                                border: "none"
                            }}></iframe>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default JungleRun;