import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui//material/Typography';

import { images, title } from './carouselData';

import AppearInView from '../Utils/AppearInView';
import './carousel.css';


const useStyles = mobileView => makeStyles((theme) => ({
    outer: {
        paddingTop: "6rem",
        paddingBottom: "4rem",
        marginLeft: 'auto',
        marginRight: "auto",
    },
    carouselItem: {
        minWidth:`80px`,
        minHeight:`80px`,
        width: "10vw",
        height: "10vw"
    },
    title: {
        paddingBottom: "2rem",
        color: "white",
        fontFamily: "blockyPixel"
    },
    introTextWrapper: {
        color: "white",
        paddingTop: "2.5rem",
        paddingBottom: "4.8rem",
    },
    introText: {
        fontFamily: "gothamMedium",
        width: "100%",
        justify: "center",
        color: theme.palette.white
    },
    arrow: {
        height: `${mobileView ? '50px' : '100%'}`
    }
}));

const Carousel = ({mobileView}) => {
    const theme = useTheme();
    const classes = useStyles(mobileView)(theme);
    
    // number items in carousel
    const [numItems, setNumItems] = useState(5);
    useEffect(() => {
        let isMounted=true;
        
        const changeWidth = () => {
            if (isMounted){
                if (window.visualViewport.width < 500) {
                    return setNumItems(3);
                }
                if (window.visualViewport.width < 700) {
                    return setNumItems(4);
                }
                setNumItems(5);
            }
        };
        
        changeWidth();
        window.addEventListener("resize", () => changeWidth());

        return () => {
            isMounted = false;
            window.removeEventListener("resize", () => changeWidth());
        }
    });


    // handle carousel functionality
    const [currentIndex, setCurrentIndex] = useState(1);
    const next = () => {
        if (currentIndex < (images.length - numItems)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    const GetImages = () => {
        return images.map(({src, alt}) => {
            return (
                <div key={src}>
                    <Grid container justifyContent="center" alignItems="center">
                        {/* <Grid container item justifyContent="center" alignItems="center" sx={{backgroundColor:'white'}}> */}
                            <Avatar src={src} alt={alt} className={classes.carouselItem}
                                variant="rounded"
                            >
                            </Avatar>
                        {/* </Grid> */}
                    </Grid>
                </div>
            )
        })
    }

    const Carousel = () => (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                <div className="carousel-content-wrapper">
                    <div className={`carousel-content show-${numItems}`}
                        style={{ transform: `translateX(-${currentIndex * (100/numItems)}%)` }}>
                        {/* Carousel items */}
                        <GetImages />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Grid container item justifyContent="center" alignItems="center"
            className={classes.outer}>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
                <AppearInView variant="fade" offset="150px">
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                        <Grid container item xs={8} justifyContent="center" alignItems="center">
                            <Typography variant="h4" className={classes.title}>
                                {title}
                            </Typography>
                        </Grid>    
                    </Grid>
                </AppearInView>
                <AppearInView variant="fade" offset="150px">
                    <Grid container item xs={11} md={8} justifyContent="space-evenly" alignItems="center">
                        {/* left arrow */}
                        <Grid container item xs={1} justifyContent="center" alignItems="center">
                            { currentIndex > 0 && 
                                <Button
                                    color="inherit"
                                    onClick={prev}
                                >
                                    <img src="/assets/images/ui/Carousel/carouselBtnLeft.webp" alt="<" className={classes.arrow}/>
                                </Button>
                            }
                        </Grid>
                        {/* carousel */}
                        <Grid container item xs={9} md={10} justifyContent="center" alignItems="center">
                            <Carousel />
                        </Grid>
                        {/* right arrow */}
                        <Grid container item xs={1} justifyContent="center" alignItems="center">
                            { currentIndex < (images.length - numItems) && 
                                <Button
                                    color="inherit"
                                    onClick={next}
                                >
                                    <img src="/assets/images/ui/Carousel/carouselBtnRight.webp" alt=">" className={classes.arrow}/>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </AppearInView>
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
                <AppearInView variant="fade" offset="150px">
                    <Grid container item xs={9} md={8} lg={6} justifyContent="center" alignItems="center"
                        className={classes.introTextWrapper}>
                        <Typography variant={mobileView ? "body2" : "body1"}  align="center" className={classes.introText}
                            style={{
                                paddingBottom: "12px"
                            }}>
                            Enter the world of PixelTigers
                        </Typography>
                        <Typography variant={mobileView ? "body2" : "body1"}  align="center" className={classes.introText}
                            style={{}}>
                            The Jungle is a place with a single rule: survival of the fittest. 
                        </Typography>
                        <Typography variant={mobileView ? "body2" : "body1"}  align="center" className={classes.introText}
                            style={{}}>
                            PixelTigers have long existed in this special realm, ever since the world dived into the blockchain and never looked back. Here, they discovered that living things have no need for air, food, or water to survive, but only one resource, pixels.
                        </Typography>
                        <Typography variant={mobileView ? "body2" : "body1"}  align="center" className={classes.introText}
                            style={{
                                paddingTop: "12px"
                            }}>
                        Brimming with energy, they were the only source of survival. PixelTigers soon realized they further needed $PIXEL to breed and expand. Come and collect pixels with us in The Jungle - and as for what choices you make, it's up to you.
                        </Typography>
                    </Grid>
                </AppearInView>
            </Grid>
        </Grid>
    )
}

export default Carousel;