import { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ConnectButton from '../Buttons/ConnectButton';
import { AccountContext } from '../MetaMaskUtils';
import { useAuth } from '../../context/AuthContext';
import { backgrounds } from './linkAccountData.js';
import { LoginUI, LogoutButton } from './Login';
import { walletLinkFn } from '../../utils/firebaseUtils/walletLinkUtils';
import axios from "axios";
import Member from "../TeamComponent/Member"

const useStyles = (mobileView) => makeStyles(theme => ({
        outer: {
            backgroundImage: `url(${backgrounds.cover})`,
            backgroundPosition: 'center',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: "4.4rem",
            backgroundColor: "#1F3037",
            height:"120vh",
            minHeight:`${mobileView ? "200px" : "500px"}`,
            maxHeight:`${mobileView ? "600px" : "1000px"}`, 
        },
        
    })
)

const TIGER_API_URL = "https://confident-einstein-ef3a1f.netlify.app/api/metadata/user"

const LinkAccount = ({ mobileView }) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const auth = useAuth();
    const classes = useStyles(mobileView)(theme)
    
    // User's stored wallet
    const [storedWallet, setStoredWallet] = useState(null);
    const getAndSetWallet = async (uid) => {
        const walletAddr = await walletLinkFn.getWalletAddr(uid);
        setStoredWallet(walletAddr);
    }
    useEffect(() => { // fetch if login
        if (auth.user !== null && auth.user.email !== null) { // if is a valid user
            getAndSetWallet(auth.user.uid);
        }
        if (auth.user == null || auth.user.email == null) { // no user / anonymous user
            setStoredWallet(null)
        }
    }, [auth.user])

    // owned tigers. Integration with tiger api that queries directly from contract
    const [storedOwnedTigers, setStoredOwnedTigers] = useState(null);
    useEffect(() => {
        if (storedWallet !== null) {
            axios.get(`${TIGER_API_URL}/${storedWallet}`)
                .then((res) => {
                    if (res.data !== null) {
                        const tigerData = res.data.data;
                        let tigers = []
                        for (const [id, tiger] of Object.entries(tigerData)) {
                            tigers.push(tiger);
                        }
                        setStoredOwnedTigers(tigers);
                    }
                })
                .catch((err) => {
                    setStoredOwnedTigers([]);
                })
        } else {
            setStoredOwnedTigers(null);
        }
    }, [storedWallet])

    const LinkButton = () => {
        const handleLinkWallet = async () => {
            const success = await walletLinkFn.addWalletToUID(accountContext.currentAccount, auth.user.uid);
            if (success) {
                getAndSetWallet(auth.user.uid);
            }
        }
        const handleUnlinkWallet = async () => {
            const success = await walletLinkFn.deleteWalletLink(auth.user.uid);
            if (success) {
                getAndSetWallet(auth.user.uid);
            }
        }

        
        return (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            color: theme.palette.offWhite,
                            padding: "10px 0px",
                            textAlign: "center",
                        }}>
                        { storedWallet == null ? 
                            `Wallet found: ${accountContext.currentAccount}`
                            :
                            `Linked wallet: ${storedWallet}`
                        }
                    </Typography>                    
                </Grid>
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    { storedWallet == null ?
                        <Button
                            sx={{
                                ...theme.palette.btnBackground
                            }}
                            onClick={() => handleLinkWallet()}>
                            Link wallet
                        </Button>
                        :
                        <Button
                            sx={{
                                ...theme.palette.btnBackground
                            }}
                            onClick={() => handleUnlinkWallet()}>
                            Unlink wallet
                        </Button>
                    }
                </Grid>
            </Grid>
        )
    }

    const OwnedTigers = () => {
        if (storedOwnedTigers == null) {
            return <></>
        }

        if (storedOwnedTigers.length == 0) {
            return (
                <Typography
                    sx={{
                        color: theme.palette.offWhite,
                        fontFamily: theme.palette.gothamMedFont,
                        fontSize: "1rem",
                    }}>
                    None
                </Typography>
            )
        }

        return storedOwnedTigers.map((tiger) => {
            return (
                <Grid
                    container
                    item
                    xs={4}
                    md={2}
                    justifyContent="center"
                    alignItems="center"
                    key={tiger.tokenId}
                    >
                    <a
                        href={tiger.osUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration:"none"
                        }}>
                        <Member member={{
                            name: tiger.tokenId.toString(),
                            image: {
                                src: tiger.image,
                            },
                            title: "",
                        }} sz={"sm"} />
                    </a>
                </Grid>
            )
        })
    }

    return (
        <div className={classes.outer}>
            { (accountContext.currentAccount !== null) ?
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    sx={{ height: "80%" }}>
                    <Typography variant="body1"
                        align="center"
                        pb={2}
                        sx={{
                            fontFamily: `${theme.palette.pixelFont} !important`,
                            // fontSize: `${mobileView ? "max(0.8rem,min(1.1rem,2.1vw))" : "max(0.95rem,min(1.4rem,1.3vw))"}`,
                            fontSize: "1.6rem",
                            color: theme.palette.highlight,
                        }}>
                        Link your wallet to use your tigers in Jungle Run!
                    </Typography>
                    { auth.user !== undefined && auth.user !== null && auth.user.email !== null ?
                    /* Display details if logged in */
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <LinkButton />
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center"
                            pt={3} pb={3}>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                { storedWallet !== null &&
                                    <Typography sx={{
                                        fontSize: '1.2rem',
                                        fontFamily: theme.palette.blockyFont,
                                        color: theme.palette.offWhite,
                                        padding: "10px 0px",
                                    }}>
                                        Linked Tigers 
                                    </Typography>
                                }
                            </Grid>
                            <Grid container item xs={11} md={6} justifyContent="center" alignItems="center">
                                <OwnedTigers />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center"
                            sx={{ padding: "10px 0px"}}>
                            <LogoutButton />
                        </Grid>
                    </Grid>
                    :
                    /* Not yet logged in */
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography
                                sx={{
                                    fontSize: "2rem",
                                    color:theme.palette.offWhite,
                                    fontFamily: theme.palette.blockyFont,
                                    paddingBottom: "1rem",
                                    textAlign: "center"
                                }}>
                                Log in to link your wallet to an account
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <LoginUI />
                        </Grid>
                    </Grid>
                    }
                </Grid>
                :
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    sx={{
                        height: "80%"
                    }}>
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography
                                sx={{
                                    fontSize: "2rem",
                                    color:theme.palette.offWhite,
                                    fontFamily: theme.palette.blockyFont,
                                    paddingBottom: "1rem",
                                    textAlign: "center"
                                }}>
                                Please connect your wallet!
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                            <ConnectButton />
                        </Grid>
                    </Grid>
                </Grid>
            }   
        </div>
    )
}

export default LinkAccount;