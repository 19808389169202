export const backgrounds = {
    cover: "/assets/images/main/SubPageBg.webp",
    tigerShop: "/assets/images/ui/Shop/TigerShop.webp",
    shopItem: "/assets/images/ui/Shop/ShopItem.webp",
    speech: "/assets/images/ui/Shop/speech.webp"
}

export const shopText = "Howdy! Check out our ongoing sale!"

export const shopItems = [
    {
        title: "Puff ME WL",
        data: {
            "key": "puffmeWL",
            "maxCount": 5,
            "price": 300,
            "img": "/assets/images/shop/puffme.jpg",
            "startDate": "26 Mar 2022 12:00:00 GMT+0800",
            "endDate": "29 Mar 2022 12:00:00 GMT+0800"
        }
    },
    {
        title: "Meta Space Babies WL",
        data: {
            "key": "metaspacebabiesWL",
            "maxCount": 20,
            "price": 250,
            "img": "/assets/images/shop/metaspacebaby.jpg",
            "startDate": "",
            "endDate": "29 Mar 2022 12:00:00 GMT+0800"
        }
    },
    {
        title: "Pastel Peeps WL",
        data: {
            "key": "pastelpeepsWL",
            "maxCount": 5,
            "price": 400,
            "img": "/assets/images/shop/pastelpeeps.jpg",
            "startDate": "",
            "endDate": "25 Mar 2022 23:59:00 GMT+0800"
        }
    },
    {
        title: "NOODS WL",
        data: {
            "key": "noodsWL",
            "maxCount": 5,
            "price": 400,
            "img": "/assets/images/shop/noods.gif",
            "startDate": "",
            "endDate": "25 Mar 2022 23:59:00 GMT+0800"
        }
    },
    {
        title: "Alienverse WL",
        data: {
            "key": "alienverseWL",
            "maxCount": 5,
            "price": 750,
            "img": "/assets/images/shop/alienverse.jpg",
            "startDate": "",
            "endDate": "17 Jan 2022 23:59:00 GMT+0800"
        }
    },
    {
        title: "Gummies Gang WL",
        data: {
            "key": "gummiesGangWL",
            "maxCount": 5,
            "price": 1500,
            "img": "/assets/images/shop/gummygang.gif",
            "startDate": "",
            "endDate": "17 Jan 2022 23:59:00 GMT+0800"
        }
    }
]