import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import AppearInView from "../Utils/AppearInView";

const useStyles = makeStyles((theme) => ({
  titleInBox: {
    position: "relative",
    color: "black",
    top: "-12vw",
    // left: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    left: "40.5%",
    transform: "translate(-49%, -48%)",
    width: "80%",
  },
  containerCentered: {
    position: "absolute",
    top: "35%",
    left: "43.5%",
    fontSize: "3.5vw",
    color: "#a6946d",
    fontFamily: "blockyPixel",
    transform: "translate(-15%,-20%)",
  },
  containerCenteredMobile: {
    position: "absolute",
    top: "30%",
    left: "43.5%",
    fontSize: "6vw",
    color: "#a6946d",
    fontFamily: "blockyPixel",
    transform: "translate(-30%,-35%)",
  },
  containerPic: {
    position: "relative",
    textAlign: "center",
    width: "100%",
  },
  container: {
    height: "100%", // So that grids 1 & 4 go all the way down
    // minHeight: 600, // Give minimum height to a div
    // maxHeight: 600,
    // fontSize: 30,
    textAlign: "center",
  },
  containerTall: {
    // maxHeight: 200, // This div has higher minimum height
    // minHeight: 200,
  },
  containerWords: {
    position: "absolute",
    top: "50%",
    left: "47%",
    transform: "translate(-50%, -52%)",
  },
  containerSides: {
    maxHeight: "40vw",
    textAlign: "center",
    overflow: "visible",
    // border: "1px solid black",
  },
}));

const PhasesData = [
  {
    description:
      "PixelTigers enter The Jungle. There will only be 1111 OG PixelTigers. The rest will be minted in phases. Immediately following each mint, $PIXEL will begin generating. No staking will be required. \n",
    title: "Initial OG and Genesis Launch \n",
  },
  {
    description:
      "$PIXEL will begin generating immediately after a Tiger is minted for holders to enter our raffles. Weekly raffles will then be held for distributing our Legendary Tigers until they are all distributed. \n",
    title: "Legendary Raffles \n",
  },

  {
    description:
      "Only through the effort of 2 PixelTigers, $PIXEL can be used to give life to a PixelCub. As pixels are a form of mystical energy, all PixelCubs given life through $PIXEL will have their own unique traits. PixelCubs can only be bred when the moon is just right so be sure to be on the lookout for breeding windows! \n",
    title: "PixelCubs \n",
  },
  {
    description:
      "The Jungle calls upon every Tiger to set out on a journey. Objective? Collect pixel shards. How? Run! Avoid obstacles, pick up power-ups and make it all the way to the top. Prizes and special perks can be won by the best Tigers. Pixels are temporary, glory is forever. \n",
    title: "Jungle Run \n",
  },
  {
    description:
      "Tigers are one of the fastest predators in The Jungle, it is only fitting that we must attack the NFT space at the fastest speeds possible to give us an edge. As a holder of a PixelTigers NFT, you get access to our in-house TigerNode for fast and secure transactions on the blockchain. \n",
    title: "TigerNode \n\n",
  },
  {
    description: "",
    title: "The Jungle is ever evolving \n",
  },
];
const Roadmap = ({ mobileView }) => {
  const theme = useTheme();
  const {
    containerCentered,
    containerPic,
    containerCenteredMobile,
    containerSides,
  } = useStyles(theme);
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!mobileView ? (
        <div style={{ overflow: "hidden" }}>
          <AppearInView variant="fade">
            <div className={containerPic}>
              <img
                src="/assets/images/ui/Roadmap/Roadmap_box.webp"
                alt="Snow"
                style={{
                  width: "25%",
                  textAlign: "center",
                }}
              />
              <div className={containerCentered}>Jungle Map</div>
            </div>
          </AppearInView>
          <Grid container direction="row" spacing={0}>
            <Grid item xs={5}>
              <div className={containerSides}>
                <div style={{ transform: "translate(5%, 25%)" }}>
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "18vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      paddingBottom: "1%",
                      width: "55%",
                      transform: "translate(42%, -15vw)",
                    }}
                  >
                    {PhasesData[1]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "55%",
                      fontFamily: "gothamMedium",
                      transform: "translate(42%, -15vw)",
                    }}
                  >
                    {PhasesData[1]["description"]}
                  </Typography>
                </div>
                <div style={{ transform: "translate(5%,5%)" }}>
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "18vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      width: "55%",
                      transform: "translate(42%, -15vw)",
                    }}
                  >
                    {PhasesData[3]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "55%",
                      fontFamily: "gothamMedium",
                      transform: "translate(42%, -15vw)",
                    }}
                  >
                    {PhasesData[3]["description"]}
                  </Typography>
                </div>
                <div style={{ transform: "translate(5%,-15%)" }}>
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "18vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      paddingBottom: "1%",
                      width: "55%",
                      transform: "translate(42%, -12vw)",
                    }}
                  >
                    {PhasesData[5]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "55%",
                      fontFamily: "gothamMedium",
                      transform: "translate(42%, -16vw)",
                    }}
                  >
                    {PhasesData[5]["description"]}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <img
                src="/assets/images/ui/Roadmap/vineLaptop.webp"
                style={{
                  height: "80vw",
                  display: "block",
                  marginLeft: "-42%",
                  marginTop: "-2%",
                  width: "auto",
                }}
              ></img>
            </Grid>
            <Grid item xs={5}>
              <div className={containerSides}>
                <div
                  className={containerPic}
                  style={{ transform: "translate(0,3%)" }}
                >
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "18vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      paddingBottom: "3%",
                      width: "55%",
                      transform: "translate(42%, -16vw)",
                    }}
                  >
                    {PhasesData[0]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "55%",
                      fontFamily: "gothamMedium",
                      transform: "translate(42%, -17vw)",
                    }}
                  >
                    {PhasesData[0]["description"]}
                  </Typography>
                </div>
                <div style={{ transform: "translate(0,-33%)" }}>
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "18vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      paddingBottom: "3%",
                      width: "55%",
                      transform: "translate(41%, -15vw)",
                    }}
                  >
                    {PhasesData[2]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "60%",
                      fontFamily: "gothamMedium",
                      transform: "translate(33%, -16vw)",
                    }}
                  >
                    {PhasesData[2]["description"]}
                  </Typography>
                </div>
                <div style={{ transform: "translate(0,-50%)" }}>
                  <img
                    src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                    alt="Snow"
                    style={{
                      width: "68%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      overflow: "visible",
                      height: "20vw",
                      position: "relative",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "blockyPixel",
                      paddingBottom: "3%",
                      width: "55%",
                      transform: "translate(42%, -17vw)",
                    }}
                  >
                    {PhasesData[4]["title"]}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "0.9vw",
                      width: "60%",
                      fontFamily: "gothamMedium",
                      transform: "translate(33%, -17vw)",
                    }}
                  >
                    {PhasesData[4]["description"]}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>{" "}
          <div
            className={containerPic}
            style={{ transform: "translate(2%,0)" }}
          >
            <img
              src="/assets/images/ui/Roadmap/pot.webp"
              alt="Snow"
              style={{
                width: "9%",
                marginLeft: "-2%",
              }}
            />
          </div>
        </div>
      ) : (
        <Grid
          container
          direction="column"
          style={{
            paddingBottom: "60%",
            marginTop: "-8%",
            backgroundImage: 'url("/assets/images/ui/Roadmap/longvine.webp")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "300vw",
            overflow: "hidden",
          }}
        >
          <Grid container item xs={1} xm={3}>
            <div className={containerPic}>
              <img
                src="/assets/images/ui/Roadmap/Roadmap_box.webp"
                alt="Snow"
                style={{
                  width: "40%",
                  textAlign: "center",
                }}
              />
              <div className={containerCenteredMobile}>Jungle Map</div>
            </div>
          </Grid>

          <Grid container item xs={1}>
            <div style={{ transform: "translate(15%, 10%)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "35vw",
                  position: "relative",
                }}
              />
              <Typography
                style={{
                  fontSize: "3.7vw",
                  fontFamily: "blockyPixel",
                  paddingBottom: "3.5%",
                  width: "55%",
                  transform: "translate(12%, -328%)",
                  textAlign: "center",
                }}
              >
                {PhasesData[0]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "2vw",
                  width: "60%",
                  fontFamily: "gothamMedium",
                  transform: "translate(7%, -31vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[0]["description"]}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={1}>
            <div style={{ transform: "translate(15%, -12vw)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "35vw",
                  position: "relative",
                }}
              />
              <Typography
                style={{
                  fontSize: "4vw",
                  fontFamily: "blockyPixel",
                  paddingBottom: "3%",
                  width: "55%",
                  transform: "translate(13%, -330%)",
                  textAlign: "center",
                }}
              >
                {PhasesData[1]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "1.9vw",
                  width: "55%",
                  fontFamily: "gothamMedium",
                  transform: "translate(12%, -31vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[1]["description"]}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={1}>
            <div style={{ transform: "translate(15%, -28vw)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "35vw",
                  position: "relative",
                }}
              />
              <Typography
                style={{
                  fontSize: "4vw",
                  fontFamily: "blockyPixel",
                  paddingBottom: "3%",
                  width: "55%",
                  transform: "translate(13%, -340%)",
                  textAlign: "center",
                }}
              >
                {PhasesData[2]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "1.9vw",
                  width: "55%",
                  fontFamily: "gothamMedium",
                  transform: "translate(12%, -33vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[2]["description"]}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={1}>
            <div style={{ transform: "translate(-85%, 232%)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "35vw",
                  position: "relative",
                }}
              />
              <Typography
                style={{
                  fontSize: "4vw",
                  fontFamily: "blockyPixel",
                  paddingBottom: "3%",
                  width: "55%",
                  transform: "translate(13%, -31vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[3]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "2vw",
                  width: "55%",
                  fontFamily: "gothamMedium",
                  transform: "translate(12%, -33vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[3]["description"]}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={1}>
            <div style={{ transform: "translate(-85%, 203%)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "36vw",
                  position: "relative",
                  paddingTop: "1%",
                }}
              />
              <Typography
                style={{
                  fontSize: "3.6vw",
                  fontFamily: "blockyPixel",
                  width: "55%",
                  transform: "translate(13%, -32vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[4]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "2vw",
                  width: "58%",
                  fontFamily: "gothamMedium",
                  transform: "translate(10%, -30vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[4]["description"]}
              </Typography>
            </div>
          </Grid>
          <Grid container item xs={1}>
            <div style={{ transform: "translate(-105%, 300%)" }}>
              <img
                src="/assets/images/ui/Roadmap/RM_textbox1.webp"
                alt="Snow"
                style={{
                  width: "68%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "visible",
                  height: "28vw",
                  position: "relative",
                }}
              />
              <Typography
                style={{
                  fontSize: "3vw",
                  fontFamily: "blockyPixel",
                  paddingBottom: "3.5%",
                  width: "80%",
                  transform: "translate(-6.5%, -240%)",
                  textAlign: "center",
                }}
              >
                {PhasesData[5]["title"]}
              </Typography>
              <Typography
                style={{
                  fontSize: "2vw",
                  width: "60%",
                  fontFamily: "gothamMedium",
                  transform: "translate(7%, -33vw)",
                  textAlign: "center",
                }}
              >
                {PhasesData[5]["description"]}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Roadmap;
