import { useState, useEffect, useContext } from "react";

import HomePage from "./Components/HomePage";
import MintPage from "./Components/MintPage";
import TokenPage from "./Components/TokenPage";
import RafflePage from "./Components/RafflePage";
import MyTigersPage from "./Components/MyTigersPage";
import VotingPage from "./Components/VotingPage";
import ShopPage from "./Components/ShopPage";
import JungleRunPage from './Components/JungleRunPage';
import GalleryPage from './Components/GalleryPage';

import { AccountContext } from "./Components/MetaMaskUtils";

import { useTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import LinkAccountPage from "./Components/LinkAccountPage";

function App() {
  const theme = useTheme();
  const accountContext = useContext(AccountContext);

  const [state, setState] = useState({
    mobileView: false,
    headerMobileView: false,
  });

  // const { mobileView, headerMobileView } = state;

   // Check mobile/desktop
  useEffect(() => {
    const setResponsiveness = () => {
      // return window.innerWidth < 1050
      //   ? setState((prevState) => ({ ...prevState, mobileView: true}))
      //   : setState((prevState) => ({ ...prevState, mobileView: false }));
      if (window.innerWidth < 500) {
        return setState((prevState) => ({ ...prevState, headerMobileView: true, mobileView: true }))
      }
      else if (window.innerWidth < 1200){
        return setState((prevState) => ({ ...prevState, headerMobileView: true, mobileView: false }))
      }
       
      return setState((prevState) => ({ ...prevState, mobileView: false, headerMobileView: false }))
      
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const auth = useAuth();
  useEffect(() => {
    if (accountContext.currentAccount !== null) {
      auth.registerUID(accountContext.currentAccount);
    }
  }, [accountContext.currentAccount, auth])

  // Setting accounts context
  useEffect(() => {
    accountContext.checkWalletIsConnected();
    // accountContext.getOwners();
  }, []);
  
  // Check connected to firebase
  // useEffect(() => {
  //   startUpRecord()
  // }, [])

  const { ethereum } = window;
  if (ethereum) {
    window.ethereum.on("accountsChanged", accountContext.handleAccountsChanged);
    window.ethereum.on("chainChanged", accountContext.handleNetworkChanged);
  }
  return (
    <div style={{ backgroundColor: theme.palette.primary.main }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage mobileView={state} />} />
          {/* {(accountContext.mintActive || accountContext.presaleActive ) &&  */}
          { true &&
            <Route
              path="/mint"
              element={<MintPage mobileView={state} />}
            />
          }
          { true &&
            <Route
              path="/token"
              element={<TokenPage mobileView={state} />}
            />
          }
          { false && 
            <Route
              path="/vote"
              element={<VotingPage mobileView={state} />}
            />
          }
          { true && 
            <Route
              path="/raffle"
              element={<RafflePage mobileView={state} />}
            />
          }
          { true && (
            <Route
              path="/mytigers"
              element={<MyTigersPage mobileView={state} />}
            />
          )}
          { true &&
            <Route
              path="/shop"
              element={<ShopPage mobileView={state} />}
            />
          }
          { true &&
            <Route
              path="/junglerun"
              element={<JungleRunPage mobileView={state} />}
            />
          }
          { true &&
            <Route
              path="/link"
              element={<LinkAccountPage mobileView={state} />}
            />
          }
          { true &&
            <Route
              path="/gallery"
              element={<GalleryPage mobileView={state} />}
            />
          }
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
