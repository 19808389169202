import { useContext } from 'react';
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { AccountContext } from '../MetaMaskUtils';

const useStyles = narrowView => makeStyles((theme) => ({
    btnBg: {
        ...theme.palette.btnBackground,
        width: "80%",
        maxWidth: "80px",
        minHeight: `${narrowView ? '30px' : '35px'}`,
        maxHeight: `${narrowView ? '30px' : '35px'}`,
        borderRadius: "7px",
        // maxHeight: "25px",
        "&:disabled": {
            ...theme.palette.btnDisabledBackground,
        },
        fontSize: "0.7rem"
    }
}));

const MintButton = ({ narrowView, numMint, mintStatusHandler }) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const { btnBg } = useStyles(narrowView)(theme);

    // Wraps mintButtonHandler
    const mintButtonHandler = (num, statusHandler) => () => {
        accountContext.mintNftHandler(num, statusHandler);
    }

    const connectButton = (
        <Button 
            onClick = { accountContext.connectWalletHandler }
            className = {btnBg}
        >
            Connect
        </Button>   
    )
        
    const mintDisabled = () => {
        if (numMint <= 0) {
            return true;
        }
        if (!accountContext.isValid()) {
            return true;
        }
        if (!accountContext.mintActive && !accountContext.presaleActive) { // no sale active
            return true;
        }
        if (!accountContext.mintActive && accountContext.presaleActive) { // During presale
            if (accountContext.presaleAmount > 0) {// has wl allocated
                return false;
            }
            else {
                return true;
            }
        }
        // mint active
        return false;
    }
    const mintButton = (
        <Button 
            disabled={mintDisabled()} // add presale condition
            onClick={ mintButtonHandler(numMint, mintStatusHandler) }
            className = {btnBg}
        >
            Mint
        </Button>
    )

    return (
        <Grid container justifyContent="center" alignItems="center"
            sx={{width: "100%", height: "100%"}} >
            {accountContext.currentAccount ? mintButton : connectButton}
        </Grid>
    );
}

export default MintButton;