import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import { StyledFirebaseAuth } from "react-firebaseui";
import { auth } from '../../config/firebaseClient';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';

export const LoginUI = () => {

    /**
     * UI pop up for logging in
     */
     const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            // Display google auth provider
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // avoid redirect after sign-in
            signInSuccessWithAuthResult: () => false,
        }
    }

    return (
        <StyledFirebaseAuth uiConfig={uiConfig}
        firebaseAuth={auth} />
    )
}

export const LogoutButton = () => {
    const theme = useTheme();

    return (
        <Button sx={{
            ...theme.palette.btnBackground,
        }}
        onClick={() => {
            auth.signOut();
            console.log("Signed out");
            }}
        >
            Log out
        </Button>
    )
}