import { useContext, useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getTransaction, numFromGwei, AccountContext } from '../MetaMaskUtils';

import Typography from '@mui/material/Typography';

import { backgrounds, shopText } from './shopData';
import { useShop } from '../../context/ShopContext';

import ShopItem from './ShopItem'

const PIXEL_CONTRACT = "0x28f6691ACe7a960eD6B549A2c486982e89F963fc";
const BURN_ID = "0xaf61ee9c";

const useStyles = (narrowView, mobileView) => makeStyles(theme => ({
    outer: {
        backgroundImage: `url(${backgrounds.cover})`,
        backgroundPosition: 'center',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "4.4rem",
        backgroundColor: "#1F3037",
        height: "auto",
        // height: `${narrowView ? '200vh' : '100vh'}`,
        minHeight:`${narrowView ? "max(800px,172vw)" : "max(650px,55vw)"}`,
        // maxHeight:`${narrowView ? "160vw" : "75vw"}`, 
    },
    wrapper: {
        // width: "auto",
        position: "relative",
        height: "min(90vh, 75vw)",
        width: `${narrowView ? '100%' : '98%'}`,
    },
    tigerShop: {
        position: "absolute",
        bottom: `${narrowView ? "5%" : "7%"}`,
        left: `${narrowView ? "13vw" : "12vw"}`,
        zIndex: "2",
        width: `${narrowView ? "max(240px, min(50vw, 320px))" : "max(300px, min(33vw, 500px))"}`,
    },
    speech: {
        position: "absolute",
        bottom: `${narrowView ? "max(150px, min(33vw,200px))" : "max(200px, min(22vw,370px))"}`,
        left: `${narrowView ? "max(220px, min(50vw, 340px))" : "max(310px,min(34vw, 580px))"}`,
        zIndex: "2",
        width: `${narrowView ? "max(140px, min(200px, 30vw))" : "max(160px, min(17vw, 200px))"}`,
    },
    shopText: {
        position: "absolute",
        bottom: `${narrowView ? "calc(max(20px,4.5vw) + max(150px, min(33vw,200px)))" : "calc(min(40px,3vw) + max(200px, min(22vw,370px)))"}`,
        left: `${narrowView ? "calc(1.8vw + max(220px, min(50vw, 340px)))" : "calc(1.2vw + max(310px,min(34vw, 580px)))"}`,
        zIndex: "2",
        width: `${narrowView ? "calc(max(140px, min(200px, 30vw)) - 4vw)" : "calc(max(160px, min(17vw, 200px)) - 2.2vw)"}`,
        height: 'undefined',
        aspectRatio: `1.88`,
        // backgroundColor:"rgb(255,255,0,0.2)",

        fontSize: `${narrowView ? "max(0.8rem, min(2.5vw, 1rem))" : "max(0.85rem, min(1.35vw, 0.98rem))"}`,
        // lineHeight: "25px",
        fontFamily: theme.palette.gothamMedFont,
        display: 'flex',
        alignItems: "center"
    },
    modalWrapper: {
        height: "100%",
    },
    modalBackground: {
        height: "max(40vh, 40px)",
        backgroundColor: "#F6E9BC",
        borderRadius: "10px",
    }
}))

const Shop = ({mobileView}) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const shop = useShop();

    // for view
    const [ narrowView, setNarrowView ] = useState(false);
    useEffect(() => {   
        if (window.visualViewport.width < 760) {
            setNarrowView(true);
        }
        else {
            setNarrowView(false);
        }
    })  
    const classes = useStyles(narrowView, mobileView)(theme);

    /* Filter hidden */
    const filterHidden = (data) => {
        return data.filter(item => !item.hidden);
    }

    const [shopItems, setShopItems] = useState([]);
    useEffect(() => {
        let data = shop.shopItems;
        
        data = filterHidden(data); 
    
        // Sort latest date first
        data.sort((a, b) => {
          const dateA = a['startDate'];
          const dateB = b['startDate'];
          if (dateA < dateB) {
            return 1;
          } else if (dateA === dateB) {
            return 0;
          } else {
            return -1
          }
        });
    
        setShopItems(data);
      }, [shop.shopItems])

    const [showBuyConfirm, setShowBuyConfirm] = useState(false);
    // Handle target item for pop up modal purchase
    const [targetItem, setTargetItem] = useState({
        itemName: "",
        availableQty: 0,
        priceEach: 0,
        isRaffle: false,
    })
    const handleSelectBuy = (itemName, availableQty, priceEach, isRaffle) => {
        setTargetItem({
            itemName: itemName,
            availableQty: availableQty,
            priceEach: priceEach,
            isRaffle: isRaffle,
        })
        setShowBuyConfirm(true);
    }

    const discordIdRef = useRef();
    const quantityRef = useRef();
    /* Utils for parsing integer */
    const getNum = (numString) => {
        return parseInt(numString, 10);
    }
    const isNumeric = (numString) => {
        return /^\d+$/.test(numString);
    }
    const isQtyValid = (quantity, maxQuantity) => {
        if (!isNumeric(quantity)) { // invalid number
            alert("Invalid quantity");
            return false;
        }

        const qtyPurchase = getNum(quantity);
        if (qtyPurchase < 1) { // invalid purchase num
            alert("Invalid purchase number");
            return false;
        }


        if (maxQuantity >= 0 && qtyPurchase > maxQuantity) {
            alert(`You can only purchase ${maxQuantity} more`);
            return false;
        }


        return true;
    }
    // Handler to pass into button
    const [pending, setPending] = useState(false);
    const handlePurchase = async (itemName, itemQty, maxQty, priceEach, userDiscordId) => {
        if (userDiscordId === undefined || userDiscordId === null) {
            userDiscordId = "";
        }
        if (!accountContext.isValid()) {
            return;
        }
        if (pending) {
            return;
        }
        const validQty = isQtyValid(itemQty, maxQty);
        if (!validQty) {
            return;
        }

        setPending(true);

        const quantity = getNum(itemQty); 

        /* Init pending transaction */
        let pendingInitiated = false;
        try {
            await shop.initPurchasePending(itemName, accountContext.currentAccount, userDiscordId, quantity);
            pendingInitiated = true;
        } catch (e) {
            setPending(false);
            alert("Max transaction limit reached");
            return 
        }
    
        try {
            // Burn and get txn hash
            const totalCost = priceEach * quantity;
            let result = await accountContext.buyShopItemPixels(totalCost);
            
            /* Simulate success */
            // result = {
            //     'hash': "success2"
            // }
            /* Simulate timed out */
            // result = "not mined within 50 blocks";
            if (result.hash !== undefined ) {
                const txnHash = result.hash;
                try {
                    await shop.handleSuccessfulPurchase(itemName, accountContext.currentAccount, quantity, txnHash, userDiscordId);
                } catch (e) {
                    alert(`Error recording purchase: ${e}. Please submit a ticket with transaction hash`);
                }
                
                pendingInitiated = false;
                shop.updateUserPurchases();
                alert("Transaction completed!")
            } else {
                // error
                let status = "FAILED";
                if (result.includes("Transaction was not mined within 50 blocks")) {
                    status = "TIMEDOUT";
                }
                try {
                    await shop.handleFailedPurchase(itemName, accountContext.currentAccount, Date.now().toString(), userDiscordId, quantity, false, result, status);
                } catch (e) {
                    alert(`Error recording failed transaction: ${e}. Please assist by submitting a ticket with the screenshot`);
                }

                alert(`Error purchasing: ${result}`);
                // if necessary
                await shop.updateTimedOutPurchases();
            }
            
        } catch (e) {
            alert("error occured:", e.message);
        } finally {
            setPending(false);
            setShowBuyConfirm(false);
            
        }
    }

    
    const txnHashRef = useRef(); // txnHash input
    const [pendingTxnItem, setPendingTxnItem] = useState({ // item to reference in fn calls
        itemName: "",
        userPendingPurchase: false,
        userTimedOutPurchase: false,
        priceEach: 0,
    })
    const [showHandlePending, setShowHandlePending] = useState(false); // show modla
    const handleSelectPending = (itemName, userPendingPurchase, userTimedOutPurchase, priceEach) => {
        setPendingTxnItem({
            itemName: itemName,
            userPendingPurchase: userPendingPurchase, 
            userTimedOutPurchase: userTimedOutPurchase,
            priceEach: priceEach
        })
        setShowHandlePending(true);
    }
    
    // To self verify transaction hashes
    const handleDeleteTxn = async (txnHash) => {
        const del = await window.confirm("This action is irreversible. Delete the transaction?");
        
        if (!del) {
          return;
        }
        // Delete timed out txn
        if (pendingTxnItem.userTimedOutPurchase) {
            try {
                await shop.deleteUserTimedOut(pendingTxnItem.itemName, accountContext.currentAccount);
            } catch (e) {
                alert(`Error deleting timed out transaction ${e}`);
            }
        } else if (pendingTxnItem.userPendingPurchase) {
            // Delete pending txn
            try {
                await shop.deleteUserPending(pendingTxnItem.itemName, accountContext.currentAccount);
            } catch (e) {
                alert(`Error deleting pending transaction ${e}`);
            }
        }
    
        await shop.updateTimedOutPurchases();
        setShowHandlePending(false);
    }
    const handleVerifyTxn = async (txnHash) => {
        if (pending) return;
        if (accountContext.currentAccount === null) {
            alert("Please connect to your wallet");
            return;
        }

        setShowHandlePending(false);
        setPending(true);

        // invalid Txn
        const txn = await getTransaction(txnHash);
        if (txn === null)  {
            setPending(false);
            alert("Invalid transaction hash");
            return
        }

        // txn not sent by user
        const sender = txn.from;
        if (sender.toLowerCase() !== accountContext.currentAccount.toLowerCase()) {
            setPending(false);
            alert("Transaction not sent by this address");
            return
        }

        // transaction not with PixelERC20
        const contractAddr = txn.to;
        if (contractAddr.toLowerCase() !== PIXEL_CONTRACT.toLowerCase()) {
            setPending(false);
            alert("Transaction not with PIXEL contract");
            return;
        }

        // transactionExists
        const txnExists = await shop.transactionExists(txnHash);
        if (txnExists) {
            setPending(false);
            alert("Transaction already recorded for another purchase");
            return;
        }

        // invalid burn method
        const txnMethod = txn.data;
        const methodId = txnMethod.substring(0, 10);
        if (methodId.toLowerCase() !== BURN_ID.toLowerCase()) {
            setPending(false);
            alert("Not a burn transaction");
            return
        }

        // Get user's purchase 
        let userPurchase;
        if (pendingTxnItem.userTimedOutPurchase) {
            userPurchase = shop.getUserTimedOutPurchase(pendingTxnItem.itemName, accountContext.currentAccount);
        } else if (pendingTxnItem.userPendingPurchase) {
            userPurchase = shop.getUserPendingPurchase(pendingTxnItem.itemName, accountContext.currentAccount);
        } 

        // invalid burn amount
        const burnAmtHex = txnMethod.substring(12);
        const burnAmt = parseFloat(numFromGwei(burnAmtHex)) * 1e10;
        const totalPrice = userPurchase.quantity * pendingTxnItem.priceEach; // Burn amount should be = quantity * price
        if (burnAmt !== totalPrice) {
            setPending(false);
            alert("Invalid burn amount for item");
            return;
        }

        // Verify purchase
        let successful = false;
        // Delete timed out
        try {
            if (pendingTxnItem.userTimedOutPurchase) {
                successful = await shop.handleVerifyTimedOut(pendingTxnItem.itemName, accountContext.currentAccount, txnHash, userPurchase.discordId, userPurchase.quantity);
            }
            // Delete pending
            if (pendingTxnItem.userPendingPurchase) {
                successful = await shop.handleVerifyPending(pendingTxnItem.itemName, accountContext.currentAccount, txnHash, userPurchase.discordId, userPurchase.quantity);
            }
        } catch (e) {
            alert(`Error verifying transaction: ${e}. Please submit a ticket with a screenshot of the error`);
            setPending(false);
            return;
        }

        if (successful) {
            alert("Verification Successful!");
        }
        shop.updateUserPurchases();
        shop.updateTimedOutPurchases();

        setPending(false);
    }

    const ShopItems = () => {
        return shopItems.map((data, idx) => {
            return (
                <Grid container item xs={narrowView ? 12 : 6} justifyContent="center" alignItems="flex-start"
                    key={data['title']+idx}>
                    { data ? 
                        <ShopItem
                            mobileView={mobileView}
                            narrowView={narrowView}
                            shopEntry={data}
                            handleSelectBuy={handleSelectBuy}
                            handleSelectPending={handleSelectPending}
                            />   
                        :
                        <></>
                    }
                </Grid>
            )
        })
    }

    const handleClose = () => {
        setShowBuyConfirm(false)
    };
    const handleClosePending = () => {
        setShowHandlePending(false)
    };
    return (
        <Grid container justifyContent="flex-start" alignItems="center"
            className={classes.outer}>
            {/* To handle pending / timedout purchases */}
            <Modal 
                open={showHandlePending}
                onClose={handleClosePending}
            >
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    className={classes.modalWrapper}>
                    <Grid container item xs={10} md={5} lg={4} justifyContent="center" alignItems="center"
                        className={classes.modalBackground}>
                        <Grid container item xs={11} justifyContent="center" alignItems="center">
                            <Typography variant={mobileView ? "body1" : "h6"}>
                                {`You have a ${pendingTxnItem.userPendingPurchase ? "pending" : "timed out"} transaction for ${pendingTxnItem.itemName}`}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <TextField 
                                label="Txn Hash"
                                inputRef={txnHashRef} />
                        </Grid>
                        <Grid container item xs={12} justifyContent="space-evenly" alignItems="center">
                            <Button onClick={handleClosePending}
                                color="primary"
                                variant="outlined">
                                Cancel
                            </Button>
                            <Button 
                                onClick={(e) => {
                                    handleDeleteTxn(txnHashRef.current.value);
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                                color="warning"
                                variant="outlined"
                                >
                                Delete
                            </Button>
                            <Button 
                                onClick={(e) => {
                                    handleVerifyTxn(txnHashRef.current.value);
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                                variant="outlined" >
                                Verify
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>    
            </Modal>
            {/* For confirm purchase */}
            <Modal 
                open={showBuyConfirm}
                onClose={handleClose}
            >
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    className={classes.modalWrapper}>
                    <Grid container item xs={10} md={4} lg={3} justifyContent="center" alignItems="center"
                        className={classes.modalBackground}>
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <Typography variant={mobileView ? "body1" : "h6"}>
                                {`Purchasing ${targetItem.itemName}`}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                            <TextField 
                                label="Discord ID"
                                inputRef={discordIdRef} />
                        </Grid>
                        { targetItem.isRaffle &&
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <TextField 
                                    label="Quantity"
                                    inputRef={quantityRef} />
                            </Grid>
                        }
                        <Grid container item xs={12} justifyContent="space-evenly" alignItems="center">
                            <Button onClick={handleClose}
                                color="warning"
                                variant="outlined">
                                Cancel
                            </Button>
                            <Button onClick={() => 
                                handlePurchase(targetItem.itemName, targetItem.isRaffle ? quantityRef.current.value : 1, targetItem.availableQty, targetItem.priceEach, discordIdRef.current.value)} 
                                variant="outlined" >
                                Confirm Purchase
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>    
            </Modal>
            
            <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start"
                sx={{
                    marginTop: "-2rem"
                }}>
                <ShopItems />
            </Grid>
            <Grid container item xs={12} mt={7} justifyContent="center" alignItems="center">
                <Grid container item xs={12} justifyContent="center" alignItems="flex-start" 
                    className={classes.wrapper}>
                    <img src={backgrounds.tigerShop} alt="Tiger Shop"
                        className={classes.tigerShop}/>
                    <img src={backgrounds.speech} alt="Tiger Shop"
                        className={classes.speech}/>
                    <Typography variant="body1" className={classes.shopText}
                        align="center">
                        {shopText}
                    </Typography>
                </Grid>
            </Grid>
            {/* <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                <Button sx={{
                        backgroundColor:'white',
                        color: 'black'
                    }}
                    onClick={accountContext.takeSnapshot}
                >Snapshot</Button>
            </Grid> */}
        </Grid>
    )
}

export default Shop;