import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { firebaseConfig } from './firebase.config';

export const app = initializeApp(firebaseConfig);

// get reference to database
export const db = getFirestore(app);

// google auth
export const auth = getAuth(app);

/**
 * Sign in user on start up
 */
signInAnonymously(auth)
    .then(() => {
        console.log("Set up success.");
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Error code:", errorCode, "Message:", errorMessage);
})