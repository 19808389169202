import { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import { AccountContext } from '../MetaMaskUtils';
import { backgrounds } from './pixelData.js';
import AppearInView from '../Utils/AppearInView';

const useStyles = (mobileView, narrowView) => makeStyles(theme => ({
        outer: {
            backgroundImage: `url(${backgrounds.cover})`,
            backgroundPosition: 'center',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: "4.4rem",
            backgroundColor: "#1F3037",
            height:"100vh",
            minHeight:`${mobileView ? "200px" : "500px"}`,
            maxHeight:`${mobileView ? "500px" : "1000px"}`, 
        },
        header: {
            // paddingTop: `${mobileView ? '2rem' : '3rem'}`,
            // paddingBottom: "1rem",
            color: "white",
            fontSize: `${mobileView ? '2.3rem' : '3.3rem'}`
        },
        pixelDesc: {
            fontSize: `${narrowView ? "max(0.7rem,min(1rem,2vw))" : "max(0.85rem,min(0.9rem,1.2vw))"}`,
            color: theme.palette.offWhite,
            fontFamily: theme.palette.gothamMedFont,
            zIndex: "3",
        },
        mainSection: {
            position: "absolute",
            top: "13%",
            zIndex: "3",
            paddingTop: "max(12px,min(20px,0.8vw))",
            width: '80%',
            height: 'undefined',
            aspectRatio: `${narrowView ? '2' : '2.44'}`,
        },
        balance: {
            color: theme.palette.highlight,
            width: '90%',
            // backgroundColor:'yellow',
            "& .MuiInput-input":{ // style of balance num overall
                padding: "0px 7px",
                fontSize: "max(0.8rem,1vw)",
                fontFamily: theme.palette.gothamMedFont,
            },
            "& :disabled": { // style of balance num
                "-webkit-text-fill-color": theme.palette.offWhite
            }
        },
        balanceAdornment: {
            "& .MuiTypography-root": { // style of $PIXELS in balance
                fontFamily: theme.palette.blockyFont,
                fontSize: "max(0.8rem,1vw)",
                "-webkit-text-fill-color": 'white',
                paddingRight: '5px'
            },   
        },
        contractBtn: {
            textDecoration: 'none',
            fontSize: "max(0.7rem,0.9vw)",
            color: theme.palette.offWhite,
            fontFamily: theme.palette.gothamMedFont,
            textTransform: 'none',
            // backgroundColor:'white',
            "&:hover": {
                // borderBottom: `1px solid ${theme.palette.highlight}`
            }
        },
        claimBtn: {
            ...theme.palette.btnBackground,
            fontSize: `${mobileView ? "0.6rem" : "max(0.6rem,min(0.8rem,0.95vw))"}`,
            width:'100%',
            minHeight: `${mobileView ? "25px" : "36px"}`,
            maxHeight: `${mobileView ? "25px" : "36px"}`,
            border: `max(1px,min(2px,0.2vw)) solid ${theme.palette.highlight} !important`,
            "&:disabled": {
                ...theme.palette.btnDisabledBackground
            },
            "&:hover": {
                backgroundColor:'black',
            }
        },
        claimableOutline: {
            position:'absolute',
            width: "90%",
            height: `${mobileView ? "23px" : "61.56%"}`,
            border: `max(1px,min(2px,0.2vw)) solid white`,
            borderRadius: '5px',
        },
        wrapper: {
            // width: "auto",
            position: "relative",
        },
        topLeftBg: {
            position: "absolute",
            top: `${mobileView ? 'calc(13% + -7vw)' : "calc(13% + min(-35px,-4.05vw))"}`,
            left: '11%',
            zIndex: "1",
            width: "25%"
        },
        topRightBg: {
            position: "absolute",
            top: `${mobileView ? 'calc(13% + -6.5vw)' : "calc(13% + min(-32px,-3.8vw))"}`,
            right: '11%',
            zIndex: "1",
            width: "25%"
        },
        centreBox: {
            position: "absolute",
            top: "13%",
            zIndex: "2",
            width: `${mobileView ? '90%' : '80%'}`,
            // minWidth: `${mobileView ? '200px' : '380px'}`
        },
    })
)

const Pixel = ({ mobileView }) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    
    // for view
    const [ narrowView, setNarrowView ] = useState(false);
    useEffect(() => {   
        if (window.visualViewport.width < 650) {
            setNarrowView(true);
        }
        else {
            setNarrowView(false);
        }
    })  

    const classes =  useStyles(mobileView, narrowView)(theme);

    /* Current Balance */
    const [ curClaimable, setCurClaimable ] = useState(null);
    useEffect(() => {
        let isMounted=true;

        const updatePixBalance = async () => {
            var claimBal = await accountContext.getTotalTokensClaimable();
            claimBal = parseFloat(claimBal).toFixed(2);
            if (isMounted) {
                setCurClaimable(claimBal);
            }
        };
        updatePixBalance();
        
        return () => {isMounted=false};
    }, [accountContext])


    /* Claim button */
    const [claimStatus, setClaimStatus] = useState("IDLE");
    // wrapper to pass to claim pixel
    const updateClaimStatus = ((status) => {
        setClaimStatus(status);
    })
    const GetClaimLabel = () => {
        var claimMsg = "";
        if (claimStatus === "FAILED") {
            claimMsg = "Claim Failed";
        }
        if (claimStatus === "CLAIMING") {
            claimMsg = "Claiming in progress ...";        
        }
        if (claimStatus === "CLAIMED") {
            claimMsg = "Successfully Claimed!"
        }
        if (claimMsg !== "") {
            return (
                <Typography variant="body1" 
                    sx={{
                        fontSize: "max(0.8rem,1vw)",
                        fontFamily: theme.palette.gothamMedFont,
                        color: theme.palette.highlight,
                    }}>
                    {claimMsg}
                </Typography>
            )
        }
        return <div></div>
    }

    const connectButton = (
        <Box sx={{ m:1, position:'relative', width:'100%'}}>
            <Button 
                {...{
                    color: "inherit",
                }}
                onClick = { accountContext.connectWalletHandler }
                className={classes.claimBtn}
            >
                Connect
            </Button>   
        </Box>
    )
    const claimButton = (
        <Box sx={{ m:1, position:'relative', width:'100%'}}>
            <Button 
                {...{
                    key: "mint",
                    // variant: "outlined",
                }}
                className={classes.claimBtn}
                disabled={!accountContext.isValid()}
                onClick={ () => {
                        setClaimStatus("IDLE");
                        accountContext.claimPixel(updateClaimStatus);
                    }
                }
            >
                Claim
            </Button>
            { (claimStatus==="CLAIMING") && (
                <CircularProgress
                size={20}
                sx={{
                    color: theme.palette.offWhite,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }} />
            )}
        </Box>
    )

    return (
        <div className={classes.outer}>
            <Grid container direction="row" justifyContent="center" alignItems="center"
                sx={{height: '100%'}}>
                {/* Title */} 
                <Grid container item xs={12} justifyContent="center" alignItems="flex-end"
                    sx={{height: '15%'}}>
                    <Typography variant="h2" className={classes.header}>
                        $PIXEL
                    </Typography>
                </Grid>  
                <Grid container item xs={12} justifyContent="center" alignItems="flex-start"
                    sx={{height: '25%'}}>
                    <Grid container item xs={12} justifyContent="center" alignItems="center"
                        sx={{height: "100%"}}>
                        <AppearInView variant="fade">
                            <Grid container item xs={mobileView ? 11 : 9} md={6.7} lg={5.5} xl={5} justifyContent="center" alignItems="center">
                                <Typography variant="body1"
                                    align="justify"
                                    className={classes.pixelDesc}>
                                    A mystical energy, pixels act as the only resource in The Jungle. PixelTigers discovered 
                                    that the coveted pixels are needed to give life to PixelCubs, or to test their luck at the Raffles. 
                                    Could there be more secrets behind the existence of pixels? What else can this mysterious energy 
                                    unlock in this realm? More will be revealed as PixelTigers explore deeper into The Jungle….
                                </Typography>
                            </Grid>
                        </AppearInView>
                    </Grid>
                </Grid>  
                {/* Wrapper for balance and claimable amount */}
                <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{height:"70%"}}>
                    <Grid container item xs={12} md={9} lg={7} justifyContent="center" alignItems="center" className={classes.wrapper}
                        sx={{height:"100%"}}>
                        <img src={backgrounds.topLeft} alt="topleftbg" className={classes.topLeftBg} />
                        <img src={backgrounds.topRight} alt="toprightbg" className={classes.topRightBg} />
                        <img src={narrowView ? backgrounds.mainTall : backgrounds.main} alt="centrebox" className={classes.centreBox} />
                        <Grid container item xs={12} justifyContent="center" alignItems="space-evenly"
                            className={classes.mainSection}>
                            <Grid container item justifyContent="center" alignItems="center">
                                <Grid container item xs={10} md={8} justifyContent="center" alignItems="center">
                                    {/* Show balance */}
                                    <Grid container item xs={12} justifyContent="center" alignItems="center"
                                        >
                                        <Grid container item justifyContent="center" alignItems="center">
                                            {/* Current balance */}
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <AppearInView variant="fade">
                                                    <Typography variant="body1" 
                                                        sx={{
                                                            fontSize: "max(0.9rem,1.1vw)",
                                                            fontFamily: theme.palette.gothamMedFont,
                                                            color: theme.palette.offWhite,
                                                        }}>
                                                        Current Balance:
                                                    </Typography>
                                                </AppearInView>
                                            </Grid>
                                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                                <AppearInView variant="fade">
                                                    <Typography
                                                        sx={{
                                                            fontSize: "max(1.2rem,1.4vw)",
                                                            fontFamily: theme.palette.blockyFont,
                                                            color: theme.palette.highlight,
                                                            paddingBottom: "max(0px,min(20px,2vw))"
                                                        }}>
                                                        {accountContext.curPixelBalance ? `${Number(parseFloat(accountContext.curPixelBalance).toFixed(2)).toLocaleString()} $PIXELS` : '???'}
                                                    </Typography>
                                                </AppearInView>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* Claimable amount */}
                                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                                            <AppearInView variant="fade">
                                                <Typography variant="body1"
                                                    sx={{
                                                        fontSize: "max(0.9rem,1.1vw)",
                                                        fontFamily: theme.palette.gothamMedFont,
                                                        color: theme.palette.offWhite,
                                                    }}>
                                                    {`Claimable Amount`}
                                                </Typography>
                                            </AppearInView>
                                        </Grid>
                                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                                            <AppearInView variant="fade">
                                                <Grid container item xs={9} justifyContent="center" alignItems="center"
                                                    sx={{position:'relative'}}>
                                                    <div className={classes.claimableOutline}></div>
                                                    <Grid container item xs={9} justifyContent="flex-end" alignItems="center">
                                                        <TextField
                                                            variant="standard"
                                                            value={curClaimable ? Number(curClaimable).toLocaleString() : 'Fetching'}
                                                            InputProps={{
                                                                endAdornment: 
                                                                    <InputAdornment position="end" 
                                                                        className={classes.balanceAdornment}
                                                                        >
                                                                        $PIXELS
                                                                    </InputAdornment>,
                                                            }}
                                                            className={classes.balance}
                                                            disabled
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    <Grid container item xs={3} justifyContent="center" alignItems="center">
                                                        {accountContext.currentAccount ? claimButton : connectButton}    
                                                    </Grid>
                                                </Grid>
                                            </AppearInView> 
                                        </Grid>
                                        <Grid container item xs={12} justifyContent="center" alignItems="center">
                                            <GetClaimLabel />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Contact addr title */}
                                <Grid container item xs={12} justifyContent="center" alignItems="center">                            
                                    <AppearInView variant="fade">
                                        {/* Address */}
                                        <Link
                                            sx={{
                                                textDecoration: 'none',
                                            }}
                                            href={`https://etherscan.io/address/${accountContext.contractAddressERC20}`}
                                            target="_blank">
                                            <Button 
                                                // variant="outlined"
                                                className={classes.contractBtn}
                                            >
                                                {/* {`${accountContext.contractAddressERC20.substring(0,5)}...${accountContext.contractAddressERC20.substring(accountContext.contractAddressERC20.length-4,accountContext.contractAddressERC20.length)}`} */}
                                                {`Contract address: ${(narrowView || (claimStatus !== "IDLE")) ? `${accountContext.contractAddressERC20.substring(0,5)}...${accountContext.contractAddressERC20.substring(accountContext.contractAddressERC20.length-4,accountContext.contractAddressERC20.length)}` : accountContext.contractAddressERC20}`}
                                            </Button>
                                        </Link>
                                    </AppearInView>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Pixel;