export const socialsData = [
  {
    src: "/assets/images/socials/discord.svg",
    alt: "discord",
    href: "https://discord.com/invite/B7HUaff6rQ",
  },
  {
    src: "/assets/images/socials/twitter.svg",
    alt: "twitter",
    href: "https://twitter.com/PixelTigers/",
  },
  {
    src: "/assets/images/socials/opensea.svg",
    alt: "opensea",
    href: "https://opensea.io/collection/pixeltigers-official",
  },
];

// TODO: Update text for desc
export const desc = "Swimming in the jungle";

export const copyright = "© PixelTigers ALL RIGHTS RESERVED";
