import { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const useStyles = () => makeStyles(theme => ({
        title: {
            backgroundColor: theme.palette.primary.light,
            fontSize: "1.6rem",
            fontFamily: theme.palette.blockyFont
        },
        content: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.offWhite
        },
        listitem: {
            fontFamily: theme.palette.gothamMedFont,
            fontSize: "max(0.8rem,min(1rem,2vw))",
            color: "black",
        },
        bottom: {
            backgroundColor: theme.palette.primary.light,
        },
        closeBtn: {
            fontSize: "1.2rem",
            padding: "0 0",
            color: "black",
            fontFamily: theme.palette.blockyFont
        }
    })
)


const RaffleParticipantsDialog = ({ mobileView, showParticipants, participants, handleShowParticipants }) => {
    const theme = useTheme();
    const classes = useStyles()(theme);

    /* Raffle participant details */
    const participantElementRef = useRef(null);
    useEffect(() => {
        if (showParticipants) {
            const { current: participantElement } = participantElementRef;
            if ( participantElement !== null) {
                participantElement.focus();
            }
        }
    }, [showParticipants])
    
    const renderParticipant = ({index, style}) => {
        let addr = participants[index].addr
        if (mobileView) {
            addr = `${addr.substring(0,10)}...${addr.substring(addr.length-10,addr.length)}`
        }
        const count = participants[index].count
        
        return (
            <ListItem key={addr+count} style={style} component="div" disablePadding>
                <ListItemText disableTypography
                    primary={
                        <Typography type="body1" className={classes.listitem}>
                            {`${addr} ----- ${count}`}
                        </Typography>
                    }/>
            </ListItem>
        )
    }
    return (
        <div>
            <Dialog
                open={showParticipants}
                onClose={handleShowParticipants}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    sx: {
                        width: "90%",
                        height: "60%",
                    }
                }}
                >
                <DialogTitle id="scroll-dialog-title"
                    className={classes.title}>Entries</DialogTitle>
                <DialogContent dividers={true}
                    className={classes.content}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                height={height}
                                width={width}
                                itemSize={30}
                                itemCount={participants.length}
                                overscanCount={5}
                            >
                                {renderParticipant}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </DialogContent>
                <DialogActions className={classes.bottom}>
                    <Button onClick={handleShowParticipants}
                        className={classes.closeBtn}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RaffleParticipantsDialog;