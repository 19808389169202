import Header from './HeaderComponent/Header';
import Main from './MainComponent/Main';
import Roadmap from "./RoadmapComponent/Roadmap";
import FAQ from "./FAQ";
import Team from './TeamComponent/Team';
import Footer from './FooterComponent/Footer';
import Carousel from './CarouselComponent/Carousel';

function HomePage({ mobileView }) {
    return (
        <div>
            {/* add anchor tags */}
            <Header mobileView={mobileView.headerMobileView}/>
            <div id="main">
                <Main/>
            </div>
            <div id="intro">
                <Carousel mobileView={mobileView.headerMobileView} />
            </div>
            <div id="phase">
                <Roadmap mobileView={mobileView.headerMobileView} />
            </div>
            <Team/>
            <div id="faqs">
                <FAQ mobileView={mobileView.headerMobileView} />
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;