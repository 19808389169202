import { createContext, useContext, useEffect, useState } from 'react';
import { ShopFn } from '../utils/firebaseUtils/shopUtils';

const ShopContext = createContext();

export const useShop = () => useContext(ShopContext);

export const ShopContextProvider = ({ children, userAddress }) => {
    
    /**
     * utils to get signed in user's purchases
     */
    const [ userPurchases, setUserPurchases ] = useState(null); 
    // Update when address is obtained
    useEffect(() => { 
        updateUserPurchases();
    }, [userAddress]);
    const updateUserPurchases = async () => {
        if (userAddress === null || userAddress === undefined) { // do nothing if already updated
            setUserPurchases(null);
            return;
        }

        // get data
        const purchases = await ShopFn.getUserPurchases(userAddress);
        let userPurchaseData = {};
        for (const purchase of purchases) {
            const quantity = parseInt(purchase['quantity'], 10);
            const itemName = purchase['itemName'];
            const success = purchase['success'];
            // Add in if not inside
            if (!(itemName in userPurchaseData)) {
                userPurchaseData[itemName] = 0;
            }
            if (success) {
                userPurchaseData[itemName] = userPurchaseData[itemName] + quantity;
            }
        }
        
        setUserPurchases(userPurchaseData);
    }
    const getUserPurchased = (itemName) => {
        if (userAddress === null || userPurchases === null) {
            return null;
        }
        if (!(itemName in userPurchases)) {
            return 0;
        }
        return userPurchases[itemName];
    }

    /**
     * Utils to get total purchases for each item (listen for total purchase val)
     */
    const [ totalPurchases, setTotalPurchases ] = useState(null);
    useEffect(() => {
        const callback = (shopItems) => {
            let total = {};
            for (const item of shopItems) {
                const itemName = item['title'];
                const totalPurchased = item['totalPurchased'];
                const totalPending = item['pendingPurchase'] !== undefined ? item['pendingPurchase'] : 0;
                const totalTimedOut = item['timedOut'] !== undefined ? item['timedOut'] : 0;
                total[itemName] = {
                    totalPurchased: totalPurchased,
                    totalPending: totalPending,
                    totalTimedOut: totalTimedOut
                };
            } 
            
            setTotalPurchases(total);
        }
        const unsubscribeShop = ShopFn.listenerShopTotalPurchases(callback);

        return () => unsubscribeShop;
    }, [])

    /**
     * Utils to get failed purchases for each item (listen for total purchase val)
     */
    const [ timedOutPurchases, setTimedOutPurchases ] = useState(null);
    useEffect(() => {
        updateTimedOutPurchases();
    }, [])
    const updateTimedOutPurchases = () => {
        ShopFn.getFailedPurchases()
            .then((data) => {
                let timedOut = {};
                data = data.filter((x) => {
                    if (!('error' in x)) {
                        return false;
                    }
                    return x.error.includes("not mined within 50 blocks");
                })
                data = data.filter((x) => {
                    if (!('status' in x)) {
                        return false;
                    }
                    return x.status !== "HANDLED";
                })
                for (const txn of data) {
                    const itemName = txn['itemName'];

                    // Add failedTxn
                    if (!(itemName in timedOut)) {
                        timedOut[itemName] = {};
                    }
                    timedOut[itemName][txn.address] = txn;
                }
                setTimedOutPurchases(timedOut);
            });
    }
    const getTimedOutPurchaseCount = (itemName) => {
        if (timedOutPurchases === null) {
            return 0;
        }
        return (itemName in timedOutPurchases) ? Object.keys(timedOutPurchases[itemName]).length : 0;
    }
    const userHasTimedOutPurchase = (itemName, address) => {
        if (timedOutPurchases === null || address === null) {
            return false;
        }
        if (!(itemName in timedOutPurchases)) {
            return false;
        }
        if (!(address in timedOutPurchases[itemName])) {
            return false;
        }
        return true;
    }
    const getUserTimedOutPurchase = (itemName, address) => {
        if (timedOutPurchases === null || address === null) {
            return null;
        }
        if (!(itemName in timedOutPurchases)) {
            return null;
        }
        if (!(address in timedOutPurchases[itemName])) {
            return null;
        }
        return timedOutPurchases[itemName][address];
    }

    /**
     * Utils to get pending purchases for each item (listen for all purchases)
     */
    const [ itemPendingPurchases, setItemPendingPurchases ] = useState(null);
    useEffect(() => {
        const callback = (pendingPurchases) => {
            let itemPending = {}
            for (const purchase of pendingPurchases) {
                const quantity = parseInt(purchase['quantity'], 10);
                const itemName = purchase['itemName'];
                
                // Add item pending 
                if (!(itemName in itemPending)) {
                    itemPending[itemName] = {}
                }
                itemPending[itemName][purchase.address] = purchase;
            } 
            setItemPendingPurchases(itemPending);
        }
        const unsubscribeShop = ShopFn.listenerShopPendingPurchases(callback);

        return () => unsubscribeShop;
    }, [])
    const getPendingPurchaseCount = (itemName) => {
        if (itemPendingPurchases === null) {
            return 0;
        }
        return (itemName in itemPendingPurchases) ? Object.keys(itemPendingPurchases[itemName]).length : 0;
    }
    const userHasPurchasePending = (itemName, address) => {
        if (itemPendingPurchases === null || address === null) {
            return false;
        }
        if (!(itemName in itemPendingPurchases)) {
            return false;
        }
        if (!(address in itemPendingPurchases[itemName])) {
            return false;
        }
        return true;
    }
    const getUserPendingPurchase = (itemName, address) => {
        if (itemPendingPurchases === null || address === null) {
            return null;
        }
        if (!(itemName in itemPendingPurchases)) {
            return null;
        }
        if (!(address in itemPendingPurchases[itemName])) {
            return null;
        }
        return itemPendingPurchases[itemName][address];
    }
    
    /**
     * utils to get items available in shop
     */
    const [ shopItems, setShopItems ] = useState([]);
    useEffect(() => {
        /** 
         * Updates shopItems with snapshot data
         */
        const callback = async (items) => {
            items = items.map((item) => {
                const userPurchased = getUserPurchased(item.title);
        
                const title = item['title'];
                const type = item['type'];
                const startDate = new Date(item['startDate'].toDate());
                const endDate = new Date(item['endDate'].toDate());
                const priceWithTiger = parseInt(item['priceWithTiger'], 10);
                const priceWithoutTiger = parseInt(item['priceWithoutTiger'], 10);
                const desc = item['desc'];
                const imgUrl = item['imgUrl'];
                const maxEntriesPerUser = parseInt(item['maxEntriesPerUser'], 10);
                const maxEntries = parseInt(item['maxEntries']);
                const hidden = item['hidden'];
        
                /* Get number of items purchased 
                if null == not yet fetched. Set default to be maxEntries
                */
                let purchaseCount = (totalPurchases === null) ? maxEntries : 0;
                if ((totalPurchases !== null) && item.title in totalPurchases) {
                    const purchaseData = totalPurchases[item.title];
                    purchaseCount = purchaseData.totalPurchased + purchaseData.totalTimedOut + purchaseData.totalPending;
                }
                
                let userPendingPurchase = userHasPurchasePending(title, userAddress); 
                let userTimedOutPurchase = userHasTimedOutPurchase(title, userAddress);

                return {
                    title,
                    type,
                    startDate,
                    endDate,
                    priceWithTiger,
                    priceWithoutTiger,
                    desc,
                    imgUrl,
                    maxEntriesPerUser,
                    maxEntries,
                    userPurchased,
                    purchaseCount,
                    hidden,
                    userPendingPurchase,
                    userTimedOutPurchase,
                }
            });
            setShopItems(items);
        };
        const unsubscribeShop = ShopFn.listenerShopItems(callback);

        return () => unsubscribeShop();
    }, [userAddress, totalPurchases, userPurchases, itemPendingPurchases, timedOutPurchases]);
    
    const transactionExists = ShopFn.transactionExists;
    const deleteUserTimedOut = async (itemName, address) => {
        const quantity = timedOutPurchases[itemName][address].quantity;
        try {
            await ShopFn.handleDeleteTimedOut(itemName, address, quantity);
        } catch (e) {
            alert(`Error deleting pending purchase ${e}`)
        }
    }
    // use when we need to get the pending purchase first
    const deleteUserPending = async (itemName, address) => {
        const pendingTxn = getUserPendingPurchase(itemName, address);
        if (pendingTxn !== null) {
            await ShopFn.handleDeletePending(itemName, address, pendingTxn.discordId, pendingTxn.quantity);
        }
    }

    // Batch functions
    const handleFailedPurchase = ShopFn.handleFailedPurchase;
    const initPurchasePending = ShopFn.handleInitPurchasePending;
    const handleSuccessfulPurchase = ShopFn.handleSuccessfulPurchase;
    const handleVerifyPending = ShopFn.handleVerifyPending;
    const handleVerifyTimedOut = ShopFn.handleVerifyTimedOut;


    return (
        <ShopContext.Provider
            value={{
                shopItems,
                totalPurchases,
                getUserPurchased,
                userPurchases,
                updateUserPurchases,
                initPurchasePending,
                deleteUserTimedOut,
                getUserTimedOutPurchase,
                getUserPendingPurchase,
                deleteUserPending,
                transactionExists,
                updateTimedOutPurchases,
                handleFailedPurchase,
                handleSuccessfulPurchase,
                handleVerifyPending,
                handleVerifyTimedOut
            }}
        >
            {children}
        </ShopContext.Provider>
    )
}