import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Waypoint } from 'react-waypoint';
import Grid from '@mui/material/Grid';

import './transitionStyles.css';

const AppearInView = ({children, variant, offset}) => {
    const [ showElem, setShowElem ] = useState(false);
    const [ enterFromBottom, setEnterFromBottom ] = useState(false);
    
    return (
        <Waypoint
            onLeave={() => {
                setShowElem(false);
            }}>
            <Grid container item sx={{height: "100%", width: "100%"}} justifyContent="center" alignItems="center">
                <Waypoint
                    onEnter={( {previousPosition, currentPosition }) => {
                        if ((previousPosition === "above") && (currentPosition === "inside")) {
                            setEnterFromBottom(false);
                        }
                        else{ 
                            setEnterFromBottom(true);
                        }
                        
                        setShowElem(true);
                    }}
                    topOffset={offset}
                    bottomOffset={offset}
                    >
                    <Grid container item justifyContent="center" alignItems="center"
                        sx={{
                            height: "100%", 
                            width: "100%",
                            opacity: `${showElem ? '100' : '0'}`
                        }}>
                        <CSSTransition
                            in={showElem}
                            timeout={1000}
                            classNames={`${variant}-${enterFromBottom ? 'btm' : 'top'}`}
                            >
                            {children}
                        </CSSTransition>
                    </Grid>
                </Waypoint>
            </Grid>
        </Waypoint>
    )
}

export default AppearInView;