import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { backgroundImage, logoImage } from './mainData';

const useStyles = makeStyles(theme => ({
    outer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        // marginTop: "4px",
    }, 
    backgroundGradient: {
        height: "100%",
        width: "100%",
        background:`linear-gradient(180deg, rgb(0,0,0,0), 85%, ${theme.palette.primary.main})`
    },
    title: {
        width: "max(350px,60vw)",
        // minWidth: "320px",
        maxWidth: "800px",
        marginTop: "-10vh",
    },
    mintButton: {
        ...theme.palette.btnBackground,
        // marginTop: "5rem",
        marginBottom: "16rem",
        height: "40px",
        width: "max(150px,10vw)",
        borderRadius: "7px",
        '&:hover': {
            backgroundColor: "black",
        }
    }
}))

const Main = () => {
    const theme = useTheme();
    const { outer, title, backgroundGradient } =  useStyles(theme);    
    
    return (
        <div>
            <Toolbar> </Toolbar>
            <Grid container className={outer}>
                <Grid container item className={backgroundGradient} justifyContent="center" alignItems="center">
                    <Grid container item justifyContent="center" alignItems="center">
                        <img src={logoImage} alt="Pixel Tigers" className={title} style={{paddingLeft: "5px"}}></img>
                    </Grid>
                    {/* <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center">
                        <Button 
                            {...{
                                key: mainButton.label,
                                color: "inherit",
                            }}
                            className={mintButton}
                        >
                            <a href={mainButton.href} 
                                style={{
                                    textDecoration: "none",
                                    color: theme.palette.highlight,
                                    // '& :visited': {
                                    //     color: theme.palette.offWhite,
                                    // }
                                }}>
                                {mainButton.label}
                            </a>
                        </Button>   
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    )
}

export default Main;