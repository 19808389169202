import { useContext, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { AccountContext } from '../MetaMaskUtils';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { backgrounds } from './shopData';

const useStyles = (narrowView, mobileView) => makeStyles(theme => ({
    shopItemOneBg: {
        backgroundImage: `url(${backgrounds.shopItem})`,
        backgroundPosition: 'center',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        zIndex: "1",
        height: `${narrowView ? "40vw" : "min(27vw, 20rem)"}`,
    },
    shopItemWrapper: {
        width:`${narrowView ? "52vw" : "min(35vw, 27rem)"}`,
        zIndex: "2",
        position:"relative",
        height: 'undefined',
        aspectRatio: `2.345`,
    },
    type: {
        position: 'absolute',
        marginTop: "-20%",
        fontFamily: theme.palette.gothamMedFont,
        fontWeight: "bold",
        color: theme.palette.highlight,
        fontSize: "14px",
        backgroundColor: alpha(theme.palette.primary.dark, 0.3),
        padding: "10px",
        borderRadius: "10px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "10px",
            padding: "6px",
            borderRadius: "6px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "7px",
            marginTop: "-22%",
        },
        
    },
    countDown: {
        position: 'absolute',
        marginTop: "-50%",
        fontFamily: theme.palette.gothamMedFont,
        color: theme.palette.offWhite,
        fontSize: "14px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "9px",
        },
    },
    fade: {
        opacity: '0.7',
    },
    shopItemName: {
        color: theme.palette.offWhite,
        fontFamily: theme.palette.blockyFont,
        fontSize: `${narrowView ? 'min(3vw, 2rem)': 'min(1.8vw, 1.8rem)'}`,
        textShadow: '0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black',
    },
    priceText: {
        color: theme.palette.offWhite,
        fontFamily: theme.palette.gothamMedFont,
        fontWeight: "bold",
        fontSize: "24px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "20px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "18px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "12px",
        },
    },
    currency: {
        color: theme.palette.offWhite,
        fontFamily: theme.palette.gothamMedFont,
        fontWeight: "bold",
        fontSize: "18px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "16px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "10px",
        },
    },
    helperText: {
        color: theme.palette.highlight,
        fontFamily: theme.palette.gothamMedFont,
        fontWeight: "bold",
        textShadow: '0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black, 0px 0px 5px black',
        fontSize: "14px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "12px",
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "10px",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "7px",
        },
    },
    purchaseBtn: {
        position: 'absolute',
        bottom: `${narrowView ? '-13%' : '-11%'}`,
        width: "30%",
        fontSize: `${narrowView ? "min(1.8vw,1rem)" : "min(1.2vw,1rem)"}`,
        // textTransform:"none",
        ...theme.palette.btnBackground,
        borderRadius: "7px",
        '&:disabled': {
            ...theme.palette.btnDisabledBackground,
            backgroundColor: theme.palette.primary.light
        },
        '&:hover': {
            ...theme.palette.btnBackground,
            fontWeight: 'bolder',
        }
    },
}))

const ShopItem = ({mobileView, narrowView, shopEntry,
    handleSelectBuy, handleSelectPending}) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const classes = useStyles(narrowView, mobileView)(theme);

    /* UI Utils */
    const getHelperText = () => {
        // Display info
        let helperText = "";
        /* Ended */
        if (Date.now() > shopEntry.endDate) {
            helperText = "Sold out";
        } else if (Date.now() < shopEntry.startDate) {
            helperText = "Coming soon";
        } else {
            if (shopEntry.maxEntries >= 0 && shopEntry.purchaseCount >= shopEntry.maxEntries) { // has limit on total
                helperText = "Sold out";   
            } else if (shopEntry.userPurchased > 0) {
                helperText = "Purchased";        
            }
        }
    
        return helperText;
    }
    const isRaffle = () => {
        return shopEntry['type'] === "RAFFLE";
    }
    const getUserAvailableQty = () => {
        let availableQty;
        
        const userAvail = shopEntry.maxEntriesPerUser >= 0 ? shopEntry.maxEntriesPerUser - shopEntry.userPurchased : -1;
        const totalAvail = shopEntry.maxEntries >= 0 ? shopEntry.maxEntries - shopEntry.purchaseCount : -1;
        if (userAvail === -1 && totalAvail === -1) {
            availableQty = -1; // no max per user, no max total
        } else if (userAvail >= 0 && totalAvail === -1) {
            availableQty = userAvail; // no max total but has max per user
        } else if (userAvail === -1 && totalAvail >= 0) {
            availableQty = totalAvail; // no max per user but has max total
        } else {
            availableQty = Math.min(userAvail, totalAvail); // take minimum of both amt
        }

        return availableQty;
    }
    const isPurchaseDisabled = () => {
        // Display info
        let purchaseDisabled = false;
        /* Ended */
        if (Date.now() > shopEntry.endDate) {
            purchaseDisabled = true;
        } else if (Date.now() < shopEntry.startDate) {
            purchaseDisabled = true;
        } else {
            const userAvailableQty = getUserAvailableQty();
            if (userAvailableQty == 0) { // no more available
                return true;
            }
        }
        
        // Not connected
        if (!accountContext.currentAccount) {
            purchaseDisabled = true;
        }

        return purchaseDisabled;
    }
    const getUserPrice = () => { // get price of user
        return accountContext.numOwnedTigers > 0 ? shopEntry.priceWithTiger : shopEntry.priceWithoutTiger;
    }
    const hasMaxPerUser = () => { // check whether limit on purchase
        return shopEntry['maxEntriesPerUser'] >= 0;
    }
    const getUserEntriesString = () => {
        if (hasMaxPerUser()) {
          return `${shopEntry.userPurchased}/${shopEntry.maxEntriesPerUser}`
        }
        return `${shopEntry.userPurchased}/${shopEntry.purchaseCount}`;
    }
    const getTotalEntriesString = () => {
        if (hasMaxTotal()) {
            return `${shopEntry.purchaseCount}/${shopEntry.maxEntries}`;
        }
        return `${shopEntry.purchaseCount}`;
    }

    /**
     * Countdown utils
     */
    const [timeLeft, setTimeLeft] = useState(null);
    useEffect(() => {
        const intervalId = setInterval(() => {
        const now = new Date();
        let diff = null;

        /* HAS STARTED ==> Countdown to end */
        if (now > shopEntry["startDate"]) {
            if (now > shopEntry["endDate"]) { // no countdown if already over
            setTimeLeft(null);
            return;
            }
            diff = new Date(shopEntry["endDate"].getTime()) - now.getTime();        
        } else { /* HASNT STARTED ==> Countdown to end */
            diff = new Date(shopEntry["startDate"].getTime()) - now.getTime();
        } 

        if (diff !== null) {
            setTimeLeft(
                {
                    seconds: Math.floor( diff /     1000 %   60 ),
                    minutes: Math.floor( diff /    60000 %   60 ),
                    hours:   Math.floor( diff /  3600000 %   24 ),
                    days:    Math.floor( diff / 86400000        )
                }
            )
        }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [shopEntry['endDate']])

    const hasStarted = () => {
        return Date.now() > shopEntry['startDate'];
    }
    const hasMaxTotal = () => { // if raffle, no limit
        return shopEntry['maxEntries'] >= 0;
    }
    const isSoldOut = () => {
        /* if ended, set as sold out */
        if (Date.now() > shopEntry['endDate']) {
          return true;
        }
    
        if (!hasMaxTotal()) {
          return false;
        }
    
        return shopEntry.maxEntries - shopEntry.purchaseCount <= 0;
    }
    const getCountdown = () => {
        if (isSoldOut()) { // no countdown if ended
            return "Ended";
        }

        let countdownText = "Calculating ...";
        if (timeLeft === null) {
            return "";
        } else {
            if (timeLeft.days > 0) {
                countdownText = `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m`;
            } else {
                countdownText = `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;
            }
        }

        if (hasStarted()) {
            return `Ending in ${countdownText}`;
        }
        return `Coming in ${countdownText}`;
    }
    

    /* Buttons */
    const ConnectButton = () => {
        return (
            <Button 
                onClick = {accountContext.connectWalletHandler}
                className={classes.purchaseBtn}
            >
                Connect
            </Button>   
        )
    }

    const PurchaseButton = ({purchaseDisabled, itemName, priceEach}) => {
        
        const availableQty = getUserAvailableQty();

        return (
            <Button
                onClick={() => {
                    handleSelectBuy(itemName, availableQty, priceEach, isRaffle());
                }}
                disabled={purchaseDisabled}
                className={classes.purchaseBtn}
                >
                Buy
            </Button>
        )
    }

    const HasPendingButton = ({itemName, userPendingPurchase, userTimedOutPurchase, priceEach}) => {
        return (
            <Button
                onClick={() => {
                    handleSelectPending(itemName, userPendingPurchase, userTimedOutPurchase, priceEach);
                }}
                className={classes.purchaseBtn}
            >
                Past Txn
            </Button>
        )
    }
    
    return (
        <Grid container item xs={narrowView ? 12 : 10} md={11} lg={12} justifyContent="center" alignItems="flex-end"
            className={`${classes.shopItemOneBg} ${isSoldOut() && classes.fade}`}>
            <Grid container item justifyContent="center" alignItems="center"
                className={classes.shopItemWrapper}>
                <span className={classes.countDown}>{getCountdown()}</span>
                <span className={classes.type}>{shopEntry.type}</span>
                <Grid container item justifyContent="center" alignItems="center"
                        sx={{height:"100%"}}>
                    <Grid container item xs={12} justifyContent="flex-start" alignItems="center"
                        sx={{height:"100%"}}>
                        { (shopEntry.imgUrl.length > 0) && 
                            <Grid container item xs={5} justifyContent="center" alignItems="flex-end"
                                sx={{height:"70%"}} 
                                >
                                <img
                                    style={{
                                        width: "60%",
                                        borderRadius: "4px"
                                    }}
                                    alt={shopEntry.title}
                                    src={shopEntry.imgUrl}
                                />
                            </Grid>    
                        }
                        <Grid container item xs={ (shopEntry.imgUrl.length > 0) ? 3.5 : 7.5} justifyContent="center" alignItems="center">
                            <Typography variant="body1"
                                className={classes.shopItemName}
                                align="center"
                                mt={narrowView ? 0.5 : 2}>
                                {shopEntry.title}
                            </Typography>
                        </Grid>
                        {/* Show price*/}
                        <Grid container item xs={3} justifyContent="center" alignItems="center">
                            <Grid container item xs={12} justifyContent="center" alignItems="center"
                                mt={narrowView ? 0.5 : 2}>
                                <Typography variant="body1" align="center"
                                    className={classes.priceText}>
                                    {getUserPrice()}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                <Typography variant="body1" align="center"
                                    className={classes.currency}>
                                    {`$PIXELS`}
                                </Typography>
                            </Grid>
                            { !(Date.now() > shopEntry.endDate)  && 
                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                    <Typography variant="body1" align="center"
                                        className={classes.helperText}>
                                        {isRaffle() ? getUserEntriesString() : getTotalEntriesString()}
                                    </Typography>
                                </Grid>
                            }
                            { (getHelperText().length > 0) &&
                                <Grid container item xs={12} justifyContent="center" alignItems="center">
                                    <Typography variant="body1" align="center"
                                        className={classes.helperText}>
                                        {getHelperText()}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {/* Buy button */}
                    <Grid container item xs={12} justifyContent="center" alignItems="center">
                        { accountContext.currentAccount ?
                            <Grid container item xs={12} justifyContent="center" alignItems="center">
                                { (shopEntry.userPendingPurchase || shopEntry.userTimedOutPurchase) ?
                                    <HasPendingButton
                                        itemName={shopEntry.title}
                                        userPendingPurchase={shopEntry.userPendingPurchase}
                                        userTimedOutPurchase={shopEntry.userTimedOutPurchase}
                                        priceEach={getUserPrice()}
                                    /> 
                                    :
                                    <PurchaseButton 
                                        purchaseDisabled={isPurchaseDisabled()}
                                        itemName={shopEntry.title}
                                        priceEach={getUserPrice()}
                                        />
                                }
                            </Grid>
                            :
                            <ConnectButton/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )    
}

export default ShopItem;