export const mintImages = [
    {
        src: "/assets/images/tigers/3.webp",
        alt: "Surprise!",
    },
    {
        src:"/assets/images/carouselTigers/1.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/12.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/2.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/3.webp",
        alt:"Surprise!"
    },
    {
        src: "/assets/images/tigers/mainRaffle2.webp",
        alt: "Surprise!",
    },
    {
        src:"/assets/images/carouselTigers/4.webp",
        alt:"Surprise!"
    },
    {
        src: "/assets/images/tigers/pinkPanther.webp",
        alt: "Surprise!",
    },
    {
        src:"/assets/images/carouselTigers/9.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/7.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/13.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/11.webp",
        alt:"Surprise!"
    },
    {
        src: "/assets/images/tigers/subRaffle2.webp",
        alt: "Surprise!",
    },
    {
        src:"/assets/images/carouselTigers/6.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/10.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/5.webp",
        alt:"Surprise!"
    },
    {
        src:"/assets/images/carouselTigers/8.webp",
        alt:"Surprise!"
    },
]

export const backgrounds = {
    cover: "/assets/images/main/SubPageBg.webp",
    main: "/assets/images/ui/Mint/CentreBox.webp",
    topLeft: "/assets/images/ui/Mint/TopLeft_Bg.webp",
    topRight: "/assets/images/ui/Mint/TopRight_Bg.webp",
    bottomLeft: "/assets/images/ui/Mint/BtmLeft_Bg.webp",
    bottomRight: "/assets/images/ui/Mint/BtmRight_Bg.webp"
}

export const tigerIcon = "/assets/images/ui/Mint/tigerIcon.webp"