import { db } from '../../config/firebaseClient';
import { doc, collection, setDoc, query, onSnapshot, updateDoc, increment,
         collectionGroup, where, getDocs, deleteDoc, getDoc,
         writeBatch } from "firebase/firestore";

const shopItemsPath = "admin/shop/shopItems";

/**
 * Starts a listener on a query and executes the callback if there are any changes
 * @param {*} query on which to listen
 * @param {*} callback function to execute when any changes are made
 *            (querySnapshot) -> {...}
 * @returns function to unsubscribe from listener
 */ 
const initListener = (q, callback) => {
    return onSnapshot(q, callback);
}

/**
 * Starts a listener on shop items
 * @param {*} callback function to execute on the items
 * @returns function to unsubscribe from listener
 */
 const listenerShopItems = (callback) => {
    const q = query(collection(db, shopItemsPath));
    return initListener(q, (querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data());
        })
        callback(items);
    });
}

/**
 * Starts a listener on all purchases
 * @param {*} callback function to execute on purchases data
 * @returns function to unsubscribe from listener
 */
const listenerShopPurchases = (callback) => {
    const q = query(collectionGroup(db, 'purchases'));
    return (initListener(q, (querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data());
        })
        callback(items); 
    }))
}

/**
 * Starts a listener to get total purchased field of item
 * @param {*} callback function to execute on purchases data
 * @returns function to unsubscribe from listener
 */
const listenerShopTotalPurchases = (callback) => {
    const q = query(collection(db, 'shop'));
    return initListener(q, (querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data());
        })
        callback(items);
    });
}

/**
 * @param {*} address user wallet address
 * @returns list of purchases made by user
 */
const getUserPurchases = async (address) => {
    const userPurchases = query(collectionGroup(db, 'purchases'), where('address', '==', address));
    const querySnapshot = await getDocs(userPurchases);
    let purchases = [];
    querySnapshot.forEach((doc) => {
        purchases.push(doc.data());
    })
    return purchases;
}

/**
 * Increments total purchased amount
 * @param {*} itemName name of item in shop
 * @param {*} quantity number of items purchased
 */
 const updateItemTotalPurchased = (batch, itemName, quantity) => {
    const shopItemRef = doc(db, `shop/${itemName}`);
    batch.update(shopItemRef, {
        totalPurchased: increment(quantity)
    })
}


/**
 * Increments number of pending purchases
 * @param {*} itemName Name of item
 * @param {*} quantity number of item intending to purchase
 */
 const updateItemPendingPurchase = async (batch, itemName, quantity) => {
    const shopItemRef = doc(db, `shop/${itemName}`);

    batch.update(shopItemRef, {
        pendingPurchase: increment(quantity),
    })
}

/**
 * Increments number of timed out purchases
 * @param {*} itemName Name of item
 * @param {*} quantity number of item intending to purchase
 */
 const updateItemTimedOutPurchase = async (batch, itemName, quantity) => {
    const shopItemRef = doc(db, `shop/${itemName}`);
    batch.update(shopItemRef, {
        timedOut: increment(quantity)
    })
}



/**
 * Record user's purchase in database
 * @param {*} itemName name of item in shop
 * @param {*} address user wallet address
 * @param {*} txnHash transaction has of blockchain transaction
 * @param {*} discordId user discord id
 * @param {*} quantity number of items purchased
 * @returns 
 */
 const purchaseItem = (batch, itemName, address, txnHash, discordId, quantity, success, error, status) => {
    const shopPurchaseRef = doc(db, `shop/${itemName}/allPurchases/${address}/purchases`, `${txnHash}`);
    const date = new Date();
    batch.set(shopPurchaseRef, {
        itemName: itemName,
        address: address,
        txnHash: error === null ? txnHash : "", 
        discordId: discordId,
        quantity: quantity,
        date: date,
        success: success,
        error: error === null ? "" : error,
        status: status
    })
}


/**
 * Set user purchase pending status before transaction executes
 * @param {*} itemName name of project
 * @param {*} address user wallet address
 * @param {*} discordId user discord id
 * @param {*} quantity number of items to purchase
 * @returns 
 */
 const initPurchasePending = (batch, itemName, address, discordId, quantity) => {
    const shopPurchaseRef = doc(db, `shop/${itemName}/allPurchases/${address}/purchases`, `${address}`);
    const date = new Date();
    batch.set(shopPurchaseRef, {
        itemName: itemName,
        address: address,
        txnHash: "", 
        discordId: discordId,
        quantity: quantity,
        date: date,
        success: false,
        error: "",
        status: "PENDING"
    })
}

/**
 * Starts a listener to get pending purchases
 * @param {*} callback function to execute on pending purchases data
 * @returns function to unsubscribe from listener
 */
 const listenerShopPendingPurchases = (callback) => {
    const q = query(collectionGroup(db, 'purchases'), where('status', '==', "PENDING"));
    return initListener(q, (querySnapshot) => {
        const items = []
        querySnapshot.forEach((doc) => {
            items.push(doc.data());
        })
        callback(items);
    });
}

/**
 * Fetch timed out purchases
 * @TODO use this instead of listenerShopFailedPurchases in future
 * @returns all failed transactions
 */
 const getTimedOutPurchases = async () => {
    const q = query(collectionGroup(db, 'purchases'), where("status", "==", "TIMEDOUT"));
    const querySnapshot = await getDocs(q);
    
    /* Fetch purchases */
    const purchases = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        purchases.push({
            ...data,
            date: data['date'].toDate().toLocaleString()
        });
    })    
    return purchases;
}

/**
 * Fetch failed purchases
 * @returns all failed transactions
 */
 const getFailedPurchases = async () => {
    const q = query(collectionGroup(db, 'purchases'), where("success", "==", false));
    const querySnapshot = await getDocs(q);
    
    /* Fetch purchases */
    const purchases = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        purchases.push({
            ...data,
            date: data['date'].toDate().toLocaleString()
        });
    })    
    return purchases;
}

/**
 * Handle user timed out purchases
 * @param {*} itemName name of item in shop
 * @param {*} address user Address
 */
 const deleteUserTimedOut = async (itemName, address) => {
    try {
        const userFailedRef = query(collection(db, `shop/${itemName}/allPurchases/${address}/purchases`));
        const querySnapshot = await getDocs(userFailedRef);
        querySnapshot.forEach(async (failedTxn) => {
            const data = failedTxn.data();
            if (data.error.includes("not mined within 50 blocks")) {
                const failedTxnRef = doc(db, `shop/${itemName}/allPurchases/${address}/purchases/${failedTxn.id}`);
                updateDoc(failedTxnRef, {
                    status: "HANDLED"
                })
            }
        })
    } catch (e) {
        alert(`Error deleting transaction: ${e}`)
    }
}

/**
 * Delete user pending purchase
 * @param {*} itemName name of project
 * @param {*} address user wallet address
 */
 const deletePurchasePending = (batch, itemName, address, userdiscordId, quantity) => {
    const shopPurchaseRef = doc(db, `shop/${itemName}/allPurchases/${address}/purchases`, `${address}`);
    purchaseItem(batch, itemName, address, Date.now().toString(), userdiscordId, quantity, false, null, "HANDLED");
    batch.delete(shopPurchaseRef);
}

/**
 * Check if transaction hash already exists
 */
const transactionExists = async (txnHash) => {
    const q = query(collectionGroup(db, 'purchases'), where("txnHash", "==", txnHash));
    const querySnapshot = await getDocs(q);

    // consolidate transactions
    const txn = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        txn.push({
            ...data,
            date: data['date'].toDate().toLocaleString()
        });
    })    
    return (txn.length > 0);
}

/**
 * Batch write to initialize pending purchase
 */
 const handleInitPurchasePending = async (itemName, address, discordId, quantity) => {
    const batch = writeBatch(db);

    updateItemPendingPurchase(batch, itemName, quantity);
    initPurchasePending(batch, itemName, address, discordId, quantity);

    await batch.commit();
}

/**
 * Batched write to handle failed purchase
 */
const handleFailedPurchase = async (itemName, address, txnHash, discordId, quantity, success, error, status) => {
    const batch = writeBatch(db);

    // ShopFn.updateItemPendingPurchase(itemName, -quantity);
    updateItemPendingPurchase(batch, itemName, -quantity);
    
    // ShopFn.deletePurchasePending(itemName, address, discordId, quantity);
    deletePurchasePending(batch, itemName, address, discordId, quantity)

    if (error.includes("not mined within 50 blocks")) {
        updateItemTimedOutPurchase(batch, itemName, quantity);
    }
    purchaseItem(batch, itemName, address, txnHash, discordId, quantity, success, error, status);

    await batch.commit();
}

/**
 * Batched write to handle successful purchase
 */
const handleSuccessfulPurchase = async (itemName, address, quantity, txnHash, discordId) => {
    const batch = writeBatch(db);

    // reduce pending count
    updateItemPendingPurchase(batch, itemName, -quantity);

    // add purchase transaction and total purchase count
    updateItemTotalPurchased(batch, itemName, quantity);
    purchaseItem(batch, itemName, address, txnHash, discordId, quantity, true, null, "COMPLETE");

    // delete current purchase pending txn
    deletePurchasePending(batch, itemName, address, discordId, quantity); // remove pending transaction

    await batch.commit();
}

/**
 * Batched write to delete pending txn
 */
const handleDeletePending = async (itemName, address, discordId, quantity) => {
    const batch = writeBatch(db);

    // decrease pending count
    updateItemPendingPurchase(batch, itemName, -quantity);
    // delete current purchase pending txn
    deletePurchasePending(batch, itemName, address, discordId, quantity);

    await batch.commit();
}

/**
 * combined functions to delete user timed out
 */
const handleDeleteTimedOut = async (itemName, address, quantity) => {
    const batch = writeBatch(db);

    updateItemTimedOutPurchase(batch, itemName, -quantity);

    await batch.commit();
    // not in batch since have to search and delete    
    await deleteUserTimedOut(itemName, address);
}

/**
 * Batched writes to verify timed out purchase
 */
 const handleVerifyTimedOut = async (itemName, address, txnHash, discordId, quantity) => {
    const batch = writeBatch(db);

    // decrease pending count
    updateItemTimedOutPurchase(batch, itemName, -quantity);

    // Create new purchase
    updateItemTotalPurchased(batch, itemName, quantity);
    purchaseItem(batch, itemName, address, txnHash, discordId, quantity, true, null, "COMPLETE"); 
    await batch.commit();
    // not in batch since have to search and delete    
    await deleteUserTimedOut(itemName, address);
}

/**
 * Batched writes to verify pending purchase
 */
const handleVerifyPending = async (itemName, address, txnHash, discordId, quantity) => {
    const batch = writeBatch(db);

    // decrease pending count
    updateItemPendingPurchase(batch, itemName, -quantity);
    // delete current purchase pending txn
    deletePurchasePending(batch, itemName, address, discordId, quantity);

    // Create new purchase
    updateItemTotalPurchased(batch, itemName, quantity);
    purchaseItem(batch, itemName, address, txnHash, discordId, quantity, true, null, "COMPLETE"); 
    await batch.commit();
}

export const ShopFn = {
    listenerShopItems,
    listenerShopPurchases,
    listenerShopTotalPurchases,
    listenerShopPendingPurchases,
    getFailedPurchases,
    getUserPurchases,
    transactionExists,
    handleInitPurchasePending,
    handleFailedPurchase,
    handleSuccessfulPurchase,
    handleDeletePending,
    handleDeleteTimedOut,
    handleVerifyPending,
    handleVerifyTimedOut
}