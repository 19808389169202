import { useState, useEffect } from "react";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Filters from './Filters';
import FilterDisplay from './FilterDisplay';
import CardsDisplay from './CardsDisplay';
import TypeSelect from './TypeSelect';

const useStyles = () => makeStyles(theme => ({
        Wrapper: {
            display: "flex !important",
            width: "90%",
            flexDirection: "column",
            [theme.breakpoints.down('md')]: {
                width: "100%",
            }
        },
        DescWrapper: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        DescBG: {
            backgroundImage: `url(/assets/images/ui/Roadmap/RM_textbox1.webp)`,
            backgroundPosition: 'center',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "50%",
            aspectRatio: "2.5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down('lg')]: {
                width: "65%",
            },
            [theme.breakpoints.down('md')]: {
                width: "70%",
                marginBottom: "20px",
            },
            [theme.breakpoints.down('sm')]: {
                width: "90%",
            }
        },
        Description: {
            fontFamily: theme.palette.gothamMedFont,
            color: theme.palette.primary.dark,
            width: "80%",
            textAlign: "justify",
            fontSize: "max(1.2vw, 15px)",
            [theme.breakpoints.down('lg')]: {
                fontSize: "max(1.6vw, 14px)",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "max(1.8vw, 11.5px)",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "max(2.4vw, 10px)",
            }  
        },
        ControlWrapper: {
            width: "100%",
            // marginBottom: "-4.4rem",
            zIndex: "2",
        },
        GalleryWrapper: {
            display: "flex !important",
            width: "100%",
            zIndex: "2"
        },
        Background: {
            [theme.breakpoints.down('md')]: {
                boxSizing: "border-box !important",
                flex: "none !important",
                width: "100% !important",
                zIndex: "1 !important",
                // backgroundColor: "rgb(255,0,0,0.2)"
            }
        },
        Foreground: {
            [theme.breakpoints.down('md')]: {
                boxSizing: "border-box !important",
                flex: "none !important",
                width: "100% !important",
                zIndex: "2 !important",
                // backgroundColor: "rgb(0,0,255,0.2)"
            }
        },
        FilterWrapper: {
            height: "100%",
            width: "max(30vw, 400px)",
            position: "sticky",
            top: "4.4rem",
            // paddingTop: "4.4rem",
            [theme.breakpoints.up('md')]: {
                marginRight: "20px",
                maxHeight: "90vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                    display: "none"
                },
                scrollbarWidth: "none",
                
            },
        },
        DisplayWrapper: {
            width: "100%",
            // paddingTop: "4.4rem",
            [theme.breakpoints.down('md')]: {
                marginLeft: "-100%",
            }
        },
        FilterTabBG: {
            backgroundColor: theme.palette.primary.light,
            padding: "10px",
            borderRight: `2px solid ${theme.palette.primary.dark}`,
            borderTop: `2px solid ${theme.palette.primary.dark}`,
            borderBottom: `2px solid ${theme.palette.primary.dark}`,
            borderRadius: `0px 8px 8px 0px`,
            [theme.breakpoints.down('md')]: {
                width: "max(300px, 40vw) !important",
            }
        },
        ToggleFilterBtn: {
            color: theme.palette.highlight,
            backgroundColor: alpha(theme.palette.primary.dark, 1),
            borderRadius: "8px !important",
            display: "flex",
            alignItems: "center",
            margin: "0% 2%",
            zIndex: "10",
        },
        FilterHeaderWrapper: {
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid #5B4316 !important"
        },
        FilterHeaderWrapperBtn: {
            width: "100%",
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid #5B4316 !important",
            borderRadius: "0px",
            color: theme.palette.primary.dark
        },
        FilterHeader: {
        },
        FilterHeaderText: {
            fontSize:"1.8em !important", 
            padding: "0px 10px",
            // color: theme.palette.primary.dark,
        },
        IdFilter: {
            marginBottom: "10px",
            width: "90%",
            marginRight: "14px",
            "& .MuiOutlinedInput-input":{ // style of balance num overall
                fontSize: "12px",
                fontFamily: theme.palette.gothamMedFont,
                color: theme.palette.primary.dark,
            },
        },
        FilterScroll: {
            height: "min(100vh, 600px)",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
                display: "none",
            },
            scrollbarWidth: "none",
        },
        Hide: {
            display: "none",
        },
        OnlyIcon: {
            [theme.breakpoints.down('md')]: {
                zIndex: "5 !important",
                height: "0px !important",
                // width: "40px !important",   
            }
        },
        Fade: {
            [theme.breakpoints.down('md')]: {
                opacity: "0.7",
                height: "100%", // disable scrolling
                overflow: "hidden",
                zIndex: "0 !important",
            }
        },
        TigerCount: {
            fontSize: "36px",
            fontFamily: theme.palette.blockyFont,
            color: theme.palette.highlight,
            margin: "0px 12px",
            textShadow: `0px 6px ${theme.palette.primary.dark}`,
        },
        SelectDropdownWrapper: {
            // backgroundColor: alpha(theme.palette.primary.light, 0.8),
            // boxShadow: `0px 6px 6px 6px ${theme.palette.primary.dark}`,
            // borderRadius: "10px",
            padding: "0px 10px",
            [theme.breakpoints.down('md')]: {
                order: "3",
                // margin: "0px 4px",
                // backgroundColor: "red",
            }
        },
        SelectDropdownLabel: {
            fontSize: "14px",
            fontFamily: theme.palette.pixelFont,
            color: theme.palette.offWhite,
        },
        SelectDropdown: {
            '& .MuiList-root': {
                // backgroundColor: theme.palette.primary.light,
            },
            '& .MuiMenuItem-root': {
                fontFamily: theme.palette.pixelFont,
                // color: theme.palette.primary.dark,
            }
        },
        FilterDisplay: {
            width: "100% !important",
            [theme.breakpoints.down('md')]: {
                order: "4"
            }
        },
        TigerCountWrapper: {
            [theme.breakpoints.down('md')]: {
                order: "1"
            }
        },
        TypeSelectWrapper: {
            [theme.breakpoints.down('md')]: {
                order: "2",
            }
        }
    })
)

const GalleryDisplay = ({ pixelTigers, attributes, selectedType, setSelectedType }) => {

    const theme = useTheme();
    const classes =  useStyles()(theme);
    
    // Show filter bar
    const [showFilterTab, setShowFilterTab] = useState(true);//useState(mobileView ? false : true);
    const toggleShowFilterTab = () => {
        setShowFilterTab(!showFilterTab);
    }
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => { // show if change size back to normal
        let isMounted = true;
        if (isMounted) {
            if (!mobileView) {
                setShowFilterTab(true);
            } else {
                setShowFilterTab(false);
            }
        }
        return () => { isMounted = false; };
    }, [mobileView])

    /* Filter format
        {
            attribute: [values];
        }
        OR for same attribute
        AND for diff attribute
    */
    const [filters, setFilters] = useState({});
    const updateFilter = (newFilters) => {
        setFilters(newFilters)
    }
    useEffect(() => { // Reset filter when wulf type change
        setFilters({});
    }, [attributes])
    /**
     * Check if the given attribute: value is inside the filters
     */
    const isFilterSet = (attr, value) => {
        // attr not inside
        if (!(attr in filters)) {
            return false;
        }
        // value not inside selected attr
        if (!filters[attr].includes(value)) {
            return false;
        }
        return true;
    }

    /**
     * Add the given attribute: value combination into the filter object
     */
    const addFilter = (attr, value) => {
        const newFilters = {...filters};
        // add if not inside
        if (!(attr in newFilters)) {
            newFilters[attr] = [];    
        }        
        newFilters[attr].push(value);
        updateFilter(newFilters);
    }

    /**
     * Remove the given attribute: value combination from the filter object
     */
    const removeFilter = (attr, value) => {
        const newFilters = {...filters};
        // remove if inside
        const newVals = newFilters[attr].filter((x) => x !== value);
        if (newVals.length == 0) {
            delete newFilters[attr];
        } else {
            newFilters[attr] = newVals;
        }
        updateFilter(newFilters);
    }
    
    /* Add / remove filter */
    const toggleFilter = (attr, value) => {
        isFilterSet(attr, value) ? removeFilter(attr, value) : addFilter(attr, value);
    }
    
    /* Clear all filters */
    const clearFilters = () => {
        updateFilter([]);
    }

    /* PixelTiger ID filter */
    const [idFilter, setIdFilter] = useState("");
    const updateIdFilter = (event) => {
        setIdFilter(event.target.value);
    }

    /* Sort method */
    const [sortType, setSortType] = useState("ID");
    const shuffleArray = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    /* Utils to filter wulfz */
    const tigerHasAttr = (tiger, attr, values) => {
        // Get value of selected attribute
        const tigerAttr = tiger["attributes"][attr];
        return values.includes(tigerAttr);
    }
    const [filteredTigers, setFilteredTigers] = useState(pixelTigers);
    const getFilteredTigers = () => {
        let filtered = [...pixelTigers];

        // Apply filters
        Object.keys(filters).forEach((attr) => {
            const values = filters[attr];
            filtered = filtered.filter((x) => tigerHasAttr(x, attr, values))
        })

        if (idFilter.length > 0) {
            filtered = filtered.filter((x) => x.tokenId.toString().includes(idFilter));
        }

        // Sort wulfz
        if (sortType.includes("RANDOM")) {
            shuffleArray(filtered);
        } else if (sortType === "RANK") {
            filtered.sort((a, b) => a.rank - b.rank);
        } else if (sortType === "ID") {
            filtered.sort((a, b) => a.typeId - b.typeId); 
        }
        return filtered;
    }
    useEffect(() => {
        const filtered = getFilteredTigers()
        setFilteredTigers(filtered);
    }, [pixelTigers, filters, sortType, idFilter])

    return (
        <div className={classes.Wrapper}>
            {/* <div className={classes.DescWrapper}>
                <div className={classes.DescBG}>
                    <span className={classes.Description}>
                        A mystical energy, pixels act as the only resource in The Jungle. PixelTigers discovered 
                        that the coveted pixels are needed to give life to PixelCubs, or to test their luck at the Raffles. 
                        Could there be more secrets behind the existence of pixels? What else can this mysterious energy 
                        unlock in this realm? More will be revealed as PixelTigers explore deeper into The Jungle….
                    </span>
                </div>
            </div> */}
            <div className={classes.ControlWrapper}>
                <Grid container item xs={12} justifyContent="center" alignItems="center"
                    >
                    <Grid container item xs={2.5} md={1.5} lg={1.25} xl={1} justifyContent="flex-start" alignItems="center"
                        className={classes.SelectDropdownWrapper}>
                        <Typography variant="body1" className={classes.SelectDropdownLabel}>Sort By:</Typography>
                        <FormControl variant="standard" fullWidth>
                            <Select
                                value={sortType}
                                onChange={(event) => setSortType(event.target.value)}
                                SelectDisplayProps={{
                                    style: {
                                        fontFamily: theme.palette.pixelFont,
                                        color: theme.palette.highlight,
                                    }
                                }}
                                MenuProps={{
                                    classes: { paper: classes.SelectDropdown}
                                }}
                            >
                                { selectedType !== "Cub" && 
                                    <MenuItem value="RANK">RANK</MenuItem>
                                }
                                <MenuItem value="ID">ID</MenuItem>
                                <MenuItem value="RANDOM">RANDOM</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={9.5} md={5.5} lg={6.5} xl={7.5} className={classes.FilterDisplay}>
                        <FilterDisplay 
                            filters={filters} 
                            removeFilter={removeFilter}
                            clearFilters={clearFilters}/>
                    </Grid>
                    <Grid container item xs={4} md={1.5} lg={1.25} xl={1} justifyContent="center" alignItems="center"
                        className={classes.TigerCountWrapper}>
                        <span className={classes.TigerCount}>
                            {filteredTigers.length}
                        </span>
                    </Grid>
                    <Grid container item xs={8} md={3.5} lg={3} xl={2.5} justifyContent="center" alignItems="center"
                        className={classes.TypeSelectWrapper}>
                        <TypeSelect
                            mobileView={mobileView} 
                            selectedType={selectedType} 
                            setSelectedType={setSelectedType}
                            />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.GalleryWrapper}>
                <div className={`${classes.FilterWrapper} ${!showFilterTab && classes.OnlyIcon} 
                    ${(showFilterTab) ? classes.Foreground : classes.Background}`}
                    >
                    {/* { (mobileView && showFilterTab) &&
                        <div className={styles.CloseFilter} 
                            onClick={() => {
                                if (mobileView && showFilterTab) {
                                    toggleShowFilterTab();
                                }
                        }}>
                        </div>
                    } */}
                    { (mobileView && !showFilterTab) &&
                        <IconButton className={classes.ToggleFilterBtn} onClick={toggleShowFilterTab}>
                            <FilterListIcon sx={{fontSize:"1.6em"}}/>
                        </IconButton>
                    }
                    <Grid xs={12} container item className={`${classes.FilterTabBG} ${!showFilterTab && classes.Hide}`}>
                        { !mobileView ? 
                            <Grid container item xs={12} className={classes.FilterHeaderWrapper}>
                                <Grid container item xs={12} className={classes.FilterHeader}>
                                    <FilterListIcon className={classes.FilterHeaderText}/>
                                    <Typography variant="h2" className={classes.FilterHeaderText}>FILTER</Typography>
                                </Grid>
                            </Grid>
                            :   
                            <Button className={classes.FilterHeaderWrapperBtn}
                                onClick={toggleShowFilterTab}>
                                <Grid container item className={classes.FilterHeader}>
                                    <FilterListIcon className={classes.FilterHeaderText}/>
                                    <Typography variant="h2" className={classes.FilterHeaderText}>FILTER</Typography>
                                </Grid>
                                <span>X</span> 
                            </Button>
                        }
                        <Grid xs={12} container item className={classes.IdFilterWrapper}
                            justifyContent="center" alignItems="center">
                            <TextField className={classes.IdFilter} 
                                size="small"
                                placeholder="Tiger ID"
                                value={idFilter} 
                                onChange={(e) => updateIdFilter(e)} 
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <ManageSearchIcon />
                                        </InputAdornment>
                                }}
                                />
                        </Grid>
                        <Grid xs={12} container item className={classes.FilterScroll}>
                            <Filters 
                                curFilters={filters}
                                attrCounts={attributes} 
                                isFilterSet={isFilterSet}
                                toggleFilter={toggleFilter}/>
                        </Grid>
                    </Grid>
                </div>
                <div className={`${classes.DisplayWrapper} ${showFilterTab && classes.Fade}
                    ${(showFilterTab) ? classes.Background : classes.Foreground}`}> 
                        <Grid container item className={classes.CardsDisplay}>
                            <CardsDisplay 
                                tigers={filteredTigers}
                                mobileView={mobileView}
                                />
                        </Grid>
                </div>
            </div>
        </div>
    )
}

export default GalleryDisplay;