import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';

import ScrollToTop from './Utils/ScrollToTop';
import LinkAccount from './LinkAccountComponent/LinkAccount';

function LinkAccountPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <LinkAccount mobileView={mobileView.mobileView}/>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default LinkAccountPage;
 