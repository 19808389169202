// TODO: Add data of members
export const membersInfo = {
  zaklewis: {
    name: "Zak Lewis",
    image: {
      src: "/assets/images/team/zaklewis.gif",
    },
    title: "",
  },
  sampath: {
    name: "Sam Path",
    image: {
      src: "/assets/images/team/sampath.png",
    },
    title: "",
  },
  jonathanbeaudoin: {
    name: "Jonathan Beaudoin",
    image: {
      src: "/assets/images/team/jonathanbeaudoin.png",
    },
    title: "",
  },
  montanaturner: {
    name: "Montana Turner",
    image: {
      src: "/assets/images/team/montanaturner.png",
    },
    title: "",
  },
  ilinlee: {
    name: "I-Lin Lee",
    image: {
      src: "/assets/images/team/ilinlee.png",
    },
    title: "",
  },
  mahdishirzadkhan: {
    name: "Mahdi Shirzadkhan",
    image: {
      src: "/assets/images/team/mahdishirzadkhan.png",
    },
    title: "",
  },
  raelynnchan: {
    name: "Raelynn Chan",
    image: {
      src: "/assets/images/team/raelynnchan.png",
    },
    title: "Lead Artist",
  },
  kaiyang: {
    name: "Kai Yang",
    image: {
      src: "/assets/images/team/kaiyang.png",
    },
    title: "Front End Developer",
  },
  liolim: {
    name: "Lio Lim",
    image: {
      src: "/assets/images/team/liolim.png",
    },
    title: "Game Designer",
  },
};