import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Waypoint } from 'react-waypoint';
import { CSSTransition } from 'react-transition-group';

import { backgrounds } from './raffleData';
import './styles.css';

const useStyles = (mobileView, narrowView) => makeStyles(theme => ({
        prize: {
            height: "100%",
            // backgroundColor:'rgb(255,255,0,0.2)',
            position:'relative'
        },
        firstPrizeBg: {
            width: "105%",
            zIndex: '3',
            position: 'absolute'
        },
        firstPrizeImg: {
            width: "80%",
            zIndex: '4',
            position: 'absolute',
            top: `${narrowView ? "29%" : "35%"}`,
            borderRadius: "3px"
        },
        firstPrizeTitle: {
            fontSize: `${narrowView ? 'max(0.7rem,min(1rem,2.3vw))' : 'max(1.2rem,min(1.7rem,1.6vw))'}`,
            fontFamily: theme.palette.blockyFont,
            position: 'absolute',
            zIndex: '4',
            top: `${ narrowView ? '5%' : '20%'}`
        },
        firstPrizeText: {
            fontSize: `${narrowView ? 'max(0.6rem,min(0.8rem,1.7vw))' : 'max(1rem,min(1.4rem,1.4vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            fontWeight: 'bold',
            color: theme.palette.highlight,
            position: 'absolute',
            zIndex: '4',
            top: `${ narrowView ? '95%' : '77%'}`,
            textShadow: '0px 0px 5px black, 0px 0px 5px black, 0px 0px 2px black, 0px 0px 2px black'
        },
        secondPrizeImg: {
            width: "75%",
            zIndex: '3',
            position: 'absolute',
            top: "40%",
            borderRadius: "3px"
        },
        secondPrizeText: {
            fontSize: `${narrowView ? 'max(0.5rem,min(0.8rem,1.4vw))' : 'max(0.8rem,min(1.4rem,1vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            fontWeight: 'bold',
            color: theme.palette.highlight,
            position: 'absolute',
            zIndex: '3',
            top: `${ narrowView ? '88%' : 'max(153px,72.5%)'}`,
            width: "100%",
            textShadow: '0px 0px 5px black, 0px 0px 5px black, 0px 0px 2px black, 0px 0px 2px black'
        },
        otherPrizesBg: {
            width: "105%",
            position: 'absolute',
            zIndex: '1',
        },
        otherPrizesBgInner: {
            backgroundColor: "#ebd4ad",
            top: "40%",
            borderRadius: "3px",
            height: `${narrowView ? "59%" : "max(85px,39%)"}`,
            width: "75%",
            position: 'absolute',
            zIndex: '2',
        },
        otherPrizesTitle: {
            fontSize: `${narrowView ? 'max(0.6rem,min(0.8rem,1.5vw))' : 'max(0.84rem,min(1.5rem,1.3vw))'}`,
            width: '95%',
            fontFamily: theme.palette.blockyFont,
            position: 'absolute',
            zIndex: '3',
            top: `${narrowView ? '4%' : '21%'}`,
        },
        otherPrizesText: {
            fontSize: `${narrowView ? 'max(0.5rem,min(0.7rem,1.3vw))' : 'max(0.7rem,min(1rem,1.05vw))'}`,
            fontFamily: theme.palette.gothamMedFont,
            fontWeight: 'bold',
            color: theme.palette.highlight,
            position: 'absolute',
            zIndex: '3',
            top: `51%`,
            width: "75%",
            textShadow: '0px 0px 5px black, 0px 0px 5px black, 0px 0px 2px black, 0px 0px 2px black'
        },
    })
)

const Prizes = ({ narrowView, mobileView, raffleData }) => {
    const theme = useTheme();

    const classes = useStyles(mobileView, narrowView)(theme);
        
    const [showPrizes, setShowPrizes] = useState(false);
    // Prizes container
    return (
        <Waypoint
            onEnter={() => {
                setShowPrizes(true);
            }}
            onLeave={() => {
                setShowPrizes(false);
            }}
            >
            <Grid container item xs={12} md={11} justifyContent="center" alignItems="center"
                sx={{height: "100%"}}>
                {/* 1st */}
                <Grid container item xs={4} justifyContent="center" alignItems="center" className={classes.prize}>
                    <CSSTransition
                        in={showPrizes}
                        timeout={2000}
                        classNames="firstPrize"
                    >
                        <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{height:"100%"}}>
                            <img src={backgrounds.firstPrize} alt="First Prize"
                                className={classes.firstPrizeBg} />
                            <img src={raffleData.Prizes.first.imgsrc} alt={raffleData.Prizes.first.alt} 
                                className={classes.firstPrizeImg}/>
                            <Typography variant="body1" className={classes.firstPrizeTitle}> 
                                {raffleData.Prizes.first.title}
                            </Typography>
                            <Typography variant="body1" className={classes.firstPrizeText}> 
                                {raffleData.Prizes.first.text}
                            </Typography>
                        </Grid>
                    </CSSTransition>
                </Grid>
                {/* 2nd */}
                <Grid container item xs={3.3} justifyContent="center" alignItems="center" className={classes.prize}>
                    <CSSTransition
                            in={showPrizes}
                            timeout={2000}
                            classNames="secondPrize"
                        >
                        <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{height:"100%"}}>
                            <img src={backgrounds.otherPrize} alt="Second Prize"
                                className={classes.otherPrizesBg} />
                            <div className={classes.otherPrizesBgInner} />
                            <img src={raffleData.Prizes.second.imgsrc} alt={raffleData.Prizes.second.alt} 
                                className={classes.secondPrizeImg}/>
                            <Typography variant="body1" align="center" className={classes.otherPrizesTitle}
                                > 
                                {raffleData.Prizes.second.title}
                            </Typography>
                            <Typography variant="body1" align="center" className={classes.secondPrizeText}
                                >
                                {raffleData.Prizes.second.text}
                            </Typography>
                            {/* <img src={backgrounds.otherPrize} alt="Third Prize"
                                className={classes.otherPrizesBg} />
                            <div className={classes.otherPrizesBgInner} />
                            <Typography variant="body1" align="center"
                                className={classes.otherPrizesTitle} > 
                                {raffleData.Prizes.second.title}
                            </Typography>   
                            <Typography variant="body1" align="center"
                                className={classes.otherPrizesText} > 
                                {raffleData.Prizes.second.text}
                            </Typography> */}
                        </Grid>
                    </CSSTransition>
                </Grid>
                {/* 3rd */}
                <Grid container item xs={3.3} justifyContent="center" alignItems="center" className={classes.prize}>
                    <CSSTransition
                            in={showPrizes}
                            timeout={2000}
                            classNames="thirdPrize"
                        >
                        <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{height:"100%"}}>
                            <img src={backgrounds.otherPrize} alt="Third Prize"
                                className={classes.otherPrizesBg} />
                            <div className={classes.otherPrizesBgInner} />
                            <Typography variant="body1" align="center"
                                className={classes.otherPrizesTitle} > 
                                {raffleData.Prizes.third.title}
                            </Typography>   
                            <Typography variant="body1" align="center"
                                className={classes.otherPrizesText} > 
                                {raffleData.Prizes.third.text}
                            </Typography> 
                        </Grid>
                    </CSSTransition>
                </Grid>
            </Grid>
        </Waypoint>
    )
}
export default Prizes;
