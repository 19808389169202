import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import Raffle from './RaffleComponent/Raffle';
import ScrollToTop from './Utils/ScrollToTop';
import { RaffleProvider } from './FirebaseUtils';

function RafflePage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <RaffleProvider>
            <Raffle mobileView={mobileView.mobileView}/>
          </RaffleProvider>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default RafflePage;
