export const backgrounds = {
    cover: "/assets/images/main/SubPageBg.webp",
    main: "/assets/images/ui/Raffle/CentreBox.webp",
    topLeft: "/assets/images/ui/Raffle/TopLeft_Bg.webp",
    firstPrize: '/assets/images/ui/Raffle/FirstPrize_Bg.webp',
    otherPrize: "/assets/images/ui/Raffle/OtherPrize_Bg.webp",
    otherPrizeInner: "/assets/images/ui/Raffle/OtherPrizeInner_Bg.webp"
}

export const MainRaffleData = {
    type: "main",
    Header: "Main Raffle",
    Prizes: {
        first: {
            title: "1st Prize",
            imgsrc: "/assets/images/tigers/1957.png",
            alt: "grrrr!",
            text: "CNY "
        },
        second: {
            title: "2nd - 5th Prize",
            imgsrc: "/assets/images/tigers/1779.png",
            alt: "Tiger",
            text: "Tiger"
        },
        third: {
            title: "6th - 15th Prize",
            text: "100 $PIXEL"
        }
    },
    RaffleEnd: "24 Jan 2023 1:00:00 GMT+0800", // GMT +08, 24hr clock
    Description: [
        "Each ticket is 1 entry",
        "1 ticket costs 10 $PIXELS",
        "Winners will be announced in Discord"
    ]
} 

export const SubRaffleData = {
    type: "sub",
    Header: "Sub Raffle",
    Prizes: {
        first: {
            title: "1st Prize",
            imgsrc: "/assets/images/tigers/pinkPanther.webp",
            alt: "Rawr",
            text: "Unique Tiger"
        },
        second: {
            title: "2nd - 5th Prize",
            imgsrc: "/assets/images/tigers/subRaffle2.webp",
            alt: "Random rawr",
            text: "1x Airdrop"
        },
        third: {
            title: "6th - 40th Prize",
            text: "100 $PIXEL Airdrop"
        }
    },
    RaffleEnd: "20 Jan 2022 23:59:00 GMT+0800", // GMT +08, 24hr clock
    Description: [
        "Each ticket is 1 entry",
        "1 ticket costs 10 $PIXELS",
        "Winners will be announced in Discord"
    ]
}