export const pixellogo = {
  src: "/assets/images/main/Pixeltigers_logo.webp",
  alt: "pixeltigerlogo",
};

export const connectData = {
  label: "Connect",
};

export const headersData = [
  {
    label: "Mint",
    href: "mint",
  },
  {
    label: "Shop",
    href: "shop",
  },
  // {
  //   label: "Vote",
  //   href: "vote",
  // },
  {
    label: "$PIXEL",
    href: "token",
  },
  // {
  //   label: "Raffles",
  //   href: "raffle",
  // },
  {
    label: "My Tigers",
    href: "mytigers",
  },
  {
    label: "Jungle Run",
    href: "junglerun"
  }, 
  {
    label: "Gallery",
    href: "gallery"
  },
  {
    label: "Link",
    href: "link"
  }
];

export const socialsData = [
  {
    path: (
      <path
        class="st0"
        d="M78.52,81.49c-2.09-2.88-3.92-5.9-5.52-9.11c1.01-0.43,2-0.83,2.98-1.28c0.99-0.46,1.97-0.95,2.95-1.44
			c0.96-0.49,1.9-1.02,2.84-1.61c-0.07-0.08-0.11-0.15-0.17-0.19c-0.59-0.47-1.19-0.94-1.78-1.42c-0.18-0.14-0.32-0.15-0.52-0.05
			c-2.46,1.16-4.98,2.16-7.57,2.99c-2.58,0.82-5.19,1.48-7.86,1.95c-1.52,0.27-3.05,0.5-4.58,0.62c-1.19,0.09-2.38,0.18-3.58,0.23
			c-1.23,0.05-2.47,0.08-3.71,0.05c-1.09-0.02-2.18-0.12-3.27-0.2c-1.54-0.1-3.07-0.31-4.59-0.55c-1.82-0.28-3.62-0.66-5.4-1.12
			c-3.8-0.98-7.48-2.29-11.03-3.95c-0.27-0.12-0.44-0.09-0.65,0.09c-0.5,0.42-1.03,0.82-1.54,1.23c-0.11,0.09-0.21,0.18-0.37,0.32
			c2.8,1.72,5.7,3.11,8.71,4.28c-0.1,0.85-3.98,7.35-5.47,9.16c-0.19-0.05-0.4-0.11-0.6-0.17c-3.24-1.04-6.41-2.26-9.5-3.67
			C15.09,76.19,12,74.52,9,72.67c-2.17-1.34-4.28-2.78-6.33-4.3c-0.21-0.16-0.34-0.31-0.35-0.59c-0.02-0.54-0.09-1.08-0.13-1.61
			c-0.08-1.12-0.15-2.23-0.21-3.35c-0.03-0.48-0.02-0.95-0.03-1.43c0-1.6-0.01-3.2,0-4.79c0.01-1.03,0.03-2.06,0.1-3.09
			c0.13-1.74,0.26-3.49,0.47-5.22c0.37-3.17,0.92-6.31,1.65-9.42c0.73-3.14,1.63-6.22,2.7-9.26c0.95-2.68,2.03-5.32,3.22-7.9
			c1.94-4.2,4.19-8.24,6.67-12.14c0.23-0.36,0.47-0.71,0.69-1.07c0.12-0.2,0.28-0.33,0.49-0.42c1.81-0.76,3.62-1.56,5.45-2.29
			c2.27-0.91,4.6-1.65,6.95-2.32c2.7-0.77,5.43-1.39,8.19-1.89c0.33-0.06,0.36-0.04,0.52,0.25c0.89,1.63,1.69,3.3,2.44,5
			c0.16,0.36,0.16,0.36,0.54,0.31c1.22-0.15,2.45-0.32,3.67-0.46c0.76-0.13,1.53-0.18,2.3-0.22c1.3-0.07,2.6-0.11,3.89-0.16
			c0.84-0.03,1.67-0.07,2.51-0.05c1.16,0.02,2.31,0.08,3.47,0.14c1.88,0.1,3.74,0.27,5.6,0.52c0.48,0.06,0.97,0.12,1.45,0.18
			c0.38,0.05,0.37,0.04,0.52-0.29c0.61-1.32,1.22-2.64,1.85-3.95c0.18-0.37,0.38-0.73,0.58-1.09c0.15-0.27,0.19-0.29,0.49-0.23
			c1.05,0.21,2.1,0.4,3.14,0.63c3.25,0.7,6.44,1.6,9.58,2.69c2.7,0.94,5.35,2,7.94,3.22c0.15,0.07,0.3,0.2,0.39,0.35
			c1.5,2.26,2.94,4.57,4.23,6.96c1,1.85,1.96,3.71,2.88,5.6c1.27,2.63,2.37,5.34,3.36,8.09c1.03,2.87,1.91,5.79,2.64,8.76
			c0.59,2.43,1.09,4.87,1.45,7.34c0.22,1.51,0.43,3.03,0.59,4.55c0.13,1.16,0.18,2.33,0.27,3.5c0.11,1.4,0.15,2.8,0.16,4.2
			c0,0.67,0.07,1.34,0.07,2c-0.01,1.11-0.05,2.21-0.1,3.32c-0.04,0.9-0.09,1.8-0.15,2.7c-0.06,0.79-0.14,1.59-0.2,2.38
			c-0.02,0.22-0.1,0.35-0.27,0.47c-1.08,0.77-2.15,1.55-3.24,2.3c-2.41,1.65-4.91,3.17-7.49,4.56C90.2,77,86.67,78.6,83.03,79.94
			c-1.32,0.48-2.65,0.92-3.98,1.38C78.89,81.39,78.72,81.43,78.52,81.49z M45.69,45.4c-0.07-0.65-0.1-1.31-0.2-1.95
			c-0.4-2.59-1.54-4.79-3.54-6.5c-1.94-1.65-4.18-2.39-6.73-2.06c-2.25,0.29-4.07,1.39-5.54,3.08c-1.35,1.55-2.15,3.37-2.51,5.39
			c-0.28,1.58-0.26,3.15,0.11,4.71c0.58,2.5,1.79,4.64,3.85,6.2c2.57,1.95,5.4,2.39,8.41,1.18c2.33-0.94,3.93-2.69,5-4.92
			C45.31,48.92,45.66,47.2,45.69,45.4z M70.49,56.12c1.4-0.01,2.63-0.29,3.78-0.85c1.98-0.97,3.42-2.49,4.41-4.44
			c1.15-2.26,1.5-4.66,1.15-7.16c-0.34-2.45-1.34-4.59-3.14-6.31c-1.92-1.84-4.2-2.73-6.88-2.49c-0.99,0.09-1.92,0.35-2.81,0.78
			c-2.19,1.07-3.71,2.78-4.7,4.98c-0.97,2.17-1.25,4.45-0.86,6.79c0.43,2.57,1.56,4.78,3.54,6.51
			C66.59,55.34,68.47,56.08,70.49,56.12z"
      />
    ),
    src: "/assets/images/socials/discord.svg",
    alt: "discord",
    href: "https://discord.com/invite/B7HUaff6rQ",
  },
  {
    path: (
      <path
        d="M1.93,76.07c11.56,1.13,21.9-1.76,31.1-8.79c-4.47-0.23-8.53-1.59-12.11-4.26
            c-3.58-2.67-6.07-6.15-7.55-10.51c3.2,0.55,6.29,0.48,9.34-0.39c-4.87-1.17-8.95-3.61-12.09-7.52s-4.69-8.39-4.68-13.37
            c1.57,0.58,3.07,1.2,4.61,1.7s3.14,0.7,4.79,0.7c-4.48-3.25-7.48-7.49-8.69-12.89c-1.21-5.38-0.41-10.49,2.3-15.37
            C9.45,5.95,9.9,6.48,10.35,7c3.08,3.53,6.53,6.65,10.34,9.37c4.68,3.35,9.74,5.99,15.17,7.88c4.41,1.53,8.93,2.57,13.58,3.02
            c0.93,0.09,1.86,0.15,2.79,0.24c0.37,0.04,0.45-0.1,0.37-0.43c-0.37-1.65-0.49-3.33-0.45-5.02c0.1-3.53,1.01-6.83,2.77-9.89
            c2.23-3.88,5.37-6.79,9.42-8.7C67.09,2.2,69.97,1.55,73,1.51c3.52-0.05,6.84,0.69,9.98,2.28c2.04,1.04,3.88,2.37,5.47,4.01
            c0.29,0.3,0.56,0.36,0.95,0.28c4.32-0.93,8.4-2.47,12.24-4.66c0.16-0.09,0.32-0.17,0.53-0.22c-1.6,4.91-4.65,8.68-8.96,11.5
            c4.1-0.46,8.01-1.54,11.86-3.22c-0.1,0.17-0.14,0.27-0.2,0.35c-2.73,3.96-6.02,7.39-9.89,10.25c-0.3,0.22-0.42,0.46-0.41,0.83
            c0.14,4.5-0.18,8.97-1.04,13.4c-1.23,6.27-3.34,12.23-6.36,17.87c-2.23,4.16-4.89,8.02-8.01,11.58c-4,4.56-8.58,8.41-13.78,11.52
            c-4.94,2.96-10.23,5.1-15.82,6.46c-3.71,0.9-7.47,1.5-11.28,1.65c-2.39,0.09-4.79,0.17-7.17,0.05
            c-7.27-0.37-14.27-2.02-20.94-4.95c-2.76-1.21-5.41-2.62-7.96-4.22C2.13,76.21,2.06,76.16,1.93,76.07z"
      />
    ),
    src: "/assets/images/socials/twitter.svg",
    alt: "twitter",
    href: "https://twitter.com/PixelTigers/",
  },
  {
    path: (
      <path
        d="M27.91,0A27.91,27.91,0,1,0,55.82,27.91,27.92,27.92,0,0,0,27.91,0ZM21.28,16.94c.29.66.53,1.23.56,1.3a18.21,18.21,0,0,
            1,1.53,5.84,7.24,7.24,0,0,1-1.15,4.07c-.37.58-.77,1.14-.77,1.14H13.38S19.15,20.28,21.28,16.94ZM46.07,33l-.65.31a2.39,2.39,0,0,
            1-.48.25,6.22,6.22,0,0,0-3,2.68,28.71,28.71,0,0,1-2.64,3.93,4.8,4.8,0,0,1-3.79,2H20.08c-4.52,0-8.35-4.34-8.46-8.86v-.82h8.63v.7a3.18,
            3.18,0,0,0,.11.64,2.78,2.78,0,0,0,2.87,2.1H27V32.56H23c-.06-.13,0-.34.21-.63.66-1,1.28-2.11,1.89-3.19,1.48-2.61,1.32-5.33.57-8.1a34,34,
            0,0,0-3-7.18s2.18.47,4.35,1.06V12.85a1.68,1.68,0,0,1,3.35,0v2.78l.43.21A29.15,29.15,0,0,1,35,19.76,18.37,18.37,0,0,1,36.5,22a6,6,0,0,1-.2,
            6.66,22.36,22.36,0,0,1-3.11,3.68,1.24,1.24,0,0,1-.22.18h-2.6v3.31h2.94a3.78,3.78,0,0,0,2.41-1.14c.38-.41,1.32-1.64,1.32-1.64l9-2.45Z"
      />
    ),
    src: "/assets/images/socials/opensea.svg",
    alt: "opensea",
    href: "https://opensea.io/collection/pixeltigers-official",
  },
];
