import { render } from "react-dom"
import App from './App'
import { ThemeProvider } from '@mui/material/styles'
import reportWebVitals from './reportWebVitals'
import theme from "./theme"
import './index.css'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { AccountProvider } from "./Components/MetaMaskUtils";
import { AuthContextProvider } from "./context/AuthContext"

const rootElement = document.getElementById("root");


render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <AccountProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </AccountProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
