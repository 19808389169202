import { db } from '../../config/firebaseClient';
import { doc, setDoc } from "firebase/firestore";

/**
 * Records the uid tied to user's address
 * @param {*} address user wallet address
 * @param {*} uid anonymous login uid
 * @returns 
 */
 const addAddressUID = async (address, uid) => {
    try {
        const userAddressRef = doc(db, `users/${address}/uids/${uid}`);
        await setDoc(userAddressRef, {
            uid: uid
        })
        return true;
    } catch (e) {
        alert("Error recording UID");
        console.log(e);
        return false;
    }
}

export const AuthFn = {
    addAddressUID
}