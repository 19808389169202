import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../MetaMaskUtils";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Card, CardContent, Stack } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ConnectButton from "../Buttons/ConnectButton";
import { backgrounds } from "../MintComponent/mintData";
import * as React from "react";
import { TextField } from "@material-ui/core";

const useStyles = (mobileView) =>
  makeStyles((theme) => ({
    outer: {
      backgroundImage: `url("/assets/images/main/SubPageBg.webp")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingTop: "4.4rem",
      height: `${mobileView ? "80%" : "100%"}`,
      minHeight: `${mobileView ? "140%" : "120%"}`,
      overflow: "hidden",
    },
    header: {
      paddingTop: `${mobileView ? "4rem" : "3rem"}`,
      paddingBottom: "1rem",
      color: "white",
      fontSize: `${mobileView ? "2.3rem" : "3.3rem"}`,
    },
    avatar: {
      minWidth: "80px",
      minHeight: "80px",
      width: "10vw",
      height: "10vw",
    },
    wordColor: {
      color: "white",
    },
    mainSection: {
      backgroundImage: `url(${backgrounds.main})`,
      // backgroundColor:"white",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      minHeight: "370px",
      height: "60vh",
      maxHeight: "500px",
      zIndex: "2",
    },
    containerPic: {
      textAlign: "center",
      height: "40%",
      paddingTop: "2%",
      margin: "2%",
      justifyContent: "center",
    },
    claimableOutline: {
      // position: "absolute",
      // width: "90%",
      // height: `${mobileView ? "23px" : "61.56%"}`,
      border: `2px solid white`,
      borderRadius: "3px",
      borderWidth: "thin",
    },
    breedBtn: {
      ...theme.palette.btnBackground,
      fontSize: `${mobileView ? "0.6rem" : "max(0.6rem,min(0.8rem,0.95vw))"}`,
      width: "100%",
      minHeight: `${mobileView ? "48px" : "50px"}`,
      maxHeight: `${mobileView ? "25px" : "36px"}`,
      border: `max(1px,min(2px,0.2vw)) solid ${theme.palette.highlight}`,
      "&:disabled": {
        border: `max(1px,min(2px,0.2vw)) solid #A9A9A9`,
        color: theme.palette.offWhite,
        backgroundColor: theme.palette.grey_disable,
      },
      "&:hover": {
        backgroundColor: "black",
      },
    },
    breedLabelStyle: {
      position: "relative",
      left: mobileView ? "43%" : "63%",
      transform: "translate(5%, -450%)",
      fontSize: "max(0.7rem,0.9vw)",
      color: theme.palette.offWhite,
      fontFamily: theme.palette.gothamMedFont,
    },
    nonActiveStyling: {
      width: "100%",
      border: "#A9A9A9",
      color: theme.palette.offWhite,
    },
    selectStyling: {
      width: "100%",
      "& .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-root": {
        color: "white",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "yellow",
      },
      "&:hover .MuiInputLabel-root": {
        color: "yellow",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "yellow",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "yellow",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "yellow",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "yellow",
      },
    },
    resize: { fontSize: mobileView ? "14px" : "13px" },
  }));

const MyTigers = ({ mobileView }) => {
  const theme = useTheme();
  const classes = useStyles(mobileView)(theme);
  const {
    header,
    outer,
    wordColor,
    containerPic,
    resize,
    breedBtn,
    breedLabelStyle,
    selectStyling,
    nonActiveStyling,
  } = classes;
  const accountContext = useContext(AccountContext);
  const [tokens, setTokens] = useState([]);
  const [tokenFirst, setTokenFirst] = useState("");
  const [token2, setToken2] = useState("");
  const [breedingCost, setBreedCost] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [breedStatus, setBreedStatus] = useState();
  const backgroundCat = {
    topLogo: "/assets/images/ui/MyTigers/tiger-ears.webp",
  };

  async function updateStats() {
    const updatedTokens = await accountContext.getOwnerTokens();
    const breedPrice = await accountContext.getBreedPrice();
    setBreedCost(parseInt(breedPrice));
    return updatedTokens;
  }

  async function getURL(id) {
    const tokenURL = await accountContext.getTokenURLS(id);
    return tokenURL;
  }

  const setNewImageToken = (newToken, url) => {
    if (url) {
      url = url.replace("http", "https");
      try {
        axios.get(url).then((r) => {
          let link = r.data["image"];
          setTokens((oldArray) => [...oldArray, [newToken, link]]);
        });
      } catch (err) {
        let link = "/assets/images/tigers/Prereveal-anim-draft2.webp";
        setTokens((oldArray) => [...oldArray, [newToken, link]]);
      }
    }
  };

  useEffect(() => {
    accountContext.breedActive
      ? setBreedStatus("IDLE")
      : setBreedStatus("BREED NOT ACTIVE");
  }, [accountContext.breedActive]);

  useEffect(() => {
    const fn = async () => {
      if (accountContext.currentAccount) {
        await updateStats().then(async (r) => {
          if (r) {
            let currTokens = r.map((token) => token._hex);
            for (let i = 0; i < currTokens.length; i++) {
              // should only be one when breeding
              if (!tokens.includes(currTokens[i])) {
                let newToken = parseInt(currTokens[i]);
                let newURL = await getURL(newToken);
                setNewImageToken(newToken, newURL);
              }
            }
          }
        });
      }
    }
    fn();
  }, [accountContext.currentAccount, breedStatus === "BRED"]);

  /* Get user pixel generation rate */
  const [ userRate, setUserRate ] = useState(null);
  useEffect(() => {
      let isMounted = true;
      
      const updateUserRate = async () => {
          const genRate = await accountContext.getPixelGenRate(accountContext.currentAccount);
          setUserRate(genRate);
      }   
      updateUserRate();
      return () => {isMounted = false;}
  }, [accountContext])

  const selectedTokenOne = (event) => {
    setTokenFirst(event.target.value);
  };

  const selectedTokenTwo = (event) => {
    setToken2(event.target.value);
  };

  const startBreeding = async () => {
    if (!tokenFirst || !token2) {
      setBreedStatus("MISSING TOKEN");
    } else if (
      tokenFirst &&
      token2 &&
      accountContext.curPixelBalance &&
      accountContext.curPixelBalance >= breedingCost
    ) {
      setLoading(true);
      setBreedStatus("BREEDING");
      try {
        await accountContext.breedNftHandler(tokenFirst, token2);
        setLoading(true);
        setBreedStatus("BRED");
        setLoading(false);
      } catch (err) {
        setBreedStatus("FAILED");
        setLoading(false);
      }
    } else {
      setBreedStatus("Insufficient pixels");
    }
  };

  const BreedButton = (props) => {
    const { onClick, loading } = props;
    return (
      <Button
        variant="contained"
        onClick={onClick}
        disabled={loading || !accountContext.breedActive}
        className={breedBtn}
      >
        {loading && <CircularProgress size={14} />}
        {!loading && "Breed"}
      </Button>
    );
  };

  const GetBreedLabel = () => {
    if (breedStatus === "BREED NOT ACTIVE") {
      return <Typography variant="body1">Breeding is not active!</Typography>;
    }
    if (breedStatus === "IDLE") {
      return <Typography variant="body1">Select 2 tigers to breed!</Typography>;
    }
    if (breedStatus === "MISSING TOKEN") {
      return <Typography variant="body1">Select a tiger!</Typography>;
    }

    if (breedStatus === "FAILED") {
      return <Typography variant="body1">Breeding Failed</Typography>;
    }
    if (breedStatus === "BREEDING") {
      return <Typography variant="body1">Breeding in progress ...</Typography>;
    }
    if (breedStatus === "BRED") {
      return <Typography variant="body1">Successfully Bred A Cub!</Typography>;
    }
    if (breedStatus === "Insufficient pixels") {
      return <Typography variant="body1">Insufficient Pixels!</Typography>;
    }
    return <div></div>;
  };

  if (!accountContext.currentAccount) {
    return (
      <div className={outer}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: mobileView ? "80vh" : "70vh" }}
        >
          <Grid item xs="auto">
            <Stack spacing={2}>
              <Typography
                variant="h3"
                style={{
                  color: "white",
                  fontFamily: "blockyPixel",
                  marginTop: "-20%",
                }}
              >
                Connect to your wallet!{" "}
              </Typography>
              <div style={{ padding: "5%" }}>
                <ConnectButton />
              </div>
            </Stack>
          </Grid>
        </Grid>
      </div>
    );
  } else if (tokens.length) {
    return (
      <div className={outer}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignContent="center"
            className={header}
          >
            <Typography variant="h2">My Tigers</Typography>
          </Grid>

          <Grid sx={{ flexGrow: 1 }} container spacing={4}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                spacing={5}
                alignItems="center"
              >
                {tokens.map((tuple) => {
                  let url = tuple[1];
                  let token = tuple[0];
                  return (
                    <Grid key={token} item>
                      <Card
                        style={{
                          width: mobileView ? "20vw" : "10vw",
                          height: mobileView ? "26vw" : "13vw",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={url.replace("http", "https")}
                          style={{ minHeight: mobileView ? "70%" : "10vw" }}
                        />
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CardContent
                            style={{
                              transform: mobileView
                                ? "translate(0%, -20%)"
                                : "translate(0%, -10%)",
                            }}
                          >
                            <Typography
                              style={{ fontSize: mobileView ? "3vw" : "1vw" }}
                              component="div"
                            >
                              {parseInt(token)}
                            </Typography>
                          </CardContent>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={5}
              className={wordColor}
              style={{ paddingLeft: "17%", paddingTop: "5%" }}
            >
              {tokens && (
                <Stack spacing={2}>
                  <Typography
                    variant="h2"
                    style={{ fontFamily: "blockyPixel" }}
                  >
                    Breed{" "}
                  </Typography>
                  <div
                    style={{
                      fontFamily: "gothamMedium",
                      fontSize: mobileView ? "0.8rem" : "1rem",
                    }}
                  >
                    Through the efforts of 2 different PixelTigers and 1000
                    $PIXEL, life can be given to a PixelCub. All PixelCubs born
                    through $PIXEL will have their own unique traits, with a
                    maximum of 8888 PixelCubs to be bred.
                  </div>
                </Stack>
              )}
            </Grid>
            <Grid
              container
              item
              xs={7}
              className={wordColor}
              style={{
                paddingLeft: mobileView ? "20%" : "30%",
                color: "#ffc40c",
                paddingTop: "5%",
              }}
            >
              {tokens && (
                <Stack alignItems="flex-end	">
                  <div style={{ fontSize: "1rem" }}>
                    CURRENT $PIXEL BALANCE:{" "}
                  </div>
                  <div
                    style={{
                      paddingTop: "10%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      paddingRight: "20%",
                    }}
                  >
                    {parseFloat(accountContext.curPixelBalance).toFixed(2)}
                  </div>
                  <div style={{ fontSize: "1rem", paddingTop: "20px"}}>
                    $PIXEL GENERATION PER DAY:
                  </div>
                  <div 
                    style={{
                      paddingTop: "10%",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      paddingRight: "20%",
                    }}>
                    {`${(userRate !== null) ? parseInt(userRate, 10) : '...'}`}
                  </div>
                </Stack>
              )}
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
              sx={{
                height: "100%",
              }}
            >
              <Grid container item className={containerPic}>
                <img
                  src={backgroundCat.topLogo}
                  alt=""
                  style={{
                    position: "absolute",
                    height: "auto",
                    maxHeight: mobileView ? "60px" : "80px",
                    width: mobileView ? "10%" : "20%",
                    minWidth: mobileView ? "200px" : "330px",
                    backgroundSize: "auto",
                    left: "50%",
                    transform: mobileView
                      ? "translate(-54%, -80%)"
                      : "translate(-50%, -90%)",
                  }}
                />
                <img
                  src={backgrounds.main}
                  style={{
                    position: "relative",
                    height: "20%",
                    maxHeight: "200px",
                    minHeight: "150px",
                    width: "70%",
                    minWidth: mobileView ? "390px" : "330px",
                    backgroundSize: "auto",
                  }}
                />
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  style={{
                    position: "relative",
                    width: "60%",
                    maxHeight: "71vw",
                    left: mobileView ? "30%" : "30%",
                    top: mobileView ? "-20%" : "2%",
                    transform: mobileView
                      ? "translate(-54%, -250%)"
                      : "translate(-50%, -250%)",
                  }}
                >
                  <Grid container item xs={4} style={{ textAlign: "center" }}>
                    <TextField
                      className={
                        accountContext.breedActive
                          ? selectStyling
                          : nonActiveStyling
                      }
                      value={tokenFirst}
                      onChange={selectedTokenOne}
                      variant="outlined"
                      label="Tiger 1"
                      disabled={!accountContext.breedActive}
                      InputProps={{
                        classes: {
                          root: resize,
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: mobileView ? "2vw" : "15px" },
                      }} // font size of input label
                      select
                    >
                      {tokens &&
                        tokens.map((tuple) => {
                          let x = tuple[0];
                          if (x != token2 && parseInt(x) < 4444) {
                            return (
                              <MenuItem key={x} value={x}>
                                {parseInt(x)}
                              </MenuItem>
                            );
                          }
                        })}
                    </TextField>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={1}
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    +
                  </Grid>
                  <Grid container item xs={4} style={{ textAlign: "center" }}>
                    <TextField
                      className={
                        accountContext.breedActive
                          ? selectStyling
                          : nonActiveStyling
                      }
                      value={token2}
                      onChange={selectedTokenTwo}
                      variant="outlined"
                      label="Tiger 2"
                      disabled={!accountContext.breedActive}
                      InputProps={{
                        classes: {
                          root: resize,
                        },
                      }}
                      InputLabelProps={{
                        style: { fontSize: mobileView ? "2vw" : "15px" },
                      }} // font size of input label
                      select
                    >
                      {tokens &&
                        tokens.map((tuple) => {
                          let x = tuple[0];
                          if (x !== tokenFirst && parseInt(x) < 4444) {
                            return (
                              <MenuItem key={x} value={x}>
                                {parseInt(x)}
                              </MenuItem>
                            );
                          } else {
                            return <></>
                          }
                        })}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ textAlign: "center", color: "white" }}
                  >
                    =
                  </Grid>
                  <Grid container item xs={2}>
                    <BreedButton onClick={startBreeding} loading={loading} />
                  </Grid>
                </Grid>
                <Grid container item className={breedLabelStyle}>
                  <GetBreedLabel />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div className={outer}>
        <Grid
          container
          justify="center"
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "70vh" }}
          className={wordColor}
        >
          <Typography variant="h2">MyTigers </Typography>
          <br />
          <Typography
            style={{
              fontSize: mobileView ? "15px" : "20px",
              fontFamily: "roboto-slab",
              paddingBottom: "1%",
            }}
          >
            You do not own any PixelTigers, Adopt one on{" "}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              target="_blank"
              href="https://opensea.io/collection/pixeltigers-official"
            >
              <Typography variant="h2">OpenSea</Typography>
            </Button>
          </Stack>
        </Grid>
      </div>
    );
  }
};

export default MyTigers;
