import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import Mint from './MintComponent/Mint';

import ScrollToTop from './Utils/ScrollToTop';


function MintPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <Mint mobileView={mobileView.mobileView}/>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default MintPage;
