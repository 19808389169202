export const title = "Enter the world of PixelTigers ..."
export const images = [
    {
        src:"/assets/images/carouselTigers/1.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/12.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/2.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/gojo.gif",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/3.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/4.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/9.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/7.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/irwin.gif",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/13.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/11.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/6.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/10.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/atlantis.gif",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/5.webp",
        alt:"Where did the tiger go :("
    },
    {
        src:"/assets/images/carouselTigers/8.webp",
        alt:"Where did the tiger go :("
    },
]

// export const introText = {
//     desc: "The Jungle, a place with a single rule: survival of the fittest. " +
//         "PixelTigers have long existed in this special realm, ever since the " +
//         "world dived into the blockchain and never looked back. Here, they " + 
//         "discovered that living things have no need for air, food, or water to " +
//         "survive, but only one resource, pixels. Brimming with energy, they were " +
//         "the only source of survival. PixelTigers soon realised they further needed " +
//         "pixels to breed and expand, yet some choose to blow it all at the Raffles… Come and collect pixels with us in The Jungle - and as for what choices you make, it's up to you."
// }
