import { useContext } from 'react';
import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import Shop from './ShopComponent/Shop';

import ScrollToTop from './Utils/ScrollToTop';
import { ShopContextProvider } from '../context/ShopContext';
import { AccountContext } from './MetaMaskUtils';

function ShopPage ({ mobileView }) {
  const accountContext = useContext(AccountContext);
  
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <ShopContextProvider userAddress={accountContext.currentAccount}>
            <Shop mobileView={mobileView.mobileView}/>
          </ShopContextProvider>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default ShopPage;
