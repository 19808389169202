import { useState, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = () => makeStyles(theme => ({
        CustomScrollBar: {
            overflowY: "scroll",
    
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
                "-webkit-box-shadow": "inset 0 0 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: theme.palette.offWhite,
            },

            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#f5f5f5",
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "5px",
            },

            scrollbarWidth: "thin",
            scrollbarColor: `${theme.palette.primary.dark} ${theme.palette.offWhite}`,
        },
        FilterText: {
            fontSize: "12px",
            marginBottom: "10px",
            backgroundColor: "inherit",
            width: "90%",
            marginLeft: "14px",
            "& .MuiOutlinedInput-input":{ // style of balance num overall
                padding: "6px 10px",
                fontSize: "12px",
                fontFamily: theme.palette.gothamMedFont,
                color: theme.palette.primary.dark,
            },
        },
        AttributeBtn: {
            width: "100%",
        },
        FilterOptionWrapper: {
            width: "100%",
            height: "100%",
            padding: "0px 5px",
        },
        FilterSelect: {
        },
        SelectOutline: {
            width: "8px",
            backgroundColor: theme.palette.grey_disable,
            aspectRatio: "1",
        },
        Selected: {
            backgroundColor: theme.palette.highlight,
        },
        FilterInfo: {
            color: theme.palette.primary.dark,
            fontFamily: theme.palette.pixelFont,
            fontSize: "12px",
        }
    })
)

/* Return filters for all values of the attribute */
const AttributeFilters = ({attr, values, toggleFilter, isFilterSet}) => {

    const theme = useTheme();
    const classes =  useStyles()(theme);

    // init value array: [attribute, value, count]
    const getInitVals = (values) => Object.keys(values).map((value) => [attr, value, values[value]]);
    const [initVals, setInitVals] = useState(getInitVals(values));
    
    // Helpers for display
    const NUM_TO_SHOW = 9;
    // Add search if greater than NUM_TO_SHOW items;
    const inclSearch = () => initVals.length > NUM_TO_SHOW;
    const targetHeight = () => Math.min(initVals.length, NUM_TO_SHOW) * 25;  

    // Filter for attributes
    const [filterText, setFilterText] = useState("");
    const [filteredVals, setFilteredVals] = useState(getInitVals(values));
    const filterValues = (filterText) => {
        let filtered = [...initVals];
        if (filterText.length > 0) {
            filtered = filtered.filter((x) => {
                return x[1].toLowerCase().includes(filterText.toLowerCase());  
            })
        }
        // sort by count. I.e. rarity asc
        filtered.sort((a,b) => b[2] - a[2]);
        setFilteredVals(filtered);
    }
    useEffect(() => { // filter if text changes
        filterValues(filterText);
    }, [filterText])
    const updateFilterText = (event) => setFilterText(event.target.value);

    
    /* Return filter for a single attribute: value combination */
    const valueFilters = ({data, index, style}) => {
        const [attr, value, count] = data[index];
        return (
            <Grid container item style={style} >
                <Button onClick={() => toggleFilter(attr, value)}
                    className={classes.AttributeBtn}>
                    <Grid container item xs={12} className={classes.FilterOptionWrapper}>
                        <Grid container item xs={1.2} className={classes.FilterSelect}
                            justifyContent="center" alignItems="center">
                            <Grid item className={`${classes.SelectOutline} ${isFilterSet(attr, value) && classes.Selected}`}>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12-1.2} className={classes.FilterInfo} alignItems="center" justifyContent="space-between">
                            <span className={classes.Value}>{value.toUpperCase()}</span>
                            <span className={classes.Count}>{count}</span>
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        )
    }

    return (
        <Grid container item xs={12} sx={{height:`${targetHeight()}px`}}
            alignItems="flex-start" 
            flexDirection="column">
            { inclSearch() && 
                <TextField className={classes.FilterText} variant="outlined"
                    value={filterText} onChange={(e) => updateFilterText(e)} placeholder="Attribute..."/>
            }
            <AutoSizer>
                {({height, width}) => (
                    <FixedSizeList
                        className={`${classes.CustomScrollBar}`}
                        height={inclSearch() ? height - 40 : height}
                        width={width}
                        itemSize={25}
                        itemCount={filteredVals.length}
                        overscanCount={5}
                        itemData={filteredVals}>
                        {valueFilters}
                    </FixedSizeList>
                    )
                }
            </AutoSizer>
        </Grid>
    )
}

export default AttributeFilters;