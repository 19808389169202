import Header from './HeaderComponent/Header';
import Footer from './FooterComponent/Footer';
import Pixel from './PixelComponent/Pixel';

import ScrollToTop from './Utils/ScrollToTop';

function TokenPage({ mobileView }) {
  return (
    <ScrollToTop>
      <div>
          <Header mobileView={mobileView.headerMobileView}/>
          <Pixel mobileView={mobileView.mobileView}/>
          <Footer />
      </div>
    </ScrollToTop>
  );
}

export default TokenPage;
