import { useState, createContext } from "react";

import contractERC721 from "../contracts/PixelTigers.json";
import contractERC20 from "../contracts/PixelERC20.json";
import { ethers } from "ethers";
import { constants } from "./constants";

import { addMainRaffle, addSubRaffle, setOwner, setAddrNumTokens } from "./FirebaseUtils";

const ErrCode = {
  UserDeniedTransaction: 4001,
  InsufficientFunds: "INSUFFICIENT_FUNDS",
  InvalidPurchaseAmount: "UNPREDICTABLE_GAS_LIMIT",
};

const SITE_ACTIVE = true; // For testing, set to true. if false, disables most metamask functions
const TESTING = false;

// Helper to verify transaction
export const getTransaction = async (txnHash) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const txn = await provider.getTransaction(txnHash);
      return txn;
    }
  } catch (err) {
    alert(`Error occured: ${err}`);
  }
  return null;
}
export const numFromGwei = (hexVal) => {
  const bigNumVal = ethers.BigNumber.from("0x"+hexVal);
  return ethers.utils.formatUnits(bigNumVal, "gwei");
}

// helper to get contract
export const _getContract = (contractAddress, abi) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(contractAddress, abi, signer);

      return nftContract;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

// wait function for testing
// const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const errorHandler = (err) => {
  if (err.code === ErrCode.UserDeniedTransaction) {
    alert("Transaction failed: User Denied Transaction");
  }
  if (err.code === ErrCode.InsufficientFunds) {
    alert("Transaction failed: Insufficient Funds");
  }
  if (err.code === ErrCode.InvalidPurchaseAmount) {
    alert("Transaction failed: Invalid Purchase Amount");
  }
};

const AccountContext = createContext();
const AccountProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [nftContractERC721, setNftContractERC721] = useState(null);
  const [nftContractERC20, setNftContractERC20] = useState(null);
  const [network, setNetwork] = useState(null);
  const targetNetwork = TESTING ? "4" : "1"; // For testing, use Rinkeby
  const networkMap = {
    1: "Ethereum Main Network (Mainnet)",
    3: "Ropsten Test Network",
    4: "Rinkeby Test Network",
    5: "Goerli Test Network",
    42: "Kovan Test Network",
  };
  // To check whether function should be called
  const isValid = () => {
    if (!SITE_ACTIVE) {
      return false;
    }
    if (!currentAccount) {
      return false;
    }
    if (!isValidNetwork()) {
      return false;
    }
    return true;
  };
  const isValidNetwork = () => {
    if (network !== targetNetwork) {
      return false;
    }
    return true;
  };
  // Set contract address
  const contractAddressERC721 = constants.contractAddressERC721;
  const contractAddressERC20 = constants.contractAddressERC20;

  // Handler to consolidate all user values
  // call update functions here, and wherever the value might be updated. E.g.  updated after claim/breed
  const updateUserVals = (userAddress, networkId) => {
    updateCurPixelBalance(userAddress, networkId);
    updateTotalSupply(userAddress, networkId);
    updateMintActive(userAddress, networkId);
    updatePresaleActive(userAddress, networkId);
    updateNumOwnedTigers(userAddress, networkId);
    updateMainRaffleActive(userAddress, networkId);
    updateSubRaffleActive(userAddress, networkId);
    updateBreedActive(userAddress, networkId);
    updatePresaleAmount(userAddress, networkId);
    updateNumOwnedOG(userAddress, networkId);
  };

  // Vars unique to userAddress
  // user pixel balance
  const [curPixelBalance, setCurPixelBalance] = useState(null);
  const updateCurPixelBalance = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setCurPixelBalance(null);
      return;
    }

    // getContract
    const contract = getERC20Contract();

    // get balance
    let balance = await contract.balanceOf(userAddress);
    balance = ethers.utils.formatUnits(balance, 18);

    setCurPixelBalance(balance);
  };

  // total supply minted
  const [totalSupply, setTotalSupply] = useState(null);
  const updateTotalSupply = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setTotalSupply(null);
      return;
    }

    // retrieve contract
    const contract = getERC721Contract();
    // get amount
    let supply = await contract.totalSupply();
    let numBabies = await contract.babyCount();
    let genesisSupply = supply - numBabies;
    setTotalSupply(genesisSupply);
  };


  // mint active
  const [mintActive, setMintActive] = useState(false);
  const updateMintActive = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setMintActive(false);
      return;
    }
    const contract = getERC721Contract();

    const active = await contract.publicSaleActive();
    setMintActive(active);
  };

  // presale active
  const [presaleActive, setPresaleActive] = useState(false);
  const updatePresaleActive = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setPresaleActive(false);
      return;
    }
    const contract = getERC721Contract();

    const active = await contract.presaleActive();
    setPresaleActive(active);
  };

  // Number of tigers user owns
  const [numOwnedTigers, setNumOwnedTigers] = useState(-1);
  const updateNumOwnedTigers = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setNumOwnedTigers(-1);
      return;
    }

    const contract = getERC721Contract();

    var numTigers = await contract.balanceOf(userAddress);
    numTigers = parseFloat(numTigers);
    setNumOwnedTigers(numTigers);
  };

  // Num owned OG
  const [ownerTokens, setOwnerTokens] = useState([]);
  const [numOwnedOG, setNumOwnedOG] = useState(-1);
  const updateNumOwnedOG = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setNumOwnedOG(-1);
      return;
    }

    const contract = getERC721Contract();

    var tokenIds = await contract.tokenIdsOfOwner(userAddress);
    setOwnerTokens(tokenIds.map(x => parseFloat(x)));

    var numOG = 0
    for (var i = 0; i < tokenIds.length; i++) {
      const id = parseFloat(tokenIds[i]);
      if ( id < 1111) {
        numOG += 1
      }
    }
    setNumOwnedOG(numOG);
  }
  
  // How many mints for presale
  const [presaleAmount, setPresaleAmount] = useState(-1);
  const updatePresaleAmount = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setPresaleAmount(-1);
      return;
    }
    const contract = getERC721Contract();

    const presaleAmt = await contract.presaleWhitelist(userAddress);
    setPresaleAmount(presaleAmt.toNumber());
  };

  // mainRaffle active
  const [mainRaffleActive, setMainRaffleActive] = useState(false);
  const updateMainRaffleActive = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setMainRaffleActive(false);
      return;
    }
    const contract = getERC20Contract();

    const active = await contract.mainRaffleActive();
    setMainRaffleActive(active);
  };

  // subRaffle active
  const [subRaffleActive, setSubRaffleActive] = useState(false);
  const updateSubRaffleActive = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setSubRaffleActive(false);
      return;
    }
    const contract = getERC20Contract();

    const active = await contract.subRaffleActive();
    setSubRaffleActive(active);
  };

  // Breed active
  const [breedActive, setBreedActive] = useState(false);
  const updateBreedActive = async (userAddress, networkId) => {
    if (userAddress === null || networkId !== targetNetwork || !SITE_ACTIVE) {
      setBreedActive(false);
      return;
    }
    const contract = getERC721Contract();

    const active = await contract.breedActive();
    setBreedActive(active);
  };

  // Handler to connect to wallet and set authorized account
  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setCurrentAccount(accounts[0]);

      // account added, update user vals
      updateUserVals(accounts[0]);
    } catch (err) {
      updateUserVals(null);
      console.log(err);
    }
  };

  // Handler to disconnect wallet
  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      // console.log('Please connect to MetaMask.');
      setCurrentAccount(null);
      updateUserVals(null, network);
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);

      // Acc changed, update user vals
      updateUserVals(accounts[0], network);
    }
  };

  const updateNetwork = (networkId) => {
    networkId = `${networkId}`;
    // if (networkId !== "4") {
    //   console.log(`Connected to invalid network: ${networkMap[networkId]}`);
    // }
    setNetwork(networkId);
    updateUserVals(currentAccount, networkId);
    // console.log(`updateNetwork: ${networkId == targetNetwork}`);
  };

  // Check current network
  const checkNetwork = () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }
    updateNetwork(window.ethereum.networkVersion);
    return window.ethereum.networkVersion;
  };

  const handleNetworkChanged = (networkId) => {
    updateNetwork(Number(networkId));
  };

  // On app start, check if there is wallet connected.
  // If connected and has authorized account, select account
  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!");
    }

    // check account
    const accounts = await ethereum.request({ method: "eth_accounts" });
    // check network
    const networkId = checkNetwork();
    if (accounts.length !== 0) {
      const account = accounts[0];
      // console.log("Found an authorized account: ", accountf);
      setCurrentAccount(account);
      // account added, update user vals
      updateUserVals(account, networkId);
    } else {
      updateUserVals(null, networkId);
    }
  };

  /* Helper Utils to get contract */
  const getERC721Contract = () => {
    if (nftContractERC721) {
      return nftContractERC721;
    }

    const abiERC721 = contractERC721.abi;
    const contract = _getContract(contractAddressERC721, abiERC721);
    if (!contract) {
      console.log("Error obtaining contract");
      return;
    }

    setNftContractERC721(contract);
    return contract;
  };
  const getERC20Contract = () => {
    if (nftContractERC20) {
      return nftContractERC20;
    }

    const abiERC20 = contractERC20.abi;
    const contract = _getContract(contractAddressERC20, abiERC20);
    if (!contract) {
      console.log("Error obtaining contract");
      return;
    }
    setNftContractERC20(contract);
    return contract;
  };

  /* HELPERS FOR ERC721 FUNCTIONS */
  //Get current tokens of owner
  const getOwnerTokens = async () => {
    if (!isValid()) {
      return [];
    }

    // retrieve contract
    const contract = getERC721Contract();

    const tokens = await contract.tokenIdsOfOwner(currentAccount);
    return tokens;
  };

  
  // Get token URL from token id
  async function getTokenURL(id) {
    // retrieve contract
    const contract = getERC721Contract();
    // get token url
    const tokenURL = await contract.tokenURI(id);
    return tokenURL;
  }

  const getTokenURLS = async (id) => {
    const URLS = await getTokenURL(id);
    return URLS;
  };

  const getBreedPrice = async () => {
    try {
      // Retrieve contract
      const contract = getERC721Contract();
      let price = await contract.BREED_PRICE();
      price = ethers.utils.formatUnits(price, 18);
      return price;
    } catch (e) {
      return;
    }
  };

  const breedNftHandler = async (token1, token2) => {
    // Retrieve contract
    const contract = getERC721Contract();
    // Breed pixeltigers!
    try {
      let nftbreed = await contract.breed(token1, token2);
      await nftbreed.wait();
      console.log("DONE BREEDING!");
      updateCurPixelBalance(currentAccount, network);
    } catch (err) {
      throw new Error("Breeding Failed");
      // console.log(err.code);
      // errorHandler(err);
    }
  };

  /* Handler to mint NFT from ERC721 Contract */

  const mintNft = async (numMint, statusHandler) => {
    // Retrieve contract
    const contract = getERC721Contract();
    try {
      let nftTxn = await contract.mint(numMint, {
        value: ethers.utils.parseEther(`${0.069 * numMint}`),
      });
      statusHandler("MINTING", "");
      await nftTxn.wait();

      const transactionUrl = `https://etherscan.io/tx/${nftTxn.hash}`;
      statusHandler("MINTED", transactionUrl);

      // update current total supply
      updateTotalSupply(currentAccount, network);
      // update owner owned
      updateNumOwnedTigers(currentAccount, network);
      return;
    } catch (err) {
      // console.log(err.code);
      errorHandler(err);
      statusHandler("FAILED", "Transaction Failed");
    }
  };

  const presaleMintNft = async (numMint, statusHandler) => {
    // Retrieve contract
    const contract = getERC721Contract();
    try {
      let nftTxn = await contract.presaleMint(numMint, {
        value: ethers.utils.parseEther(`${0.069 * numMint}`),
      });
      statusHandler("MINTING", "");
      await nftTxn.wait();

      const transactionUrl = `https://etherscan.io/tx/${nftTxn.hash}`;
      statusHandler("MINTED", transactionUrl);

      // update current total supply
      updateTotalSupply(currentAccount, network);
      // update tigers owned
      updateNumOwnedTigers(currentAccount, network);
      // update presale left
      updatePresaleAmount(currentAccount, network);
      return;
    } catch (err) {
      // console.log(err.code);
      errorHandler(err);
      statusHandler("FAILED", "Transaction Failed");
    }
  };

  /* Handler to mint NFT from ERC721 Contract */
  /* assert numTickets check at user input field */
  const mintNftHandler = async (numMint, statusHandler) => {
    // Mint pixeltigers!

    // Check normal mint
    if (mintActive) {
      mintNft(numMint, statusHandler);
      return;
    }

    // Check Presale
    else if (presaleActive) {
      // Check if user is whitelisted
      if (presaleAmount <= 0) {
        // early exit if not registered for presale
        alert("this address is not authorized for presale");
        return;
      }
      if (numMint > presaleAmount) {
        alert("can't mint more than reserved!");
        return;
      }

      presaleMintNft(numMint, statusHandler);
      return;
    }
  };

  // Get all owner addresses 
  const takeSnapshot = async () => {
    console.log("fetching owners of tigers");
    for (let i = 0; i < totalSupply; i++) {  // [0 ... totalSupply]
      getOwner(i, true);
    }

    // console.log("fetching owners of cubs");
    for (let i = 7860; i < 13332; i ++) { // [4444 .... 13331]
      getOwner(i, false);
    }
  }

  const getTokenData = async (address) => {
    const pixelContract = getERC20Contract();

    try {
      // get num claimable tokens
      let claimableTokens = await pixelContract.totalTokensClaimable(address);
      claimableTokens = ethers.utils.formatUnits(claimableTokens, 18);

      // get current num tokens
      let tokenBalance = await pixelContract.balanceOf(address);
      tokenBalance = ethers.utils.formatUnits(tokenBalance, 18);

      setAddrNumTokens(address, claimableTokens, tokenBalance);
    } catch (err) {
      return;
    }
  }

  const getOwner = async (id, withTokenData) => {
    const tigerContract = getERC721Contract();

    try {
      let owner = await tigerContract.ownerOf(id);
      console.log(`${id} fetched`);
      setOwner(id, owner);

      if (withTokenData) {
        console.log("getting token data");
        getTokenData(owner);
      }
    } catch (err) {
      return;
    }
  }

  /* Read total tokens claimable from ERC20 contract */
  const getTotalTokensClaimable = async () => {
    if (!isValid()) {
      return 0.0;
    }
    // retrieve contract
    const contract = getERC20Contract();

    // get tokens
    let tokens = await contract.totalTokensClaimable(currentAccount);
    tokens = ethers.utils.formatUnits(tokens, 18);

    return tokens;
  };

  /* Claim reward function called from ERC20 contract */
  const claimPixel = async (setClaimStatus) => {
    // retrieve contract
    const contract = getERC20Contract();

    try {
      // Call the function
      // var options = {gasLimit: 200000};
      let nftTxn = await contract.claimReward(); //options);

      // console.log("claiming pixels");
      setClaimStatus("CLAIMING");
      await nftTxn.wait();
      // console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
      setClaimStatus("CLAIMED");
      // const transactionUrl = `https://etherscan.io/tx/${nftTxn.hash}`;

      // update current balance
      updateCurPixelBalance(currentAccount, network);
    } catch (err) {
      alert(err.message);
      setClaimStatus("FAILED");
    }
  };

  /* Buy Shop Item with pixels */
  const buyShopItemPixels = async (price) => {
    // retrieve contract
    const contract = getERC20Contract();

    try {
      // call function
      const numTickets = price / 10;
      let nftTxn = await contract.enterSubRaffle(numTickets);
      await nftTxn.wait();
      return nftTxn;
    } catch (err) {
      if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
        return "Insufficient $PIXEL balance";
      }
      return err.message;
    }
  }

  /* Enter main raffle from ERC20 contract */
  /* Unable to test at the moment, no tigers */
  const enterMainRaffle = async (numTickets, setRaffleStatus) => {
    // retrieve contract
    const contract = getERC20Contract();

    try {
      // call function
      let nftTxn = await contract.enterMainRaffle(numTickets);
      // console.log("entering main raffle");
      setRaffleStatus("ENTERING");
      await nftTxn.wait();
      // console.log(`Transaction completed, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
      setRaffleStatus("ENTERED");

      // Log in db
      addMainRaffle(currentAccount, numTickets);
      // const transactionUrl = `https://etherscan.io/tx/${nftTxn.hash}`;
    } catch (err) {
      alert(err.message);
      setRaffleStatus("FAILED");
    }
  };

  /* Enter sub raffle from ERC20 contract */
  /* Unable to test at the moment, no tigers */
  const enterSubRaffle = async (numTickets, setRaffleStatus) => {
    // retrieve contract
    const contract = getERC20Contract();

    try {
      // call function
      let nftTxn = await contract.enterSubRaffle(numTickets);
      // console.log("entering main raffle");
      setRaffleStatus("ENTERING");
      await nftTxn.wait();
      // console.log(`Transaction completed, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
      setRaffleStatus("ENTERED");

      // Log in db
      addSubRaffle(currentAccount, numTickets);
      // const transactionUrl = `https://etherscan.io/tx/${nftTxn.hash}`;
    } catch (err) {
      alert(err.message);
      setRaffleStatus("FAILED");
    }
  };

  /* Read eth balance from Metamask wallet address */
  const getEthBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let balance = await provider.getBalance(currentAccount);
    balance = ethers.utils.formatEther(balance);

    return balance;
  };

  const getPixelGenRate = async (address) => {
    // retrieve contract
    const contract = getERC20Contract();

    if (address === null) {
      return null;
    }
    try {
      // Call the function
      let pixelGenRate = await contract.userRate(address);
      return ethers.utils.formatUnits(pixelGenRate, 18);
    } catch (err) {
      alert(err.message);
    }
    return null;
  }

  return (
    <AccountContext.Provider
      value={{
        currentAccount,
        network,
        targetNetwork,
        networkMap,
        isValid,
        isValidNetwork,
        contractAddressERC20,
        contractAddressERC721,
        checkWalletIsConnected,
        connectWalletHandler,
        getBreedPrice,
        handleAccountsChanged,
        handleNetworkChanged,
        getEthBalance,
        getOwnerTokens,
        getTokenURLS,
        mintNftHandler,
        breedNftHandler,
        enterMainRaffle,
        enterSubRaffle,
        getTotalTokensClaimable,
        claimPixel,
        buyShopItemPixels,
        curPixelBalance,
        totalSupply,
        mintActive,
        numOwnedTigers,
        presaleActive,
        presaleAmount,
        mainRaffleActive,
        subRaffleActive,
        breedActive,
        ownerTokens,
        takeSnapshot,
        numOwnedOG,
        getPixelGenRate
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { AccountProvider, AccountContext };
