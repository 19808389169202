import { useState, useContext, useEffect } from "react";

import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

import { HashLink } from 'react-router-hash-link';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { headersData, pixellogo, socialsData, connectData } from './headerData';
import { AccountContext } from '../MetaMaskUtils';

// Custom styles for header
const useStyles = makeStyles(theme => ({
    header: {
        "@media (max-width: 900px)": {
        },
    },
    logo: {
        marginBottom: "0.2rem",
        paddingLeft: "1.5rem",
        width:"20%",
    },
    menuButton: {
        paddingTop:"10px",
        paddingBottom:"10px",
        maxWidth: "130px",
        // minWidth: "120px",
        // maxWidth: "120px",
    },
    connectButton: {
        fontSize: "0.8rem",
        height: "2rem",
        width: "10vw",
        minWidth: "90px",
        maxWidth: "150px",
        color: 'white',
        fontFamily: "gothamMedium",
    },
    socialButton: {
        height: "1.4rem",
        margin: "0.2em",
        paddingLeft: "0.15rem",
        paddingRight: "0.15rem",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "80px !important",
    },
    social: {
        float: "left",
        paddingLeft: "0.5rem",
        paddingRight: "0.2rem",
    },
    socialSeg: {
        width: "45%"
    },
    menuLabel: {
        color: "white",
        fontFamily: "blockyPixel",
        fontSize: `max(1.1rem,min(1.35rem,1.6vw))`,
        '&:hover': {
            color: theme.palette.highlight,
        }
    },
}, {index: 1}));

const Header = ({ mobileView }) => {
    const theme = useTheme();
    const accountContext = useContext(AccountContext);
    const { header, logo, toolbar, menuButton, connectButton, social,
            socialSeg, menuSeg} = useStyles(theme);
    const [state, setState] = useState({
        drawerOpen: false,
    })

    const { drawerOpen } = state;

    const [ invalidNetwork, setInvalidNetwork ] = useState(false);

    useEffect(() => {
        setInvalidNetwork((accountContext.currentAccount && !accountContext.isValidNetwork()));
    }, [accountContext.network, accountContext.currentAccount])

    // Display Header
    const displayDesktop = () => {
        return (
            <Toolbar classes={{ root: toolbar }} style={{backgroundColor:'black'}}>
                <div className={logo}>
                    {pixelTigerLogo}
                </div>
                <Grid container justifyContent="center" alignItems="center"
                    >
                    <Grid container item xs={8} md={9.2} lg={8.8} justifyContent="flex-end" className={menuSeg}>
                        <Grid item xs={12} lg={11} xl={10} container justifyContent="space-evenly">
                            {getMenuButtons()}
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} md={2.8} lg={3.2} direction="row" justifyContent="center" alignItems="center">
                        <Grid container item xs={12} className={socialSeg} justifyContent="center" alignItems="center">
                            <Grid container item xs={6} md={8.5} lg={6} className={social} justifyContent="flex-end" alignItems="center">
                                {getSocialButtons()}
                            </Grid>
                            <Grid container item xs={6} md={3.5} lg={6} className={social} justifyContent="center" alignItems="center">
                                {getConnectButton()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        );
    };
    const displayMobile = () => {
        const handleDrawerOpen = () => {
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        }
        const handleDrawerClose = () => {
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        }
        return (
            <Toolbar classes={{ root: toolbar }} sx={{backgroundColor:'black'}}>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                    >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: 'black'
                        }
                    }}
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div>{getMenuButtons()}</div>
                    <div>{getSocialButtons()}</div>
                    <div>{getConnectButton()}</div>
                </Drawer>
                <div>{pixelTigerLogo}</div>
            </Toolbar>
        )
    }

    // Logo
    const pixelTigerLogo = (
        <HashLink to="/#main" style={{textDecoration:"none"}} smooth>
            <div>
                <img src={pixellogo.src} alt={pixellogo.alt}/>
            </div>
        </HashLink>
    )

    // Menu Buttons
    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            var active = true;
            // if (label === "Mint") {
            //     if (!accountContext.presaleActive && !accountContext.mintActive) {
            //         active = false;
            //     }
            // }
            if ([].includes(label)) {
                active = false;
            }
            var path = '/' + href;
            // add pathlink to about and faq for mobile
            if (mobileView) {
                if (['#intro', '#faqs'].includes(href)) {
                    path = '/pathLink' + href;
                }
            }

            return (
                <HashLink to={path} style={{textDecoration:"none"}} key={label}
                    scroll={el => el.scrollIntoView({behavior:'smooth', block: 'end'})}>
                    { mobileView ?
                        <MenuItem
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                key: label,
                            }}
                        >
                            <Typography variant="h6" align="center" 
                                sx={{
                                    color: `${active ? 'white' : 'gray'}`,
                                    fontFamily: "blockyPixel",
                                    fontSize: `max(1.1rem,min(1.35rem,1.6vw))`,
                                        '&:hover': {
                                            color: `${active ? theme.palette.highlight : 'gray'}`,
                                        }} }
                                >
                                {label}
                                {!active && <span 
                                                style={{
                                                    fontSize:"0.8rem",
                                                    color: `gray`,
                                                }}><br />Inactive</span>}
                            </Typography>
                        </MenuItem>
                        :
                        <Box display="flex" justifyContent='center' alignItems="center"
                            sx={{
                                height: "100%",
                            }}>
                            <Button
                                {...{
                                    key: label,
                                    color: "inherit",
                                }}
                                className={menuButton}
                            >
                                <Typography variant="h6" sx={{
                                    color: `${active ? 'white' : 'gray'}`,
                                    fontFamily: "blockyPixel",
                                    fontSize: `max(1.1rem,min(1.35rem,1.6vw))`,
                                    '&:hover': {
                                        color: `${active ? theme.palette.highlight : 'gray'}`,
                                    }}} >
                                    {label}
                                    {!active && <span 
                                                    style={{
                                                        fontSize:"0.8rem",
                                                        color: `gray`
                                                    }}><br />Inactive</span>}
                                </Typography>
                            </Button>
                        </Box>
                    }
                </HashLink>
            );
        });
    }
    // Social Buttons
    const getSocialButtons = () => {
        return socialsData.map(({ src, href, alt }) => {

            const socialContent = (
                <a href={href} key={src} target="_blank" rel="noreferrer">
                    <img style={{
                        height: "1.4rem",
                        margin: "0.2em",
                        paddingLeft: "0.15rem",
                        paddingRight: "0.15rem",}} src={src} alt={alt} ></img>
                </a>
            );

            if (mobileView) {
                return (
                    <MenuItem key={src}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                        {socialContent}
                    </MenuItem>
                )
            }
            else {
                return socialContent;
            }
        });
    }
    // Connect Button
    const getConnectButton = () => {
        const connectContent = (
            <Button
                {...{
                    color: "inherit",
                    variant: "outlined",
                    className: connectButton
                }}
                onClick = { accountContext.connectWalletHandler }
            >
                {connectData.label}
            </Button>
        )

        const connectedLabel = accountContext.currentAccount ? `${accountContext.currentAccount.substring(0,4)}...${accountContext.currentAccount.substring(accountContext.currentAccount.length-4,accountContext.currentAccount.length)}` : '';
        const connected = (
            <Button
                {...{
                    color: "inherit",
                    variant: "outlined",
                    className: connectButton
                }}
            >
                {connectedLabel}
            </Button>
        )

        if (mobileView) {
            return (
                <MenuItem >
                    <Grid container justifyContent="center">
                        {accountContext.currentAccount ? connected : connectContent}
                    </Grid>
                </MenuItem>
            )
        }
        else {
            return accountContext.currentAccount  ? connected: connectContent;
        }
    }

    // console.log(mobileView);
    return (
        <header>
            <AppBar className={header}>
                { invalidNetwork &&
                    <Alert severity="error" variant="filled">
                        <AlertTitle>Error: Invalid network</AlertTitle>
                        {`Please connect to the network: ${accountContext.networkMap[accountContext.targetNetwork]}`}
                    </Alert>
                }
                {mobileView? displayMobile() : displayDesktop()}
            </AppBar>
        </header>
    );
}

export default Header;